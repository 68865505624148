// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const UnitedNationsSustainableDevelopmentGoalsBanner = ({ data }) => {
  return (
    <>
      {data && (
        <StyledUnitedNationsSustainableDevelopmentGoalsBanner className="banner_block">
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div
              className="svg"
              dangerouslySetInnerHTML={{
                __html: data.unsLogo,
              }}
            />
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.unsContent,
              }}
            />
          </div>
        </StyledUnitedNationsSustainableDevelopmentGoalsBanner>
      )}
    </>
  );
};

const StyledUnitedNationsSustainableDevelopmentGoalsBanner = styled.div`
  width: 100%;
  height: 289px;
  position: relative;
  overflow: hidden;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    height: 220px;
  }
  .bg_layout {
    background-color: #ffff;
  }
`;

export const query = graphql`
  fragment UnitedNationsSustainableDevelopmentGoalsBanner on WpPage_Components_Components_UnitedNationsSustainableDevelopmentGoalsBanner {
    unsContent
    unsLogo
  }
`;
