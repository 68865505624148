// libraries
import React from "react";
import Slider from "rc-slider/lib/Slider";
import "rc-slider/assets/index.css";
// static
import "./index.css";

export const MegaSlider = ({ handleChange, ...rest }) => {
  return <Slider onChange={handleChange} {...rest} />;
};
