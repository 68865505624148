// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const EmptyPromoBlock = ({ data, id }) => {
  let classes = [33, 1109, 39, 1136].includes(id) ? "reverse" : "";

  return (
    <>
      {data && (
        <StyledEmptyPromoBlock>
          <div className={`bg_layout ${classes}`} />
          
          <div className="main_wrapper">
            <h1
              dangerouslySetInnerHTML={{
                __html: data.epbTitle,
              }}
            />
          </div>
        </StyledEmptyPromoBlock>
      )}
    </>
  );
};

const StyledEmptyPromoBlock = styled.div`
  position: relative;
  z-index: 4;
  height: 400px;
  padding-top: 104px;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    height: 250px;
  }
  .bg_layout {
    background: #f1f4f7;
    &:before {
      background-color: #e8edf2;
      right: -1450px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: -639px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -250px;
      }
    }

    &.reverse {
      background-color: #e8edf2;
      &:before {
        background-color: #f1f4f7;
        right: 453px;
        @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
          right: 250px;
        }
      }
    }
  }

  .main_wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    h1 {
      font-weight: 700;
      font-size: 76px;
      line-height: 76px;
      letter-spacing: -1.5px;
      width: 50%;
      position: relative;
      top: -52px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 80%;
        top: -30px;
        font-size: 42px;
        line-height: 58px;
        letter-spacing: -1px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
      }

      span {
        color: #005eb8;
      }
    }
  }
`;

export const query = graphql`
  fragment EmptyPromoBlock on WpPage_Components_Components_EmptyPromoBlock {
    epbTitle
  }
`;
