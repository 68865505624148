// libraries
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
// constants
import { BREAKPOINTS } from "../../settings";

export const InvestorTable = ({ data }) => {
    const { title, rows } = data;

    return (
        <StyledInvestorTable>
          <div className="bg_layout" />
          <div className="main_wrapper">
            <StyledTitle dangerouslySetInnerHTML={{__html: title}} />
            <StyledTableContainer>
              <StyledTable>
                <tbody>
                {rows ? rows.map(({columnLeft, columnRight}, index) => (
                    <StyledTableRow key={index}>
                      <StyledColumn className="left" dangerouslySetInnerHTML={{__html: columnLeft}} />
                      <StyledColumn className="right" dangerouslySetInnerHTML={{__html: columnRight}} />
                    </StyledTableRow>
                  ))
                : null}
                </tbody>
              </StyledTable>
            </StyledTableContainer>
          </div>
        </StyledInvestorTable>
    );
};

const StyledInvestorTable = styled.section`
  padding: 80px 0;
  background-color: #F1F4F7;
`;

const StyledTitle = styled.h2`
  margin-bottom: 48px;
  color: #000;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.5px;

  @media (min-width: ${BREAKPOINTS.lg + "px"}) {
    margin-bottom: 40px;
    font-size: 48px;
    line-height: 1.2;
    letter-spacing: -1px;;
  }
`;

const StyledTableContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledTable = styled.table`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 735px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #FFF;
`

const StyledTableRow = styled.tr`
  display: flex;
`

const StyledColumn = styled.td`
  display: flex;
  gap: 16px;
  border-bottom: 1px solid #F2F8FD;
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: -0.3px;
  
  &.left {
    min-width: 140px;
    padding: 12px 8px;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background: #005EB8;
    font-weight: 700;
    text-align: center;
    @media (min-width: ${BREAKPOINTS.lg}px) {
      min-width: 164px;
    }
  }
  
  &.right {
    flex-direction: column;
    padding: 12px 16px;
  }
  
  ul {
    padding-left: 20px;
    list-style-type: disc;
  }
  
  a {
    color: blue;
    text-decoration: underline;
    word-break: break-all;
  }
`

export const query = graphql`
    fragment InvestorTable on WpPage_Components_Components_InvestorTable {
        title
        rows {
            columnLeft
            columnRight
        }
    }
`;
