// libraries
import React, { useState } from "react";
import { graphql } from "gatsby";
// components
import { HelmetBlock } from "../components/HelmetBlock";
import { Layout } from "../components/Layout";
import { Header } from "../components/HeaderBlock";
import { PreFooterBlock } from "../components/PreFooterBlock";
import { FooterBlock } from "../components/FooterBlock";
import { ContactsFormBlock } from "../components/ContactsFormBlock";
// helpers
import { renderBlock } from "../helpers/renderBlock";
// import { checkLangOnRedirect } from "../helpers/lang"; //TODO: временно скрыл редирект на испанскую версию
// static
import "../styles/main.scss";

const Page = ({ data, location }) => {
  const [fixedNav, setFixedNav] = useState(false);

  //TODO: временно скрыл редирект на испанскую версию
  // useEffect(() => {
  //   if (checkLangOnRedirect(data.wpPage)) {
  //     navigate(checkLangOnRedirect(data.wpPage));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const pageList = data?.wpPage;
  const pageId = pageList?.databaseId;
  const title = pageList?.title;
  const components = pageList?.components?.components;
  const seo = pageList?.seo;

  return (
    <Layout data={pageList} isPost={false}>
      <HelmetBlock data={seo} pageId={pageId} setFixedNav={setFixedNav} />
      <Header pageId={pageId} fixedNav={fixedNav} />
      {components &&
        components.map((item, index) => (
          <section key={item.__typename + "-" + index}>
            {renderBlock(item, pageId, title, location, fixedNav)}
          </section>
        ))}
      <PreFooterBlock pageId={pageId} />
      <FooterBlock />
      {<ContactsFormBlock />}
    </Layout>
  );
};
export default Page;

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      isFrontPage
      databaseId
      title
      language {
        code
      }
      translations {
        link
        slug
      }
      seo {
        canonical
        metaDesc
        metaRobotsNoindex
        title
        opengraphTitle
        opengraphType
        opengraphSiteName
        opengraphDescription
        opengraphModifiedTime
        opengraphImage {
          localFile {
            publicURL
          }
          mediaDetails {
            height
            width
          }
        }
      }
      components {
        components {
          ... on WpPage_Components_Components_PromoMainPageBlock {
            __typename
            ...PromoMainPageBlock
          }
          ... on WpPage_Components_Components_AdvantagesMainPageBlock {
            __typename
            ...AdvantagesMainPageBlock
          }
          ... on WpPage_Components_Components_CompanyWaysMainPageBlock {
            __typename
            ...CompanyWaysMainPageBlock
          }
          ... on WpPage_Components_Components_OurProductsBlock {
            __typename
            ...OurProductsBlock
          }
          ... on WpPage_Components_Components_OurKeyFiguresMainPageBlock {
            __typename
            ...OurKeyfiguresMainPageBlock
          }
          ... on WpPage_Components_Components_LatesNewsPreviewBlock {
            __typename
            ...LatesNewsPreviewBlock
          }
          ... on WpPage_Components_Components_EmptyPromoBlock {
            __typename
            ...EmptyPromoBlock
          }
          ... on WpPage_Components_Components_NewsPagePreviewBlock {
            __typename
          }
          ... on WpPage_Components_Components_MediaKitBlock {
            __typename
            ...MediaKitBlock
          }
          ... on WpPage_Components_Components_FullPromoBlock {
            __typename
            ...FullPromoBlock
          }
          ... on WpPage_Components_Components_WorkingResponsiblyBlock {
            __typename
            ...WorkingResponsiblyBlock
          }
          ... on WpPage_Components_Components_OurCarbonFootprintBlock {
            __typename
            ...OurCarbonFootprintBlock
          }
          ... on WpPage_Components_Components_CarbonNeutralBanner {
            __typename
            ...CarbonNeutralBanner
          }
          ... on WpPage_Components_Components_ActivelyCollaborateBlock {
            __typename
            ...ActivelyCollaborateBlock
          }
          ... on WpPage_Components_Components_SustainableAndInclusiveSocietyBanner {
            __typename
            ...SustainableAndInclusiveSocietyBanner
          }
          ... on WpPage_Components_Components_FinancialResponsibilityBlock {
            __typename
            ...FinancialResponsibilityBlock
          }
          ... on WpPage_Components_Components_UnitedNationsSustainableDevelopmentGoalsBanner {
            __typename
            ...UnitedNationsSustainableDevelopmentGoalsBanner
          }
          ... on WpPage_Components_Components_TechnologyDrivenAdvantagesBlock {
            __typename
            ...TechnologyDrivenAdvantagesBlock
          }
          ... on WpPage_Components_Components_QualityManagementSystemBanner {
            __typename
            ...QualityManagementSystemBanner
          }
          ... on WpPage_Components_Components_HowWeWorkBlock {
            __typename
            ...HowWeWorkBlock
          }
          ... on WpPage_Components_Components_WantToJoinUsBlock {
            __typename
            ...WantToJoinUsBlock
          }
          ... on WpPage_Components_Components_SubPromoBlock {
            __typename
            ...SubPromoBlock
          }
          ... on WpPage_Components_Components_HistoryPreviewBlock {
            __typename
            ...HistoryPreviewBlock
          }
          ... on WpPage_Components_Components_LeadershipBlock {
            __typename
            ...LeadershipBlock
          }
          ... on WpPage_Components_Components_HighlightsBlock {
            __typename
            ...HighlightsBlock
          }
          ... on WpPage_Components_Components_InvestorsInnerBlock {
            __typename
            ...InvestorsInnerBlock
          }
          ... on WpPage_Components_Components_InformationForBondInvestorsBlock {
            __typename
            ...InformationForBondInvestorsBlock
          }
          ... on WpPage_Components_Components_KeyFiguresBlock {
            __typename
            ...KeyFiguresBlock
          }
          ... on WpPage_Components_Components_SubPromoCareerBlock {
            __typename
            ...SubPromoCareerBlock
          }
          ... on WpPage_Components_Components_OurPrinciplesBlock {
            __typename
            ...OurPrinciplesBlock
          }
          ... on WpPage_Components_Components_WhyIdFinanceBlock {
            __typename
            ...WhyIdFinanceBlock
          }
          ... on WpPage_Components_Components_LifeAtIdFinanceBlock {
            __typename
            ...LifeAtIdFinanceBlock
          }
          ... on WpPage_Components_Components_EmployeesExperienceBlock {
            __typename
            ...EmployeesExperienceBlock
          }
          ... on WpPage_Components_Components_OpenPositionsBlock {
            __typename
            ...OpenPositionsBlock
          }
          ... on WpPage_Components_Components_DataScienceSolutionsBlock {
            __typename
            ...DataScienceSolutionsBlock
          }
          ... on WpPage_Components_Components_HowDoWeDoItBlock {
            __typename
            ...HowDoWeDoItBlock
          }
          ... on WpPage_Components_Components_CreditInteligeBlock {
            __typename
            ...CreditInteligeBlock
          }
          ... on WpPage_Components_Components_SimplePolicyBlock {
            __typename
            ...SimplePolicyBlock
          }
          ... on WpPage_Components_Components_CalcBlock {
            __typename
            ...CalcBlock
          }
          ... on WpPage_Components_Components_InvestorTable {
            __typename
            ...InvestorTable
          }
        }
      }
    }
  }
`;
