// libraries
import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
// components
import { InvestAgreement } from "../../components/InvestAgreement";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { usePopups } from "../../context/popupsContext";
// static
import { SVG_SIDE_RIGHT } from '../../icons/icons'

export const FullPromoBlock = ({ data }) => {
  const { showAgreementPopup, setShowAgreementPopup } = usePopups();
  const { linkToPage, previewBtn } = data.buttons;
  
  const investorPopupHandler = (isShow, url) => {
    let data = [];
    data.showPopup = isShow;
    data.url = url;

    setShowAgreementPopup(data);
  };

  return (
    <>
      {data && (
        <StyledFullPromoBlock>
          <div
            className="bg_layout"
            style={{
              backgroundImage: `url(${data.fpbBgImage?.localFile?.publicURL})`,
            }}
          />
          
          <div className="main_wrapper">
            <h1
              dangerouslySetInnerHTML={{
                __html: data.fpbTitle,
              }}
            />
            {data.fpbSubtitle ? (
              <StyledSubtitle
                dangerouslySetInnerHTML={{
                  __html: data.fpbSubtitle,
                }}
              />
            ) : null}
            {data.isButtons ? (
              <StyledButtons>
                {linkToPage.link ? <StyledButton to={linkToPage.link}>
                  <span dangerouslySetInnerHTML={{ __html: linkToPage.text }} />
                  <StyledIcon dangerouslySetInnerHTML={{ __html: SVG_SIDE_RIGHT }} />
                </StyledButton>
                : null}
                {previewBtn.link ? <StyledButton
                  as="button"
                  onClick={() => investorPopupHandler(true, previewBtn.link)}
                  aria-label={previewBtn.text}
                >
                  <span dangerouslySetInnerHTML={{ __html: previewBtn.text }} />
                  <StyledIcon dangerouslySetInnerHTML={{ __html: SVG_SIDE_RIGHT }} />
                </StyledButton>
                : null}
              </StyledButtons>
            ) : null}
          </div>
          {showAgreementPopup?.showPopup ? (
              <InvestAgreement
                previewBtnLink={previewBtn.link ? previewBtn.link : null}
                isInner={false}
              />
            ) : null}
        </StyledFullPromoBlock>
      )}
    </>
  );
};

const StyledButtons = styled.div`
  display: flex;
  position: relative;
  top: 315px;
  left: 0;
  gap: 24px;
  max-width: 523px;
  
  @media (min-width: ${BREAKPOINTS.md}px) {
    position: static;
    margin-top: 40px;
  }
`

const StyledButton = styled(Link)`
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 50%;
  padding: 16px;
  font-family: "PT Sans", arial, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  color: #fff;
  background: #182F48;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;

  @media (min-width: ${BREAKPOINTS.xl}px) {
    max-width: unset;
    padding: 24px;
    font-size: 18px;
    line-height: 1.1;
    text-align: center;
    letter-spacing: -0.5px;
  }

  &:active,
  &:hover,
  &:focus {
    opacity: 0.9;
    color: #fff;
  }
`
const StyledIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
`

const StyledSubtitle = styled.p`
  max-width: 455px;
  font-size: 24px;
  line-height: 1.15;
  letter-spacing: -0.5px;

  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    font-size: 18px;
    line-height: 1.33;
    letter-spacing: -0.3px;
  }
`

const StyledFullPromoBlock = styled.div`
  position: relative;
  overflow: hidden;
  height: 504px;
  padding-top: 103px;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    height: 664px;
    padding-bottom: 280px;
    background-color: rgba(241, 244, 247, 0.7);
  }

  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    height: 560px;
    overflow: visible;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: skewX(-30deg);
    background-color: #fff;
    right: 300px;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      right: 250px;
    }
  }

  .bg_layout {
    top: 104px;
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      background-position: 150%;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      height: 280px;
      top: auto;
      bottom: 0;
      background-position: center;
      background-size: cover;
    }
    &:before {
      background-color: #f1f4f7;
      right: 570px;
      top: -2px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: 400px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        right: 409px;
        background-color: rgba(248, 250, 251, 0.4);
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 329px;
      }
    }
    &:after {
      display: none;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        height: 280px;
        top: auto;
        bottom: 0;
        background-position: center;
        background-size: cover;
      }
    }
  }

  .main_wrapper {
    position: relative;
    z-index: 4;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @media (min-width: ${BREAKPOINTS.lg}px) {
    z-index: 5;
  }
    
    h1 {
      margin-bottom: 24px;
      font-weight: 700;
      font-size: 76px;
      line-height: 76px;
      letter-spacing: -1.5px;
      width: 55%;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 48px;
        line-height: 58px;
        letter-spacing: -1px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        margin-bottom: 8px;
        width: 100%;
      }
      @media (max-width: ${BREAKPOINTS.xsm + "px"}) {
        font-size: 38px;
        line-height: 47px;
      }
      span {
        color: #005eb8;
      }
    }
  }
`;

export const query = graphql`
  fragment FullPromoBlock on WpPage_Components_Components_FullPromoBlock {
    fpbTitle
    fpbSubtitle
    fpbBgImage {
      localFile {
        publicURL
      }
    }
    isButtons
    buttons {
      linkToPage {
        text
        link
      }
      previewBtn {
        text
        link
      }
    }
  }
`;
