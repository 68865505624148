// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const QualityManagementSystemBanner = ({ data }) => {
  return (
    <>
      {data && (
        <StyledQualityManagementSystemBanner className="banner_block">
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div
              className="svg"
              dangerouslySetInnerHTML={{
                __html: data.qmsIcon,
              }}
            />
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.qmsContent,
              }}
            />
          </div>
        </StyledQualityManagementSystemBanner>
      )}
    </>
  );
};

const StyledQualityManagementSystemBanner = styled.div`
  width: 100%;
  height: 289px;
  position: relative;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    height: 220px;
  }

  .bg_layout {
    background-color: #19324b;
    &:after {
      background: #273e56;
      right: -1495px;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -250px;
      }
    }
  }

  .main_wrapper {
    width: 800px;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 100%;
    }
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding-left: 100px;
    }
    svg {
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 60px;
        height: 80px;
      }
    }

    .content {
      color: #fff;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }
`;

export const query = graphql`
  fragment QualityManagementSystemBanner on WpPage_Components_Components_QualityManagementSystemBanner {
    qmsContent
    qmsIcon
  }
`;
