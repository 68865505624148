// libraries
import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const TitleLink = ({ title, link }) => {
  return (
    <StyledTitleLink to={link} className="title">
      <h2
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
    </StyledTitleLink>
  );
};

const StyledTitleLink = styled(Link)`
  color: #fff;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.5px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      font-size: 32px;
      line-height: 32px;
    }
    &:after {
      content: "";
      background-image: url("data:image/svg+xml,%3Csvg width='37' height='36' viewBox='0 0 37 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' width='36' height='36' rx='18' fill='white'%3E%3C/rect%3E%3Cpath d='M16.5125 25L23.5 18L16.5125 25Z' fill='%23005EB8'%3E%3C/path%3E%3Cpath d='M16.5125 25L23.5 18' stroke='%23005EB8' stroke-linecap='round'%3E%3C/path%3E%3Cpath d='M23.5 18L16.5124 11L23.5 18Z' fill='%23005EB8'%3E%3C/path%3E%3Cpath d='M23.5 18L16.5124 11' stroke='%23005EB8' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
      width: 36px;
      height: 36px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: inline-block;
      position: relative;
      bottom: -4px;
      margin-left: 20px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        display: none;
      }
    }
  }
`;
