// libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// helpers
import { newsDate, categoryColor } from "../../../helpers/base";
import { translator } from "../../../helpers/lang";
// constants
import { BREAKPOINTS } from "../../../settings";
// static
import { SVG_PLUS_ICON } from "../../../icons/icons";

export const SortedPreviewList = ({ data }) => {
  const newsPerView = 6;
  const [slicedNewsCount, setSliceNewsCount] = useState(newsPerView);
  const [showButtonMore, setShowButtonMore] = useState(
    data.sortedNews.length > newsPerView + 1
  );

  useEffect(() => {
    setSliceNewsCount(newsPerView);
    setShowButtonMore(data.sortedNews.length > newsPerView);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.choisedCategoryID]);
  const moreButtonHandler = () => {
    if (data.sortedNews.length <= slicedNewsCount + newsPerView) {
      setShowButtonMore(false);
    }

    setSliceNewsCount(slicedNewsCount + newsPerView);
  };

  return (
    <>
      {data.sortedNews && (
        <StyledSortedPreviewList>
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div className="news_list">
              {data.sortedNews.slice(0, slicedNewsCount).map((news, index) => (
                <div key={index} className="news_list_item">
                  <div className="news_item">
                    <div className="item_content">
                      <ul className="category_list">
                        {news.categories?.nodes?.map((category, catIndex) => (
                          <li
                            key={index + "-" + catIndex}
                            style={{
                              color: categoryColor(category.databaseId),
                            }}
                            dangerouslySetInnerHTML={{
                              __html: category.name,
                            }}
                          />
                        ))}
                      </ul>

                      <a
                        href={translator(data.langCurrent, {
                          es: `/es/noticias/${news.slug}`,
                          en: `/news/${news.slug}`,
                        })}
                        aria-label={news.title}
                      >
                        <h3
                          dangerouslySetInnerHTML={{
                            __html: news.title,
                          }}
                        />
                      </a>

                      <div
                        className="date"
                        dangerouslySetInnerHTML={{
                          __html: newsDate(news.date, data.langCurrent),
                        }}
                      />
                    </div>

                    {/* TODO: проверить макет, возможно есть смысл обернуть всю карточку в ссылку */}
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a
                      href={translator(data.langCurrent, {
                        es: `/es/noticias/${news.slug}`,
                        en: `/news/${news.slug}`,
                      })}
                      className="thumb"
                    >
                      <img
                        src={news.news.picturemob?.localFile?.publicURL}
                        alt={news.news.picturemob?.altText ? news.news.picturemob?.altText : news.title}
                      />
                    </a>
                  </div>
                </div>
              ))}
            </div>

            {showButtonMore && (
              <div className="more_line">
                <button
                  aria-label="Load more"
                  onClick={() => moreButtonHandler()}
                >
                  <span
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: translator(data.langCurrent, {
                        es: "Ver más",
                        en: "Load more",
                      }),
                    }}
                  />
                  <span
                    className="svg"
                    dangerouslySetInnerHTML={{
                      __html: SVG_PLUS_ICON,
                    }}
                  />
                </button>
              </div>
            )}
          </div>
        </StyledSortedPreviewList>
      )}
    </>
  );
};

const StyledSortedPreviewList = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 10px 0 120px;

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding: 0 0 80px;
  }

  .bg_layout {
    background-color: #0a243f;
    &:after {
      background-color: #19324b;
      height: 3000px;
      width: 100%;
      right: -2060px;
      top: auto;
      bottom: 0;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -1100px;
      }
    }
  }

  .news_list {
    width: calc(100% + 24px);
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 12px;
    margin-left: -12px;
  }

  .news_list_item {
    width: calc(100% / 3);
    padding: 12px;

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 50%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
    }
  }

  .news_item {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;

    .item_content {
      width: 100%;
      padding: 30px 30px 60px;
      position: relative;
      flex-grow: 1;
      .category_list {
        width: 100%;
        height: auto;
        margin-bottom: 16px;
        li {
          display: inline-block;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.3px;
          color: #635bff;
          margin-right: 10px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 13px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }

      h3 {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.3px;
        color: #000000;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 20px;
          }
      }

      .date {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: #000000;
        opacity: 0.6;
        position: absolute;
        left: 30px;
        bottom: 20px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 13px;
          }
      }
    }

    .thumb {
      width: 100%;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .more_line {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    button {
      width: auto;
      min-width: 190px;
      height: 56px;
      background: #005eb8;
      border-radius: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.95;
      }
      .title {
        width: fit-content;
        height: 100%;
        position: relative;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.385714px;
        color: #ffffff;
        padding-right: 30px;
        border-right: 1px solid #4780b5;
        display: flex;
        align-items: center;
      }
      .svg {
        width: auto;
        height: auto;
        svg {
          width: 16px;
          height: 16px;
          fill: #fff;
        }
      }
    }
  }
`;
