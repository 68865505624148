// libraries
import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
// context
import { useLang } from "../../context/languageContext";
// constants
import { BREAKPOINTS } from "../../settings";
// hooks
import { useMediaCarouselItems } from "../../hooks/use-media-carousel-items";
// helpers
import { translator } from "../../helpers/lang";
// static
import { SVG_LONG_ARROV_LEFT } from "../../icons/icons";

export const MediaPresenceSlider = ({ isHome }) => {
  const { lang:langCurrent } = useLang();

  const mediaItems = useMediaCarouselItems();
  const mediaCarouselList = mediaItems.mediaCarouselList;

  const swiperRef = React.useRef(null);

  const sliderParams = {
    slidesPerView: 2,
    spaceBetween: 20,
    loop: true,
    centeredSlides: true,
    breakpoints: {
      576: {
        slidesPerView: 3,
        centeredSlides: false,
      },
      768: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 5,
      },
    },
  };

  return (
    <>
      {mediaCarouselList && (
        <StyledMediaPresenceSlideBlock
          className={isHome ? "home_slider" : "page_slider"}
        >
          {!isHome && (
            <>
              <div className="bg_layout" />
              <div className="title_block">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: mediaItems.mediaCarouselTitle.es,
                      en: mediaItems.mediaCarouselTitle.en,
                    }),
                  }}
                />
              </div>
            </>
          )}

          <div className="main_wrapper">
            <Swiper {...sliderParams} ref={swiperRef}>
              {mediaCarouselList.map((item, index) => (
                <SwiperSlide key={index}>
                  <a
                    href={item.mclLink}
                    target="_blank"
                    rel="nofollow noopener noreferrer"
                  >
                    <img
                      src={item.mclLogo?.localFile?.publicURL}
                      alt="Media logo"
                    />
                  </a>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="custom_navs">
              <button
                aria-label="previous slide"
                className="prev-slide"
                dangerouslySetInnerHTML={{
                  __html: SVG_LONG_ARROV_LEFT,
                }}
                onClick={() => swiperRef.current.swiper.slidePrev()}
              />

              <button
                aria-label="next slide"
                className="next-slide"
                dangerouslySetInnerHTML={{
                  __html: SVG_LONG_ARROV_LEFT,
                }}
                onClick={() => swiperRef.current.swiper.slideNext()}
              />
            </div>
          </div>
        </StyledMediaPresenceSlideBlock>
      )}
    </>
  );
};

const StyledMediaPresenceSlideBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  &.home_slider {
    margin-top: 120px;
    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      margin-top: 60px;
    }
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      margin-top: 40px;
    }
  }

  &.page_slider {
    height: 300px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      height: 250px;
    }

    .swiper-container {
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        width: 80%;
        .custom_navs {
          top: -23px;
        }
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
        left: 0;
      }
    }
    .custom_navs {
      width: 100%;
      left: 0;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        width: 90%;
        left: 5%;
      }
      button {
        top: -6px;
        @media (max-width: ${BREAKPOINTS.xl + "px"}) {
          top: -23px;
        }
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          top: -7px;
        }
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          top: -24px;
        }
      }
    }
  }

  .bg_layout {
    background-color: #0a243f;
    z-index: 1;
    &:after {
      background-color: #19324b;
      right: -1110px;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -165px;
      }
    }
  }

  .title_block {
    text-align: center;
    width: 55%;
    margin: 0 auto 48px;
    position: relative;
    z-index: 2;
    color: #fff;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
      margin: 0 auto 40px;
    }

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      margin: 0 auto 30px;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
    }

    .title {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #fff;

      h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: -0.5px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 32px;
          line-height: 32px;
        }
      }
    }
  }

  .swiper-container {
    width: 90%;
    height: 100px;
    z-index: 2;
    position: relative;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: calc(100% + 40px);
      left: -20px;
    }

    .swiper-slide {
      height: 100%;
      a {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: auto;
          height: auto;
          max-width: 90%;
          max-height: 80%;
        }
      }
    }
  }

  .custom_navs {
    width: 100%;
    height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      display: none;
    }
    button {
      width: 25px;
      height: 25px;
      top: -11px;
      position: relative;
      cursor: pointer;
      &.next-slide {
        svg {
          transform: rotate(180deg);
        }
      }
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
`;
