// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
// components
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";
// static
import { SVG_DOWNLOAD_ICON } from "../../icons/icons";

export const MediaKitBlock = ({ data }) => {
  const sliderParams = {
    slidesPerView: 1.2,
    spaceBetween: 16,
    breakpoints: {
      576: {
        slidesPerView: 2,
        spaceBetween: 28,
      },
      768: {
        slidesPerView: 2.5,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };

  const elemId = "kit";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data.mkbKitsList && (
        <StyledMediaKitBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.mkbTitle,
                }}
              />
            </div>

            <Swiper {...sliderParams} className="kit_list">
              {data.mkbKitsList.map((item, index) => (
                <SwiperSlide key={index} className="item">
                  <div
                    className="pretitle"
                    dangerouslySetInnerHTML={{
                      __html: item.pretitle,
                    }}
                  />

                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  />

                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: item.content,
                    }}
                  />

                  {item.file ? (
                    <a
                      href={item.file?.localFile?.publicURL}
                      className="btn"
                      aria-label={item.buttonLabel}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      download={true}
                    >
                      <span
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: item.buttonLabel,
                        }}
                      />

                      <span
                        className="svg"
                        dangerouslySetInnerHTML={{
                          __html: SVG_DOWNLOAD_ICON,
                        }}
                      />
                    </a>
                  ) : (
                    <a
                      href={item.link}
                      className="btn"
                      aria-label={item.buttonLabel}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <span
                        className="text"
                        dangerouslySetInnerHTML={{
                          __html: item.buttonLabel,
                        }}
                      />

                      <span
                        className="svg"
                        dangerouslySetInnerHTML={{
                          __html: SVG_DOWNLOAD_ICON,
                        }}
                      />
                    </a>
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </StyledMediaKitBlock>
      )}
    </>
  );
};

const StyledMediaKitBlock = styled.div`
  width: 100%;
  height: 630px;
  position: relative;
  padding-top: 100px;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding-top: 60px;
  }
  .bg_layout {
    background-color: #dbe3ea;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      background-color: #f2f8fd;
    }
    &:after {
      background-color: #e8edf2;
      right: -846px;
    }
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 60px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 15px;
      color: #0a243f;
    }

    span {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      br {
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          display: none;
        }
      }
    }
  }

  .kit_list {
    .swiper-wrapper {
      justify-content: center;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        justify-content: start;
      }
    }
    .item {
      width: 31%;
      height: auto;
      background: linear-gradient(0deg, #0a243f, #0a243f), #0d2655;
      padding: 16px 20px 112px;
      position: relative;
      margin-right: 3.5%;
      color: #fff;
      border-radius: 16px;
      &:nth-child(3n) {
        margin-right: 0;
      }
      .pretitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        margin-bottom: 16px;
      }

      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.3px;
        margin-bottom: 16px;
      }

      .content {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.5px;
        opacity: 0.6;
      }

      .btn {
        width: auto;
        min-width: 200px;
        height: 40px;
        position: absolute;
        bottom: 24px;
        left: 20px;
        background-color: #223a52;
        border-radius: 100px;
        overflow: hidden;
        padding: 0 50px 0 12px;
        display: flex;
        align-items: center;
        &:hover {
          opacity: 0.9;
        }
        .text {
          font-weight: 700;
          font-size: 18px;
          line-height: 20px;
          letter-spacing: -0.385714px;
          color: #ffffff;
        }
        .svg {
          position: absolute;
          right: 0;
          top: 0;
          width: 40px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-left: 1px solid #0a243f;
          svg {
            width: 18px;
            height: 18px;
            fill: #fff;
          }
        }
      }
    }
  }
`;

export const query = graphql`
  fragment MediaKitBlock on WpPage_Components_Components_MediaKitBlock {
    mkbTitle
    mkbKitsList {
      isAddLink
      link
      buttonLabel
      content
      fieldGroupName
      pretitle
      title
      file {
        localFile {
          publicURL
        }
      }
    }
  }
`;
