// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";
// static
import { SVG_LONG_ARROV_LEFT } from "../../icons/icons";

export const EmployeesExperienceBlock = ({ data }) => {
  const swiperRef = React.useRef(null);
  const sliderParams = {
    slidesPerView: 1.2,
    spaceBetween: 10,
    loop: true,
    centeredSlides: false,
    breakpoints: {
      576: {
        slidesPerView: 1.6,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
  };

  const elemId = "employees_experience";
  const blockRef = React.useRef(null);

  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledEmployeesExperienceBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.eebTitle,
                }}
              />
            </div>

            <div className="slider_wrapper">
              <Swiper {...sliderParams} ref={swiperRef}>
                {data.eebExperienceList.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div
                      className="image"
                      style={{
                        backgroundImage: `url(${item.eebElImage?.localFile?.publicURL})`,
                      }}
                    />

                    <div className="content_wrapper">
                      <div
                        className="quote"
                        dangerouslySetInnerHTML={{
                          __html: item.eebElContent,
                        }}
                      />

                      <div className="person_wrapper">
                        <div
                          className="name"
                          dangerouslySetInnerHTML={{
                            __html: item.eebElName,
                          }}
                        />

                        <div
                          className="position"
                          dangerouslySetInnerHTML={{
                            __html: item.eebElPosition,
                          }}
                        />
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="custom_navs">
                <button
                  aria-label="previous slide"
                  className="prev-slide"
                  dangerouslySetInnerHTML={{
                    __html: SVG_LONG_ARROV_LEFT,
                  }}
                  onClick={() => swiperRef.current.swiper.slidePrev()}
                />

                <button
                  aria-label="next slide"
                  className="next-slide"
                  dangerouslySetInnerHTML={{
                    __html: SVG_LONG_ARROV_LEFT,
                  }}
                  onClick={() => swiperRef.current.swiper.slideNext()}
                />
              </div>
            </div>
          </div>
        </StyledEmployeesExperienceBlock>
      )}
    </>
  );
};

const StyledEmployeesExperienceBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 80px 0 110px;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding: 30px 0 60px;
  }

  .bg_layout {
    background-color: #19324b;
    &:before {
      background-color: #0a243f;
      right: 2075px;
    }
    &:after {
      background-color: #0a243f;
      right: -1384px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -614px;
      }
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: -354px;
        display: none;
      }
    }
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 60px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 80%;
      margin: 0 auto 45px;
    }

    h2 {
      color: #fff;
    }
  }

  .main_wrapper {
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding: 0;
    }
  }

  .slider_wrapper {
    width: 100%;
    height: auto;
    padding: 0 80px;
    position: relative;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      padding: 0 50px;
    }
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      padding: 0;
    }
  }

  .swiper-slide {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 40px;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      padding: 0 20px;
    }
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      padding: 0;
    }
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding-left: 20px;
    }
    .image {
      width: 309px;
      height: 309px;
      border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: hidden;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        margin: 0 auto 50px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        margin: 0 auto 50px;
        width: 70vw;
        height: 70vw;
      }
    }
    .content_wrapper {
      width: calc(100% - 450px);
      margin-left: auto;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        width: calc(100% - 400px);
      }
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 100%;
      }
      .quote {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #ffffff;
        margin-bottom: 47px;
        position: relative;
        padding-top: 45px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          margin-bottom: 60px;
          font-size: 17px;
        }
        &:before {
          content: url("data:image/svg+xml,%3Csvg width='39' height='26' viewBox='0 0 39 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.996 25.6L19.632 3.244L11.996 0.115995L0.128 21.276L11.996 25.6ZM31.224 25.6L38.86 3.244L31.224 0.115995L19.356 21.276L31.224 25.6Z' fill='%23005EB8'/%3E%3C/svg%3E%0A");
          position: absolute;
          top: 10px;
          left: -50px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            left: 0;
          }
        }
        &:after {
          content: url("data:image/svg+xml,%3Csvg width='39' height='26' viewBox='0 0 39 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.684 25.6L19.552 4.348L7.684 0.115995L0.0480003 22.472L7.684 25.6ZM26.82 25.6L38.78 4.348L26.912 0.115995L19.276 22.472L26.82 25.6Z' fill='%23005EB8'/%3E%3C/svg%3E%0A");
          position: absolute;
          bottom: -40px;
          right: -20px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            right: 0;
          }
        }
      }
      .person_wrapper {
        width: 100%;
        height: auto;
        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #ffffff;
          margin-bottom: 5px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 17px;
          }
        }
        .position {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #979797;
          opacity: 0.6;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 17px;
          }
        }
      }
    }
  }

  .custom_navs {
    position: absolute;
    left: 0;
    top: calc(50% - 60px);
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      display: none;
    }
    button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      &:hover {
        opacity: 0.8;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .next-slide {
      transform: rotate(180deg);
    }
  }
`;

export const query = graphql`
  fragment EmployeesExperienceBlock on WpPage_Components_Components_EmployeesExperienceBlock {
    eebTitle
    eebExperienceList {
      eebElContent
      eebElImage {
        localFile {
          publicURL
        }
      }
      eebElName
      eebElPosition
    }
  }
`;
