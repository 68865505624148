// libraries
import { useStaticQuery, graphql } from "gatsby";

export const useNewsPreview = langCurrent => {
  const { en, es } = useStaticQuery(
    graphql`
      query NewsPreview {
        en: allWpPost(
          filter: { language: { code: { eq: EN } } }
          sort: { order: DESC, fields: date }
        ) {
          nodes {
            link
            title
            slug
            databaseId
            news {
              picturedesk {
                altText
                localFile {
                  publicURL
                }
              }
              picturemob {
                altText
                localFile {
                  publicURL
                }
              }
            }
            language {
              code
            }
            date(formatString: "DD/MM/YYYY")
            categories {
              nodes {
                name
                databaseId
              }
            }
          }
        }

        es: allWpPost(
          filter: { language: { code: { eq: ES } } }
          sort: { order: DESC, fields: date }
        ) {
          nodes {
            link
            title
            slug
            databaseId
            news {
              picturedesk {
                localFile {
                  publicURL
                }
              }
              picturemob {
                altText
                localFile {
                  publicURL
                }
              }
            }
            language {
              code
            }
            date(formatString: "DD/MM/YYYY")
            categories {
              nodes {
                name
                databaseId
              }
            }
          }
        }
      }
    `
  );

  if (langCurrent === "es") {
    return es.nodes;
  } else {
    return en.nodes;
  }
};
