// libaries
import React from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const MirrorListBlock = ({ index, title, content, image, color }) => {
  return (
    <>
      {title && (
        <StyledMirrorListBlock
          className={index % 2 === 0 ? "" : "reverse"}
          color={color}
        >
          <div className="content_block">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />

            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>

          <div
            className="image_wrapper"
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
        </StyledMirrorListBlock>
      )}
    </>
  );
};

const StyledMirrorListBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 80px;
  color: ${props => (props.color ? props.color : "#fff")};

  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    width: calc(100% + 40px);
    left: -20px;
  }

  &:last-child {
    margin-bottom: 0;
  }
  &.reverse {
    flex-direction: row-reverse;
  }

  .content_block {
    width: 42%;
    height: auto;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 100%;
      margin-bottom: 60px;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.3px;
      padding: 0 20px;
    }
    .title {
      width: 400px;
      font-weight: 700;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: -0.5px;
      margin-bottom: 32px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 100%;
        font-size: 32px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        line-height: 30px;
        text-align: center;
        letter-spacing: -0.3px;
      }
    }
    .content {
      width: 400px;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.3px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 100%;
        font-size: 17px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        text-align: center;
      }
    }
  }
  .image_wrapper {
    width: 49%;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    display: block;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 100%;
      height: 194px;
      position: relative;
      border-radius: 0;
    }
  }
`;
