// libraries
import { useStaticQuery, graphql } from "gatsby";

export const useMediaCarouselItems = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        acfOptionsGatsby {
          gatsbyFields {
            mediaCarouselList {
              mclLink
              mclLogo {
                localFile {
                  publicURL
                }
              }
            }
            mediaCarouselTitle {
              en
              es
            }
          }
        }
      }
    }
  `);

  return data?.wp.acfOptionsGatsby?.gatsbyFields;
};
