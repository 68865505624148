// libraries
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// components
import { OurProductsBlockLinkItem } from "./OurProductsBlockLinkItem";
import { TitleLink } from "../TitleLink";
import { Title } from "../Title";
// constants
import { SETTINGS, BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const OurProductsBlock = ({ id, location, data }) => {
  const [activeIndex, setActiveIndex] = useState(() => {
    if (location.state?.project) {
      return location.state?.project;
    } else {
      return 0;
    }
  });

  const ClickHandler = index => {
    setActiveIndex(index);
  };

  const product = data.oprmpProductsList[activeIndex];

  const classes = SETTINGS.pageAbout.includes(id) ? "about_page" : "";

  const elemId = "products";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <StyledOurProductsBlock id={elemId} ref={blockRef}>
      <div className={`bg_layout ${classes}`} />

      <div className="block_header">
        <div className="main_wrapper">
          <div className="title_block">
            {data.oprmpLink ? (
              <TitleLink title={data.oprmpTitle} link={data.oprmpLink} />
            ) : (
              <Title title={data.oprmpTitle} />
            )}
            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: data.oprmpSubtitle,
              }}
            />
          </div>

          <div className="tabs_list">
            {data.oprmpProductsList.map((item, index) => (
              <button
                key={index}
                aria-label="Product"
                dangerouslySetInnerHTML={{
                  __html: item.oprmpPlTabTitle,
                }}
                onClick={() => ClickHandler(index)}
                className={activeIndex === index ? "active" : ""}
              />
            ))}
          </div>
        </div>
      </div>

      <div className="block_content">
        <div className="main_wrapper">
          <div className="content_wrapper">
            <OurProductsBlockLinkItem
              data={{
                id: id,
                activeIndex: activeIndex,
                product: product,
              }}
            />

            <div
              className="subtitle"
              dangerouslySetInnerHTML={{
                __html: product.oprmpPlProductSubtitle,
              }}
            />

            <div className="advantage_list">
              {product.oprmpPlAdvantageList.map((item, index) => (
                <div key={index} className="list_item">
                  <div
                    className="li_title"
                    dangerouslySetInnerHTML={{
                      __html: item.oprmpPlAlTitle,
                    }}
                  />
                  <div
                    className="li_subtitle"
                    dangerouslySetInnerHTML={{
                      __html: item.oprmpPlAlSubtitle,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="product_image">
            <img
              src={product.oprmpPlProductImage.localFile.publicURL}
              alt={product.oprmpPlProductTitle}
            />
          </div>
        </div>
      </div>
    </StyledOurProductsBlock>
  );
};

const StyledOurProductsBlock = styled.div`
  position: relative;
  color: #fff;

  .bg_layout {
    background-color: #273e56;
    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 1400px;
      background-color: rgba(18, 37, 58, 0.2);
      transform: skewX(-30deg);
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 1175px;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 585px;
      }
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: -1450px;
      background-color: rgba(18, 37, 58, 0.4);
      transform: skewX(-30deg);
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -1050px;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 605px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -400px;
      }
    }

    &.about_page {
      &:before {
        right: 1548px;
        @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
          right: 1341px;
        }
      }
      &:after {
        right: -1625px;
      }
    }
  }

  .block_header {
    width: 100%;
    height: 350px;
    background-color: rgba(0, 0, 0, 0.1);
    padding-top: 90px;
    position: relative;
    z-index: 5;
    .main_wrapper {
      height: 100%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding-top: 60px;
    }

    @media (max-width: ${BREAKPOINTS.xsm + "px"}) {
      padding-top: 40px;
    }

    .title_block {
      text-align: center;
      width: 55%;
      margin: 0 auto;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        width: 60%;
      }

      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        width: 80%;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
      }

      .subtitle {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.3px;
      }
    }

    .tabs_list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      position: absolute;
      bottom: 0;
      left: 0;
      button {
        width: 24%;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;
        text-align: center;
        border-bottom: 2px solid rgba(255, 255, 255, 0.5);
        opacity: 0.5;
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          width: 100%;
        }
        &:hover {
          cursor: pointer;
          opacity: 0.8;
        }
        &.active {
          border-bottom: 2px solid rgba(255, 255, 255, 1);
          opacity: 1;
          cursor: default;
        }
      }
    }
  }

  .block_content {
    width: 100%;
    height: 650px;
    position: relative;
    z-index: 5;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      height: 500px;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      height: 750px;
    }

    .main_wrapper {
      position: relative;
      height: 100%;
    }

    .content_wrapper {
      position: relative;
      z-index: 2;
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 50%;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
        margin-bottom: 40px;
        height: fit-content;
        padding-top: 60px;
      }

      .subtitle {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.0025em;
        margin-bottom: 36px;
        max-width: 392px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }

      .advantage_list {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        .list_item {
          width: fit-content;
          height: auto;
          margin-right: 50px;
          &:last-child {
            margin-right: 0;
          }
          .li_title {
            font-weight: 700;
            font-size: 36px;
            line-height: 36px;
            letter-spacing: -0.01em;
            margin-bottom: 4px;
            @media (max-width: ${BREAKPOINTS.lg + "px"}) {
              font-size: 32px;
            }
          }
          .li_subtitle {
            font-size: 16px;
            line-height: 25px;
            color: #898989;
            @media (max-width: ${BREAKPOINTS.lg + "px"}) {
              font-size: 15px;
            }
          }
        }
      }
    }

    .product_image {
      position: absolute;
      z-index: 1;
      top: 100px;
      right: 100px;
      width: auto;
      height: 100%;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 30%;
        top: calc(50% - 175px);
        right: 50px;
        height: 350px;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        position: relative;
        width: 100%;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 80%;
      }
    }
  }
`;

export const query = graphql`
  fragment OurProductsBlock on WpPage_Components_Components_OurProductsBlock {
    oprmpLink
    oprmpSubtitle
    oprmpTitle
    oprmpProductsList {
      oprmpPlProductSubtitle
      oprmpPlProductTitle
      oprmpPlTabTitle
      oprmpPlProductLink
      oprmpPlAdvantageList {
        oprmpPlAlSubtitle
        oprmpPlAlTitle
      }
      oprmpPlProductImage {
        localFile {
          publicURL
        }
      }
    }
  }
`;
