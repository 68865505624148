// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const DataScienceSolutionsBlock = ({ data }) => {
  const elemId = "data_science";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledDataScienceSolutionsBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.dssTitle,
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: data.dssSubtitle,
                }}
              />
            </div>

            <div className="advantage_list">
              {data.dssAdvantageList.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.dssAlTitle,
                    }}
                  />

                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: item.dssAlSubtitle,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </StyledDataScienceSolutionsBlock>
      )}
    </>
  );
};

const StyledDataScienceSolutionsBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 100px 0 40px;
  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    padding: 70px 0 60px;
  }
  .bg_layout {
    background-color: #f1f4f7;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      background-color: #e8edf2;
    }
    &:before {
      background-color: #e8edf2;
      height: 2000px;
      right: 1230px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: 1061px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        right: 1066px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 985px;
      }
    }
    &:after {
      background-color: #f1f4f7;
      height: 2000px;
      display: none;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        display: block;
        right: -17px;
      }
    }
  }

  .title_block {
    width: 45%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 24px;
    }

    span {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 17px;
      }
      br {
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          display: none;
        }
      }
    }
  }

  .advantage_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      width: 30%;
      height: auto;
      margin-bottom: 84px;
      margin-right: 5%;
      text-align: center;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 48%;
        margin-right: 4%;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        margin-bottom: 40px;
      }
      &:nth-child(3n) {
        margin-right: 0;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          margin-right: 4%;
        }
      }

      &:nth-child(2n) {
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          margin-right: 0;
        }
      }

      .title {
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;
        letter-spacing: -4px;
        color: #005eb8;
        margin-bottom: 40px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 48px;
        }
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          margin-bottom: 0;
          letter-spacing: 0;
        }
      }
      .subtitle {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.5px;
        color: #000000;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 20px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment DataScienceSolutionsBlock on WpPage_Components_Components_DataScienceSolutionsBlock {
    dssSubtitle
    dssTitle
    dssAdvantageList {
      dssAlTitle
      dssAlSubtitle
    }
  }
`;
