// libraries
import React from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { useLang } from "../../context/languageContext";
import { usePopups } from "../../context/popupsContext";
// helpers
import { translator } from "../../helpers/lang";
// hooks
import { useInvestAgreement } from "../../hooks/use-invest-agreement";
// static
import { SVG_MENU_CLOSE } from "../../icons/icons";

export const InvestAgreement = ({ isInner, previewBtnLink }) => {
  const { lang:langCurrent } = useLang();
  const { setShowAgreementPopup } = usePopups();

  const investAgreementFields = useInvestAgreement();

  const investorPopupHandler = (isShow, url) => {
    let data = [];
    data.showPopup = isShow;
    data.url = url;

    setShowAgreementPopup(data);
  };

  return (
    <>
      {investAgreementFields && (
        <StyledInvestAgreement
          onClick={() => investorPopupHandler(false, null)}
        >
          <div className="popup_wrapper">
            {!isInner && (
              <button
                className="close"
                aria-label="Close popup"
                dangerouslySetInnerHTML={{
                  __html: SVG_MENU_CLOSE,
                }}
                onClick={() => investorPopupHandler(false, null)}
              />
            )}

            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  es: investAgreementFields.vtdTitle.es,
                  en: investAgreementFields.vtdTitle.en,
                }),
              }}
            />

            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  es: investAgreementFields.vtdContent.es,
                  en: investAgreementFields.vtdContent.en,
                }),
              }}
            />

            <div className="buttons_line">
              {isInner ? (
                <a
                  href={translator(langCurrent, {
                    es: "/es/inversores",
                    en: "/investors",
                  })}
                  aria-label="Not agree"
                  className="button not_agree"
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: investAgreementFields.vtdNotAgreeButtonLabel.es,
                      en: investAgreementFields.vtdNotAgreeButtonLabel.en,
                    }),
                  }}
                />
              ) : (
                <button
                  aria-label="Not agree"
                  className="button not_agree"
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: investAgreementFields.vtdNotAgreeButtonLabel.es,
                      en: investAgreementFields.vtdNotAgreeButtonLabel.en,
                    }),
                  }}
                  onClick={() => investorPopupHandler(false, null)}
                />
              )}

              {previewBtnLink ? (
                <a
                  href={previewBtnLink + "?ref=1"}
                  aria-label="Agree"
                  className="button agree"
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: investAgreementFields.vtdAgreeButtonLabel.es,
                      en: investAgreementFields.vtdAgreeButtonLabel.en,
                    }),
                  }}
                />
              ) : (
                <button
                  aria-label="Agree"
                  className="button agree"
                  onClick={() => investorPopupHandler(false, null)}
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: investAgreementFields.vtdAgreeButtonLabel.es,
                      en: investAgreementFields.vtdAgreeButtonLabel.en,
                    }),
                  }}
                />
              )}
            </div>
          </div>
        </StyledInvestAgreement>
      )}
    </>
  );
};

const StyledInvestAgreement = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  .popup_wrapper {
    width: 682px;
    height: auto;
    background-color: #fff;
    padding: 20px 28px 90px;
    position: relative;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.3px;
    max-height: 70vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 95vw;
      max-height: 75vh;
      border-radius: 4px;
      padding: 20px 20px 80px;
    }
    .close {
      position: absolute;
      width: fit-content;
      height: fit-content;
      top: 25px;
      right: 25px;
      cursor: pointer;
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: #000;
        }
      }
    }

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #000000;
      width: 70%;
      margin-bottom: 40px;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        width: 90%;
        margin-bottom: 20px;
      }
    }

    .content {
      width: 100%;
      height: auto;
      overflow: auto;
      flex-grow: 1;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
      }
      p {
        margin-bottom: 15px;
      }
      ul {
        width: 100%;
        height: auto;
        li {
          text-indent: 20px;
          margin-bottom: 15px;
          position: relative;
          &:before {
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #000;
            position: absolute;
            display: block;
            left: 6px;
            top: 9px;
          }
        }
      }
    }

    .buttons_line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 52px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        height: 36px;
        justify-content: center;
        bottom: 20px;
      }
      .button {
        width: 190px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: -0.5px;
        cursor: pointer;
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          width: 140px;
        }
        &:hover {
          opacity: 0.8;
          background-color: #005eb8;
          color: #ffffff;
        }
        &.agree {
          color: #ffffff;
          background-color: #005eb8;
        }
      }
    }
  }
`;
