// libraries
import React from "react";
import styled from "styled-components";
// components
import { ContactsForm } from "./ContactsForm";
// constants
import { BREAKPOINTS } from "../../../settings";
// context
import { usePopups } from "../../../context/popupsContext";
// helpers
import { translator } from "../../../helpers/lang";
// static
import { SVG_MENU_CLOSE } from "../../../icons/icons";

export const ContactsFormPopup = ({
  langCurrent,
  setShowContactsFormPopup,
}) => {
  const { contactsFormStatus, setContactsFormStatus } = usePopups();

  function closePopup() {
    let defaultData = {
      isOk: null,
      message: null,
    };

    setContactsFormStatus(defaultData);
    setShowContactsFormPopup(false);
  }

  return (
    <>
      <StyledContactsForm
        className="popup_overlay"
        onMouseDown={e =>
          e.target.classList.contains("popup_overlay") && closePopup()
        }
      >

        <div className="popup_wrapper">
          <div className="request_wrapper">
            <button className="popup_close-btn"
              onClick={closePopup}
              aria-label="Close popup"
              dangerouslySetInnerHTML={{ __html: SVG_MENU_CLOSE }}
            />
            {!contactsFormStatus.message ? (
              <div className="title_block">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: "Consulta sobre los bonos de IDF Spain",
                      en: "Enquiry about IDF Spain bonds",
                    }),
                  }}
                />
                <div
                  className="subtitle"
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: "Complete el formulario y nos pondremos en contacto",
                      en: "Fill in the form and we will contact you",
                    }),
                  }}
                />
              </div>
            ) : null}
            <ContactsForm langCurrent={langCurrent}/>
          </div>
        </div>
      </StyledContactsForm>
    </>
  );
};

const StyledContactsForm = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 56px 0;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  
  .popup_wrapper {
    width: 95vw;
    max-width: 990px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.18);
    margin: 0 auto;
  }
  
  .popup_close-btn {
    position: absolute;
    right: 16px;
    top: 24px;
    z-index: 1;
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
      path {
        fill: #000;
      }
    }

    @media (min-width: ${BREAKPOINTS.lg + "px"}) {
      right: 40px;
      top: 40px;
    }
  }

  .request_wrapper {
    position: relative;
    width: 100%;
    height: auto;
    padding: 24px 0;

    @media (min-width: ${BREAKPOINTS.lg + "px"}) {
      width: 100%;
      height: auto;
      padding: 40px 0;
    }

    .title_block {
      width: 100%;
      height: auto;
      padding: 0 16px;
      margin-bottom: 0;

      @media (min-width: ${BREAKPOINTS.lg + "px"}) {
        padding: 0 40px;
      }

      .title {
        width: 50%;
        font-size: 20px;
        margin-bottom: 15px;
        font-weight: 700;

        @media (min-width: ${BREAKPOINTS.lg + "px"}) {
          width: 100%;
        }
      }

      .subtitle {
        width: 70%;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.6);

        @media (min-width: ${BREAKPOINTS.lg + "px"}) {
          width: 100%;
        }
      }
    }
  }
`;
