// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// components
import { CompareGraph } from "./CompareGraph";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";
// hooks
import { ComparedGraphList } from "../../hooks/use-compared-graph-list";

export const KeyFiguresBlock = ({ data }) => {
  // const dataBase = useSelector(state => state.base);
  // const langCurrent = dataBase.currentLang;

  const comparedGraphList = ComparedGraphList();
  // const growthEvolutionsGraphList = GrowthEvolutionsGraphList();

  const elemId = "key_figures";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      <StyledKeyFiguresBlock id={elemId} ref={blockRef}>
        <div className="bg_layout" />
        <div className="main_wrapper">
          <div className="title_block">
            <h2
              dangerouslySetInnerHTML={{
                __html: data.kfgTitle,
              }}
            />
          </div>

          {comparedGraphList && (
            <div className="compare_graph_wrapper">
              {comparedGraphList.map((graph, index) => (
                <CompareGraph key={index} graph={graph} />
              ))}
            </div>
          )}

          {/*{growthEvolutionsGraphList && (*/}
          {/*  <>*/}
          {/*    <h2*/}
          {/*      className="ge_title"*/}
          {/*      dangerouslySetInnerHTML={{*/}
          {/*        __html: translator(langCurrent, {*/}
          {/*          es: growthEvolutionsGraphList.gegTitle.es,*/}
          {/*          en: growthEvolutionsGraphList.gegTitle.en,*/}
          {/*        }),*/}
          {/*      }}*/}
          {/*    />*/}
          
          {/*    <div className="growth_graph_wrapper">*/}
          {/*      {growthEvolutionsGraphList.gegGraphsList.map((graph, index) => (*/}
          {/*        <GrowthEvolutionGraph key={index} graph={graph} />*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
      </StyledKeyFiguresBlock>
    </>
  );
};

const StyledKeyFiguresBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 54px 0 86px;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding: 60px 0;
  }
  .bg_layout {
    background-color: #e9edf2;
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 15px;
    }
  }

  .compare_graph_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }

  .ge_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.5px;
    margin-bottom: 30px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      text-align: center;
    }
  }

  .growth_graph_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const query = graphql`
  fragment KeyFiguresBlock on WpPage_Components_Components_KeyFiguresBlock {
    kfgTitle
  }
`;
