// libraries
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const WhyIdFinanceBlock = ({ data }) => {
  const [activeTab, setActiveTab] = useState(0);

  const getTabNamesList = opbFinnaceList => {
    let list = [];
    if (!opbFinnaceList) {
      return [];
    }
    for (const name of opbFinnaceList) {
      list.push(name.opbFlTabTitle);
    }
    return list;
  };
  const tabNamesList = getTabNamesList(data.opbFinnaceList);

  const elemId = "why";
  const blockRef = React.useRef(null);

  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {tabNamesList && (
        <StyledWhyIdFinanceBlock
          image={
            data.opbFinnaceList[activeTab].opbFlImage?.localFile?.publicURL
          }
          id={elemId}
          ref={blockRef}
        >
          <div className="tabs_part">
            <div className="bg_layout" />
            <div className="main_wrapper_lg">
              <div className="title_block">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data.wifTitle,
                  }}
                />
              </div>

              <div className="tabs_list">
                {tabNamesList.map((tabName, index) => (
                  <button
                    key={index}
                    className={`tab ${index === activeTab ? "active" : ""}`}
                    dangerouslySetInnerHTML={{
                      __html: tabName,
                    }}
                    aria-label={tabName}
                    onClick={event => {
                      setActiveTab(index);
                      event.target.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "start",
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="content_part">
            <div className="bg_layout" />
            <div className="main_wrapper_lg">
              <div className="content">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: data.opbFinnaceList[activeTab].opbFlTitle,
                  }}
                />
                <div
                  className="desc"
                  dangerouslySetInnerHTML={{
                    __html: data.opbFinnaceList[activeTab].opbFlContent,
                  }}
                />
              </div>

              <div className="image" />
            </div>
          </div>
        </StyledWhyIdFinanceBlock>
      )}
    </>
  );
};

const StyledWhyIdFinanceBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 70px;
    text-align: center;
    color: #fff;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 15px;
    }

    span {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      br {
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          display: none;
        }
      }
    }
  }

  .tabs_part {
    width: 100%;
    height: auto;
    padding-top: 100px;
    background-color: #0a243f;
    overflow: hidden;
    position: relative;
    .bg_layout {
      &:before {
        background-color: #19324b;
        right: 1142px;
        @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
          right: 937px;
        }
      }
      &:after {
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          display: none;
        }
      }
    }

    .tabs_list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      overflow: auto;
      .tab {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: -0.3px;
        color: #ffffff;
        opacity: 0.5;
        margin: 4px 0;
        position: relative;
        cursor: pointer;
        min-width: 200px;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        padding: 10px;
        white-space: nowrap;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          font-size: 16px;
          line-height: 16px;
          letter-spacing: -0.444444px;
          min-width: auto;
          padding: 10px 20px;
        }
        &:after {
          content: "";
          width: 100%;
          height: 2px;
          position: absolute;
          left: 0;
          bottom: -2px;
          background-color: #fff;
        }
        &.active {
          opacity: 1;
        }
      }
    }
  }

  .content_part {
    width: 100%;
    height: auto;
    padding: 113px 0 70px;
    background-color: #19314b;
    overflow: hidden;
    position: relative;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      padding: 80px 0 0;
    }

    .bg_layout {
      &:before {
        background-color: #273e56;
        right: 1360px;
        @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
          right: 1149px;
        }
      }
      &:after {
        background-color: #273e56;
        right: -2100px;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          display: none;
        }
      }
    }

    .main_wrapper_lg {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        padding-bottom: 208px;
      }
    }
    .content {
      width: 35%;
      height: auto;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        width: 40%;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        margin-bottom: 40px;
      }
      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: #ffffff;
        margin-bottom: 24px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 32px;
        }
      }
      .desc {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #ffffff;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }
    }
    .image {
      width: 600px;
      height: 300px;
      background-image: url(${props => (props.image ? props.image : "")});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      margin-left: auto;
      border-radius: 8px;
      overflow: hidden;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        width: 50%;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        height: 188px;
        width: calc(100% + 40px);
        position: absolute;
        left: 0;
        bottom: 0;
        border-radius: 0;
      }
      &:after {
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          transform: skewX(-30deg);
          background-color: rgba(255, 255, 255, 0.5);
          right: -102px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment WhyIdFinanceBlock on WpPage_Components_Components_WhyIdFinanceBlock {
    wifTitle
    opbFinnaceList {
      opbFlContent
      opbFlTabTitle
      opbFlTitle
      opbFlImage {
        localFile {
          publicURL
        }
      }
    }
  }
`;
