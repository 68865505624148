// libraries
import React, { useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
// constants
import { BREAKPOINTS } from "../../../settings";
// static
import { SVG_LONG_ARROV_LEFT } from "../../../icons/icons";

export const HistoryYearsSlider = ({ historyList }) => {
  const swiperRef = React.useRef(null);
  const sliderParams = {
    slidesPerView: 1.5,
    spaceBetween: 0,
    loop: false,
    breakpoints: {
      576: {
        slidesPerView: 2.4,
      },
      992: {
        slidesPerView: 3.4,
      },
      1200: {
        slidesPerView: 4.4,
      },
    },
  };

  const [slideClass, setSlideClass] = useState("hide_prev_nav");
  const checkSliderClass = (isBeginning, isEnd) => {
    let classes = [];
    isEnd && classes.push("hide_next_nav");
    isBeginning && classes.push("hide_prev_nav");
    setSlideClass(classes.join(" "));
  };

  return (
    <>
      {historyList && (
        <StyledHistoryYearsSlider className={`${slideClass}`}>
          <Swiper
            {...sliderParams}
            ref={swiperRef}
            onSlideChange={Swiper =>
              checkSliderClass(Swiper.isBeginning, Swiper.isEnd)
            }
          >
            {historyList.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  className="year"
                  dangerouslySetInnerHTML={{
                    __html: item.hpbHlYear,
                  }}
                />

                <div className="list-wrapper">
                  <ul>
                    {item.hpbHlEventList.map((evt, evtIndex) => (
                      <li
                        key={index + "-" + evtIndex}
                        dangerouslySetInnerHTML={{
                          __html: evt.hpbEvlEvent,
                        }}
                      />
                    ))}
                  </ul>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={`custom_navs ${slideClass}`}>
            <button
              aria-label="previous slide"
              className="prev-slide"
              dangerouslySetInnerHTML={{
                __html: SVG_LONG_ARROV_LEFT,
              }}
              onClick={() => swiperRef.current.swiper.slidePrev()}
            />

            <button
              aria-label="next slide"
              className="next-slide"
              dangerouslySetInnerHTML={{
                __html: SVG_LONG_ARROV_LEFT,
              }}
              onClick={() => swiperRef.current.swiper.slideNext()}
            />
          </div>
        </StyledHistoryYearsSlider>
      )}
    </>
  );
};

const StyledHistoryYearsSlider = styled.div`
  width: 95%;
  margin: 0 auto;
  height: auto;
  position: relative;
  &.hide_next_nav {
    &:after {
      display: none;
    }
  }
  &.hide_prev_nav {
    &:before {
      display: none;
    }
  }
  &:after {
    content: "";
    width: 200px;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    right: -5%;
    background: linear-gradient(
      to right,
      rgba(232, 237, 242, 0.0001) 2%,
      #e8edf2 60%
    );
    z-index: 3;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 25px;
      background: linear-gradient(
        to right,
        rgba(232, 237, 242, 0.4),
        rgba(232, 237, 242, 1)
      );
    }
  }

  &:before {
    content: "";
    width: 200px;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: -5%;
    background: linear-gradient(
      to left,
      rgba(232, 237, 242, 0.0001) 2%,
      #e8edf2 60%
    );
    z-index: 3;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      display: none;
    }
  }

  .swiper-wrapper {
    padding-left: 12px;
    z-index: 1;
  }

  .swiper-slide {
    position: relative;
    height: auto;
    .year {
      width: 100%;
      height: auto;
      font-weight: 700;
      font-size: 60px;
      line-height: 66px;
      letter-spacing: -0.3px;
      color: #005eb8;
      margin-bottom: 44px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 48px;
      }
    }

    .list-wrapper {
      position: relative;
      &:before {
        content: "";
        width: 13px;
        height: 13px;
        border-radius: 50%;
        position: absolute;
        background-color: #0a2540;
        left: -6px;
        top: -6px;
      }

      ul {
        border-top: 1px solid rgba(10, 37, 64, 0.2);
        padding-top: 65px;
        margin: 0 12px;
        &:after {
          content: "";
          width: 1px;
          height: 54px;
          position: absolute;
          background-color: rgba(10, 37, 64, 0.2);
          left: 0;
          top: 12px;
        }
        li {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.3px;
          padding: 0 20px 0 14px;
          position: relative;
          padding-bottom: 16px;
          margin-left: -12px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 17px;
          }
          &:before {
            content: "";
            width: 8px;
            height: 1px;
            position: absolute;
            background-color: rgba(10, 37, 64, 0.2);
            left: 0;
            top: 13px;
          }
          &:after {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            background-color: rgba(10, 37, 64, 0.2);
            left: 0;
            top: 0;
          }

          &:last-child {
            &:after {
              height: 13px;
            }
          }
        }
      }
    }
  }

  .custom_navs {
    width: 100%;
    height: 0;
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    z-index: 4;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      display: none;
    }

    &.hide_next_nav {
      .next-slide {
        display: none;
      }
    }

    &.hide_prev_nav {
      .prev-slide {
        display: none;
      }
    }

    button {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      position: relative;
      cursor: pointer;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      &.next-slide {
        margin-left: auto;
        svg {
          transform: rotate(180deg);
        }
      }
      .prev-slide {
        margin-right: auto;
      }

      svg {
        width: 17px;
        height: 17px;
        path {
          fill: #000;
          stroke: #000;
        }
      }
    }
  }
`;
