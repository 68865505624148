// libraries
import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// static
import { SVG_ROUND_NAV_RIGHT } from "../../icons/icons";

export const CompanyWaysMainPageBlock = ({ data }) => {
  return (
    <>
      {data && (
        <StyledCompanyWaysMainPageBlock className="block-container">
          <div className="bg_layout" />

          <StyledMainWrapper>
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.cwmBlockTitle,
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: data.cwmBlockSubtitle,
                }}
              />
            </div>

            <div className="ways_list">
              {data.cwmWaysList.map((item, index) => (
                <div key={index} className="list_item">
                  <div
                    className="icon"
                    dangerouslySetInnerHTML={{
                      __html: item.cwmWaysListIcon,
                    }}
                  />
                  <Link to={item.cwmWaysListLink} className="title">
                    <h3
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: item.cwmWaysListTitle,
                      }}
                    />
                    <span
                      className="svg"
                      dangerouslySetInnerHTML={{
                        __html: SVG_ROUND_NAV_RIGHT,
                      }}
                    />
                  </Link>

                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: item.cwmWaysListSubtitle,
                    }}
                  />
                </div>
              ))}
            </div>
          </StyledMainWrapper>
        </StyledCompanyWaysMainPageBlock>
      )}
    </>
  );
};

const StyledCompanyWaysMainPageBlock = styled.div`
  width: 100%;
  padding: 80px 0 90px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
    padding-top: 60px;
  }
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    height: 1300px;
  }
  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    height: 1350px;
  }

  .content {
    font-size: 36px;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      font-size: 32px;
    }
  }
  .bg_layout {
    background-color: #eff3f6;
    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 935px;
      background-color: #f8fafb;
      transform: skewX(-30deg);

      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 709px;
      }

      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: 835px;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 640px;
      }
    }

    &:after {
      width: 200%;
      height: 100%;
      position: absolute;
      background-color: #f8fafb;
      transform: skewX(-30deg);
      top: 0;
      right: -780px;
      display: none;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        display: block;
      }
    }
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 25px;
    }

    h2 {
      margin-bottom: 15px;
    }

    span {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 17px;
      }
      br {
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          display: none;
        }
      }
    }
  }

  .ways_list {
    width: 100%;
    height: auto;
    display: flex;
    column-gap: 24px;
    justify-content: space-between;
    max-width: 1216px;
    margin: auto;

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      flex-direction: column;
    }

    .list_item {
      flex: 1;
      padding: 35px 12px;
      height: auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
        margin-bottom: 0;
      }
      .icon {
        width: 100%;
        min-height: 70px;
        margin: 0 auto 27px;
        svg {
          width: 58px;
          height: 58px;
        }
      }
      .title {
        position: relative;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: #005eb8;
        margin-bottom: 17px;
        display: block;
        text-align: center;
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          white-space: normal;
        }
        @media (max-width: ${BREAKPOINTS.xsm + "px"}) {
          font-size: 24px;
          line-height: 24px;
        }
        h3 {
          display: inline;
        }
        .svg {
          margin-left: 10px;
          display: inline;
          position: relative;
          bottom: 1px;
          @media (max-width: ${BREAKPOINTS.xsm + "px"}) {
            display: none;
          }
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
      .subtitle {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }
    }
  }
`;

const StyledMainWrapper = styled.div`
  height: auto;
  margin: 0 auto;
  position: relative;
  z-index: 5;

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    width: 100%;
    padding: 0 20px;
  }
`;

export const query = graphql`
  fragment CompanyWaysMainPageBlock on WpPage_Components_Components_CompanyWaysMainPageBlock {
    cwmBlockSubtitle
    cwmBlockTitle
    cwmWaysList {
      cwmWaysListTitle
      cwmWaysListLink
      cwmWaysListSubtitle
      cwmWaysListIcon
    }
  }
`;
