// libraries
import { useStaticQuery, graphql } from "gatsby";

export const TranslatePositions = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        acfOptionsGeneral {
          settings {
            departments {
              name
              nameEs
            }
            locations {
              city
              cityEs
              country
              countryEs
            }
          }
        }
      }
    }
  `);

  return data?.wp.acfOptionsGeneral?.settings;
};
