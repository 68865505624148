// libraries
import React from "react";
import styled from "styled-components";
// components
import { AcceptCheckboxWithMessage } from "./AcceptCheckboxWithMessage";
// constants
import { BREAKPOINTS, FILE_INPUT_DEFAULT_MESSAGE } from "../../../../settings";
// context
import { useLang } from "../../../../context/languageContext";
import { usePopups } from "../../../../context/popupsContext";
// helpers
import {
  checkForm,
  sendPositionForm,
  checkAllInputs,
} from "../../../../helpers/forms";
import { translator } from "../../../../helpers/lang";
// hooks
import { useBaseStore } from "../../../../store";
// static
import { SVG_UPLOAD_ICON } from "../../../../icons/icons";

export const PositionForm = ({ data }) => {
  const { state, dispatch } = useBaseStore();
  const { lang:langCurrent } = useLang();
  const showPositionFormOverlay = state.showPositionFormOverlay;
  const { positionFormStatus, setPositionFormStatus } = usePopups();
  const labelES =
    "He leído y acepto la <a href='/es/politica-de-proteccion-de-datos-carreras/' target='_blank' rel='noreferrer noopener'>Política de Privacidad</a>";
  const labelEN =
    "I have read and accept the <a href='/data-protection-policy-careers/' target='_blank' rel='noreferrer noopener'>Privacy Policy</a>";

  const positionForm = React.createRef();

  return (
    <>
      {data && (
        <StyledPositionForm>
          {positionFormStatus.isOk ? (
            <div
              className="form_sended"
              dangerouslySetInnerHTML={{
                __html: positionFormStatus.message,
              }}
            />
          ) : (
            <>
              {positionFormStatus.isOk === false &&
                positionFormStatus.message && (
                  <div
                    className="fail_send"
                    dangerouslySetInnerHTML={{
                      __html: positionFormStatus.message,
                    }}
                  />
                )}

              <form
                id="position_form"
                className="position_form"
                ref={positionForm}
                onSubmit={event => {
                  event.preventDefault();
                  if (checkAllInputs(positionForm, langCurrent)) {
                    sendPositionForm(
                      dispatch,
                      positionForm.current,
                      langCurrent,
                      setPositionFormStatus
                    );
                  }
                }}
              >
                <input
                  type="hidden"
                  name="position_email"
                  value={data.positions.email}
                />
                <input
                  type="hidden"
                  name="department"
                  value={data.positions.department}
                />
                <input
                  type="hidden"
                  name="location"
                  value={data.positions.location[0]}
                />
                <div className="user_metrick">
                  <div data-checked={null} className="input_wrapper">
                    <input
                      data-req={true}
                      autoComplete="name"
                      type="text"
                      name="first_name"
                      placeholder={translator(langCurrent, {
                        es: "Nombre",
                        en: "First name",
                      })}
                      onChange={event => checkForm(event)}
                    />
                    <div
                      className={translator(langCurrent, {
                        es: "error error_es",
                        en: "error",
                      })}
                      dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                          es: "El campo es obligatorio",
                          en: "The field is required",
                        }),
                      }}
                    />
                  </div>

                  <div data-checked={null} className="input_wrapper">
                    <input
                      data-req={true}
                      autoComplete={"family-name"}
                      type="text"
                      name="last_name"
                      placeholder={translator(langCurrent, {
                        es: "Apellido",
                        en: "Last name",
                      })}
                      onChange={event => checkForm(event)}
                    />

                    <div
                      className={translator(langCurrent, {
                        es: "error error_es",
                        en: "error",
                      })}
                      dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                          es: "El campo es obligatorio",
                          en: "The field is required",
                        }),
                      }}
                    />
                  </div>
                </div>

                <div data-checked={null} className="input_wrapper">
                  <input
                    data-req={true}
                    autoComplete={"email"}
                    type="text"
                    name="email"
                    placeholder={translator(langCurrent, {
                      es: "Email",
                      en: "Email",
                    })}
                    onChange={event => checkForm(event)}
                  />
                  <div
                    className={translator(langCurrent, {
                      es: "error error_es",
                      en: "error",
                    })}
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        // es: "Email incorrecto",
                        // en: "Incorrect email",
                        es: "El campo es obligatorio",
                        en: "The field is required",
                      }),
                    }}
                  />
                </div>

                <div data-checked={null} className="input_wrapper">
                  <textarea
                    data-req={true}
                    autoComplete="off"
                    name="message"
                    placeholder={translator(langCurrent, {
                      es: "Mensaje",
                      en: "Your message",
                    })}
                    onChange={event => checkForm(event)}
                  />
                  <div
                    className={translator(langCurrent, {
                      es: "error error_es",
                      en: "error",
                    })}
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "El campo es obligatorio",
                        en: "The field is required",
                      }),
                    }}
                  />
                </div>

                <div data-checked={null} className="input_wrapper file">
                  <label htmlFor="file">
                    <span
                      className="svg"
                      dangerouslySetInnerHTML={{
                        __html: SVG_UPLOAD_ICON,
                      }}
                    />
                    <span
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                          es: FILE_INPUT_DEFAULT_MESSAGE.message.es,
                          en: FILE_INPUT_DEFAULT_MESSAGE.message.en,
                        }),
                      }}
                    />
                  </label>

                  <input
                    data-req={true}
                    type="file"
                    name="file"
                    id="file"
                    onChange={event => !checkForm(event)}
                  />

                  <div
                    className="error"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "No está permitido subir archivos de este tipo",
                        en: "Upload failed. Files must be less than 4MB in doc docx or pdf extensions",
                      }),
                    }}
                  />
                </div>

                <AcceptCheckboxWithMessage
                  labelES={labelES}
                  labelEN={labelEN}
                  langCurrent={langCurrent}
                />

                <button
                  type="submit"
                  className="btn"
                  aria-label="Send message"
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: "Enviar mensaje",
                      en: "Send message",
                    }),
                  }}
                />
              </form>

              {showPositionFormOverlay && <div className="form_overlay" />}
            </>
          )}
        </StyledPositionForm>
      )}
    </>
  );
};

const StyledPositionForm = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 40px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding: 10px;
  }
  .form_sended,
  .fail_send {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #005eb8;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      height: 50px;
      font-size: 16px;
    }
  }

  .fail_send {
    color: #c35249;
    height: 60px;
    border: 1px solid #c35249;
    margin-bottom: 20px;
    font-size: 18px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      height: 40px;
      font-size: 16px;
    }
  }

  .form_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 50;
  }

  .input_wrapper {
    width: 100%;
    height: auto;
    padding-bottom: 20px;
    position: relative;

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      padding-bottom: 20px;
    }

    &[data-checked="false"] {
      input,
      textarea {
        border: 1px solid rgba(195, 82, 73, 0.4);
      }
      .error {
        display: block;
        &_es {
          @media (max-width: 400px) {
            bottom: 9px;
          }
          @media (max-width: 380px) {
            bottom: 1px;
          }
        }
      }
    }
    &[data-checked="true"] {
      input,
      textarea {
        border: 1px solid rgba(63, 140, 66, 0.4);
      }
      .error {
        display: none;
      }
    }
    .error {
      width: 100%;
      padding-top: 4px;
      color: #ba1a1a;
      font-size: 16px;
      display: none;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 15px;
        bottom: 8px;
      }
    }
  }

  input,
  textarea {
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px 0 inset;
    border-radius: 4px;
    padding: 10px 15px;
    width: 100%;
    line-height: 1.33;
    -webkit-appearance: none;
    font-size: 18px;
    font-family: "PT Sans", arial, sans-serif;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      font-size: 17px;
    }
    &::placeholder {
      color: #6d7288;
    }
  }

  textarea {
    height: 110px;
    resize: none;
    font-family: "PT Sans", arial, sans-serif;
  }

  .user_metrick {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .input_wrapper {
      width: 48%;
    }
  }

  .file {
    width: 100%;
    height: 80px;
    border: 4px dashed grey;
    border-radius: 4px;
    margin-bottom: 40px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      margin-bottom: 30px;
      border: 3px dashed grey;
    }
    &[data-checked="false"] {
      margin-bottom: 60px;
      border: 3px dashed rgba(195, 82, 73, 0.4);
      .error {
        display: block;
        margin-top: 80px;
      }
    }
    &[data-checked="true"] {
      border: 3px dashed rgba(63, 140, 66, 0.4);
      .error {
        display: none;
      }
    }
    input {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 5;
      color: transparent;
      opacity: 0;
      cursor: pointer;
    }
    label {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 3;
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 18px;
      color: #6d7288;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        padding: 0 60px;
        font-size: 17px;
      }
      .svg {
        width: auto;
        height: auto;
        position: absolute;
        left: 20px;
        top: calc(50% - 20px);
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          left: 10px;
        }
        svg {
          width: 40px;
          height: 40px;
          fill: #005eb8;
        }
      }
      .content {
        width: 100%;
        height: auto;
        text-align: center;
        padding: 0 20px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          text-align: left;
        }
        span {
          font-weight: 700;
          color: #005eb8;
        }
      }
    }
    .error {
      bottom: -28px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        max-width: 400px;
        bottom: -34px;
      }
    }
  }

  .btn {
    width: fit-content;
    height: 44px;
    background-color: #005eb8;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 40px auto 0;
    font-size: 14px;
    font-weight: 700;
    font-family: "PT Sans", arial, sans-serif;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      margin-top: 30px;
    }
    &:hover {
      &:not(:disabled) {
        opacity: 0.8;
      }
    }
    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
  input[type="checkbox"] {
    margin-top: 16px !important;
  }
  .item {
    padding-top: 40px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      padding-top: 30px;
    }
  }
`;
