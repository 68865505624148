// libraries
import React from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../../settings";

export const PromoMainPageContent = ({ title }) => {
  return (
    <>
      {title && (
        <StyledPromoMainPageContent>
          <div className="main_wrapper_lg">
            <h1
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </div>
        </StyledPromoMainPageContent>
      )}
    </>
  );
};

const StyledPromoMainPageContent = styled.div`
  position: relative;
  z-index: 4;
  height: 100%;
  width: 100%;
  .main_wrapper_lg {
    padding: 160px 0 0 40px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      padding-top: 114px;
    }
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding-left: 20px;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 76px;
    line-height: 76px;
    color: #ffffff;
    width: 70%;
    display: block;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      width: 55%;
      font-size: 50px;
      line-height: 50px;
    }
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 70%;
      font-size: 42px;
      line-height: 40px;
    }

    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 80%;
      padding-right: 10px;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 94%;
      line-height: 40px;
    }

    span {
      color: #005eb8;
    }
  }

  @media (min-width: ${BREAKPOINTS.lg + "px"}) {
  }
`;
