// libraries
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const HowDoWeDoItBlock = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const activeContent = data.hdwTabsList[activeIndex];

  const elemId = "smart_scoring";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledHowDoWeDoItBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />

          <div className="header_block">
            <div className="main_wrapper">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.hdwTitle,
                }}
              />
            </div>
          </div>

          <div className="subheader_block">
            <div className="bg_layout" />
            <div className="main_wrapper">
              <div className="subtitile_block">
                <h3
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html: data.hdwSubtitle,
                  }}
                />

                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html: data.hdwSubcontent,
                  }}
                />
              </div>

              <div className="tabs_list">
                {data.hdwTabsList.map((tab, index) => (
                  <button
                    key={index}
                    className={`tab ${activeIndex === index ? "active" : ""} `}
                    aria-label={tab.hdwTlTabTitle}
                    dangerouslySetInnerHTML={{
                      __html: tab.hdwTlTabTitle,
                    }}
                    onClick={event => {
                      setActiveIndex(index);
                      event.target.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "start",
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="content_wrapper">
            <div className="bg_layout" />
            <div className="main_wrapper">
              <div className="content_list">
                <div className="content_part">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: activeContent.hdwTlTitle,
                    }}
                  />
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: activeContent.hdwTlContent,
                    }}
                  />
                </div>

                <div
                  className="image_wrapper"
                  dangerouslySetInnerHTML={{
                    __html: activeContent.hdwTlSvgImage,
                  }}
                />
              </div>
            </div>
          </div>
        </StyledHowDoWeDoItBlock>
      )}
    </>
  );
};

const StyledHowDoWeDoItBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  .header_block {
    width: 100%;
    height: 220px;
    background-color: #0a243f;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 59px;
      letter-spacing: -0.5px;
      color: #ffffff;
      text-align: center;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }

  .subheader_block {
    padding-top: 80px;
    position: relative;
    overflow: hidden;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding-top: 67px;
    }
    .main_wrapper {
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        padding: 0;
      }
    }

    .bg_layout {
      background-color: #19324b;
      &:after {
        background-color: #12253a;
        right: -1800px;
        @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
          right: -1300px;
        }
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          right: -700px;
        }
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          right: -472px;
        }
      }
    }

    .subtitile_block {
      width: 60%;
      margin: 0 auto 80px;
      color: #fff;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 80%;
      }
      @media (max-width: ${BREAKPOINTS.xsm + "px"}) {
        width: 100%;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        padding: 0 20px;
        margin-bottom: 68px;
      }
      .title {
        width: 100%;
        height: auto;
        text-align: center;
        font-weight: 700;
        font-size: 36px;
        line-height: 32px;
        letter-spacing: -0.5px;
        margin-bottom: 24px;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          font-size: 32px;
          line-height: 30px;
          margin-bottom: 48px;
        }
      }
      .content {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }
    }

    .tabs_list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: auto;
      .tab {
        width: 100%;
        min-width: 200px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #ffffff;
        opacity: 0.5;
        cursor: pointer;
        position: relative;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          min-width: auto;
          padding: 0 20px;
          white-space: nowrap;
        }
        &.active {
          opacity: 1;
        }
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background-color: #fff;
        }
        &:hover {
          &:not(.active) {
            opacity: 0.7;
          }
        }
      }
    }
  }

  .content_wrapper {
    padding: 0;
    color: #fff;
    position: relative;
    overflow: hidden;
    height: 550px;
    display: flex;
    align-items: center;

    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      height: 700px;
    }

    .bg_layout {
      background-color: #273e56;
      &:after {
        height: 2000px;
        background-color: #1c2e43;
        right: -1132px;
        @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
          right: -633px;
        }
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          right: -32px;
        }
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          right: -26px;
        }
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          right: 202px;
        }
      }
    }

    .content_list {
      width: 100%;
      max-width: 980px;
      margin: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .content_part {
        width: 50%;
        height: auto;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          width: 60%;
        }
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          width: 100%;
          margin-bottom: 40px;
        }
        .title {
          font-weight: 700;
          font-size: 36px;
          line-height: 32px;
          margin-bottom: 24px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 32px;
          }
          @media (max-width: ${BREAKPOINTS.md + "px"}) {
            text-align: center;
            margin-bottom: 30px;
            line-height: 30px;
          }
        }

        .content {
          font-size: 18px;
          line-height: 24px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 17px;
          }
          p {
            margin-bottom: 15px;
          }
          ul {
            width: 100%;
            height: auto;
            li {
              position: relative;
              margin-bottom: 24px;
              padding-left: 20px;
              &:last-child {
                margin-bottom: 0;
              }
              &:before {
                content: "";
                width: 4px;
                height: 4px;
                background-color: #078be4;
                border-radius: 50%;
                position: absolute;
                top: 8px;
                left: 0px;
              }
            }
          }
        }
      }

      .image_wrapper {
        width: 35%;
        height: auto;
        margin-left: auto;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          width: 80%;
          margin: 0 auto;
        }

        svg {
          width: 100%;
          height: auto;
          max-height: 316px;
          @media (max-width: ${BREAKPOINTS.md + "px"}) {
            max-height: 230px;
          }
        }
      }
    }
  }
`;

export const query = graphql`
  fragment HowDoWeDoItBlock on WpPage_Components_Components_HowDoWeDoItBlock {
    hdwSubcontent
    hdwSubtitle
    hdwTitle
    hdwTabsList {
      hdwTlContent
      hdwTlSvgImage
      hdwTlTabTitle
      hdwTlTitle
    }
  }
`;
