// libraries
import React from "react";
import styled from "styled-components";
// components
import { BREAKPOINTS } from "../../settings";

export const Title = ({ title }) => {
  return (
    <StyledTitle
      dangerouslySetInnerHTML={{
        __html: title,
      }}
    />
  );
};

const StyledTitle = styled.h2`
  color: #fff;
  width: 100%;
  height: auto;
  margin-bottom: 24px;
  h2 {
    font-weight: 700;
    font-size: 48px;
    line-height: 48px;
    letter-spacing: -0.5px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      font-size: 32px;
      line-height: 32px;
    }
  }
`;
