// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const TechnologyDrivenAdvantagesBlock = ({ data }) => {
  const elemId = "proprietary_software_platform";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledTechnologyDrivenAdvantagesBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="title_block">
              <div className="title">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data.tdaTitle,
                  }}
                />
                <span
                  dangerouslySetInnerHTML={{
                    __html: data.tdaSubtitle,
                  }}
                />
              </div>
            </div>

            <div className="advantages_list">
              {data.tdaAdvantagesList.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="icon"
                    dangerouslySetInnerHTML={{
                      __html: item.tdaAlIcon,
                    }}
                  />

                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.tdaAlTitle,
                    }}
                  />

                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: item.tdaAlContent,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </StyledTechnologyDrivenAdvantagesBlock>
      )}
    </>
  );
};

const StyledTechnologyDrivenAdvantagesBlock = styled.div`
  width: 100%;
  height: 900px;
  position: relative;
  overflow: hidden;
  padding-top: 100px;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding-top: 70px;
  }
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    height: auto;
  }
  .bg_layout {
    background-color: #f1f4f7;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      background-color: #e8edf2;
    }
    &:after {
      background-color: #e8edf2;
      right: 914px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: 771px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        right: 840px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -430px;
        background-color: #f1f4f7;
      }
    }
  }

  .title_block {
    width: 60%;
    height: auto;
    margin: 0 auto 60px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 70%;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 100%;
    }
    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      text-align: center;
      letter-spacing: -0.5px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 32px;
        line-height: 32px;
      }
    }
    span {
      display: block;
      width: 100%;
      text-align: center;
      color: #919294;
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      letter-spacing: -0.5px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 32px;
        line-height: 32px;
      }
    }
  }

  .advantages_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    .item {
      width: 40%;
      height: auto;
      margin-bottom: 30px;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        margin-bottom: 60px;
        margin-right: 0;
      }
      .icon {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 25px;
        svg {
          width: 55px;
          height: 55px;
        }
      }
      .title {
        font-weight: 700;
        font-size: 36px;
        line-height: 32px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #005eb8;
        margin-bottom: 15px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 32px;
        }
      }
      .content {
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.3px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment TechnologyDrivenAdvantagesBlock on WpPage_Components_Components_TechnologyDrivenAdvantagesBlock {
    tdaSubtitle
    tdaTitle
    tdaAdvantagesList {
      tdaAlContent
      tdaAlIcon
      tdaAlTitle
    }
  }
`;
