// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const HowWeWorkBlock = ({ data }) => {
  const elemId = "how_we_work";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledHowWeWorkBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.hwwTitle,
                }}
              />

              <span
                dangerouslySetInnerHTML={{
                  __html: data.hwwSubtitle,
                }}
              />
            </div>

            <div className="icons_list">
              {data.hwwIconsList.map((item, index) => (
                <div key={index} className="icon">
                  <img
                    src={item.hwwIlIcon?.localFile?.publicURL}
                    alt="Logotype"
                  />
                </div>
              ))}
            </div>
          </div>
        </StyledHowWeWorkBlock>
      )}
    </>
  );
};

const StyledHowWeWorkBlock = styled.div`
  width: 100%;
  height: 1100px;
  position: relative;
  padding-top: 100px;

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding-top: 60px;
  }

  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    height: 700px;
  }

  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    height: auto;
    padding-bottom: 60px;
  }

  .bg_layout {
    background-color: #e8edf2;
    &:after {
      background-color: #f1f4f7;
      right: -1097px;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -49px;
      }
    }
  }

  .title_block {
    text-align: center;
    width: 55%;
    margin: 0 auto 60px;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 100%;
      margin-bottom: 40px;
    }
    h2 {
      margin-bottom: 24px;
    }
    span {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.3px;
      color: #000000;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 17px;
      }
    }
  }

  .icons_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .icon {
      width: 32%;
      height: 230px;
      background: #ffffff;
      border: 1px solid #e6e6e6;
      border-radius: 16px;
      margin-right: 2%;
      margin-bottom: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        height: 120px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        height: 68px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      img {
        width: auto;
        height: auto;
        max-width: 80%;
        max-height: 90%;
      }
    }
  }
`;

export const query = graphql`
  fragment HowWeWorkBlock on WpPage_Components_Components_HowWeWorkBlock {
    hwwSubtitle
    hwwTitle
    hwwIconsList {
      hwwIlIcon {
        localFile {
          publicURL
        }
      }
    }
  }
`;
