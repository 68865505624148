// libraries
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// actions
import { updateListCarrer } from "../../store/actions/base";
// components
import { PositionPopup } from "./PositionPopup";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { useLang } from "../../context/languageContext";
import { usePopups } from "../../context/popupsContext";
// helpers
import { translator } from "../../helpers/lang";
import { getAllUrlParameters, scrollToElemTargetId } from "../../helpers/base";
// hooks
import { PositionsList } from "../../hooks/use-positions-list";
import { TranslatePositions } from "../../hooks/use-trnslate-positions";
import { useBaseStore } from "../../store";
// static
import { SVG_SHEVRON_BOTTOM_ICON } from "../../icons/icons";

function checkGetParamPosition(getParam, listPosition, callPopup) {
  const positUrl = window.location.href.split("/?");
  const newPositUrl = `/?${positUrl[1]}`;
  if (window.location.href.indexOf(getParam) > -1) {
    for (let i = 0; i < listPosition.length; i++) {
      if (listPosition[i].uri === newPositUrl) {
        callPopup(i);
      }
    }
  }
}

export const OpenPositionsBlock = ({ data }) => {
  const { state, dispatch } = useBaseStore();
  const { lang: langCurrent } = useLang();
  const { showPositionPopup, setShowPositionPopup } = usePopups();
  const positionsList = PositionsList();
  const translatePositions = TranslatePositions();

  function getPositionHeadersList(positionsList) {
    let depArr = {
      departaments: ["All departments"],
      locations: ["All locations"],
    };

    for (const position of positionsList) {
      if (!depArr.departaments.includes(position.positions.department)) {
        depArr.departaments.push(position.positions.department);
      }

      if (!depArr.locations.includes(position.positions.location[0])) {
        depArr.locations.push(position.positions.location[0]);
      }
    }

    return depArr;
  }

  function translateDepartaments(depTitleEn, translatePositions, langCurrent) {
    if (langCurrent === "en") {
      return depTitleEn;
    }

    if (depTitleEn === "All departments") {
      return "Todos los departamentos";
    }

    let depTitleEs = translatePositions.departments?.filter(
      item => item.name === depTitleEn
    )[0]?.nameEs;

    return depTitleEs ? depTitleEs : depTitleEn;
  }

  function translateLocationsCountry(
    locTitleEn,
    translatePositions,
    langCurrent
  ) {
    if (langCurrent === "en") {
      return locTitleEn;
    }

    if (locTitleEn === "All locations") {
      return "Todas las localizaciones";
    }

    let locTitleEs = translatePositions.locations?.filter(
      item => item.country === locTitleEn
    )[0]?.countryEs;

    return locTitleEs ? locTitleEs : locTitleEn;
  }

  function getSortedPositionsList(positionsList, depLabel, locLabel) {
    if (depLabel === "All departments" && locLabel === "All locations") {
      return positionsList;
    }

    let sortedByDepArray = [];
    let sortedArr = [];

    if (depLabel !== "All departments") {
      for (const position of positionsList) {
        if (position.positions.department === depLabel) {
          sortedByDepArray.push(position);
        }
      }
    } else {
      sortedByDepArray = positionsList;
    }

    if (locLabel !== "All locations") {
      for (const position of sortedByDepArray) {
        for (const location of position.positions.location) {
          if (location === locLabel) {
            sortedArr.push(position);
            break;
          }
        }
      }
    } else {
      sortedArr = sortedByDepArray;
    }

    return Array.from(sortedArr);
  }

  function getFullLocationHTML(country, translatePositions, langCurrent) {
    let currentLocation = translatePositions.locations?.filter(
      item => item.country === country
    )[0];

    // if es lang
    if (langCurrent === "es") {
      return currentLocation.cityEs + ", " + currentLocation.countryEs;
    } else {
      return currentLocation.city + ", " + currentLocation.country;
    }
  }

  const [urlPosition, setUrlPosition] = useState(null);

  useEffect(() => {
    if (urlPosition !== null) {
      window.history.pushState({}, "", `${urlPosition}`);
    }
  }, [urlPosition]);

  useEffect(() => {
    checkGetParamPosition("/?position", sortedPositionsList, togglePopup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function togglePopup(index) {
    setChoisedPosition(sortedPositionsList[index]);
    setShowPositionPopup(true);
    setUrlPosition(
      translator(langCurrent, {
        es: `/es/carreras${sortedPositionsList[index].uri}`,
        en: `/careers${sortedPositionsList[index].uri}`,
      })
    );
  }

  const openListHandler = type => {
    if (isShowDep && type === "dep") {
      updateListCarrer(dispatch, false);
    } else if (isShowLoc && type === "loc") {
      updateListCarrer(dispatch, false);
    } else {
      updateListCarrer(dispatch, type);
    }
  };

  useEffect(() => {
    switch (state.showCarrerList) {
      case "dep":
        setShowDep(!isShowDep);
        setShowloc(false);
        break;
      case "loc":
        setShowloc(!isShowLoc);
        setShowDep(false);
        break;
      default:
        setShowDep(false);
        setShowloc(false);
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.showCarrerList]);

  const choiseLabel = (type, label) => {
    const url = new URL(
      typeof window !== "undefined" ? window.location.href : ""
    );

    switch (type) {
      case "dep":
        setDepLabel(label);
        updateListCarrer(dispatch, false);

        url.searchParams.set("department", label.replace(" ", "_"));
        window.history.pushState({}, "", url);
        break;
      case "loc":
        setLocLabel(label);
        updateListCarrer(dispatch, false);

        url.searchParams.set("location", label.replace(" ", "_"));
        window.history.pushState({}, "", url);
        break;
      default:
        break;
    }
  };

  const positionHeadersList = getPositionHeadersList(positionsList);

  const [isShowDep, setShowDep] = useState(false);
  const [depLabel, setDepLabel] = useState(positionHeadersList.departaments[0]);

  const [isShowLoc, setShowloc] = useState(false);
  const [locLabel, setLocLabel] = useState(positionHeadersList.locations[0]);

  const sortedPositionsList = getSortedPositionsList(
    positionsList,
    depLabel,
    locLabel
  );

  const [choisedPosition, setChoisedPosition] = useState(false);

  //id for scroll
  const elemId = "positions";
  const blockRef = React.useRef(null);

  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));

    //?department=all&location=Spain
    const urlParams = getAllUrlParameters();

    if (urlParams.department && urlParams.department !== "") {
      setDepLabel(decodeURIComponent(urlParams.department.replace(/[_\+]/g, " ")));
    }

    if (urlParams.location && urlParams.location !== "") {
      setLocLabel(decodeURIComponent(urlParams.location.replace(/[_\+]/g, " ")));
    }
  }, [blockRef]);

  return (
    <>
      {positionsList && (
        <StyledOpenPositionsBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.opsTitle,
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: data.opsSubtitle,
                }}
              />
            </div>

            <div className="positions_wrapper">
              <div className="navigation_wrapper">
                <div className={`option_list ${isShowDep ? "opened" : ""}`}>
                  <button
                    className="visible"
                    onClick={() => openListHandler("dep")}
                  >
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: translateDepartaments(
                          depLabel,
                          translatePositions,
                          langCurrent
                        ),
                      }}
                    />
                    <div
                      className="svg"
                      dangerouslySetInnerHTML={{
                        __html: SVG_SHEVRON_BOTTOM_ICON,
                      }}
                    />
                  </button>
                  <div className={`hidden ${isShowDep ? "show" : ""}`}>
                    {positionHeadersList.departaments.map((depTitle, index) => (
                      <button
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: translator(langCurrent, {
                            es: translateDepartaments(
                              depTitle,
                              translatePositions,
                              langCurrent
                            ),
                            en: depTitle,
                          }),
                        }}
                        aria-label={depTitle}
                        style={{ fontSize: "16px" }}
                        onClick={() => choiseLabel("dep", depTitle)}
                      />
                    ))}
                  </div>
                </div>

                <div className={`option_list ${isShowLoc ? "opened" : ""}`}>
                  <button
                    className="visible"
                    onClick={() => openListHandler("loc")}
                  >
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                          es: translateLocationsCountry(
                            locLabel,
                            translatePositions,
                            langCurrent
                          ),
                          en: locLabel,
                        }),
                      }}
                    />
                    <div
                      className="svg"
                      dangerouslySetInnerHTML={{
                        __html: SVG_SHEVRON_BOTTOM_ICON,
                      }}
                    />
                  </button>
                  <div className={`hidden ${isShowLoc ? "show" : ""}`}>
                    {positionHeadersList.locations.map((locTitle, index) => (
                      <button
                        key={index}
                        dangerouslySetInnerHTML={{
                          __html: translator(langCurrent, {
                            es: translateLocationsCountry(
                              locTitle,
                              translatePositions,
                              langCurrent
                            ),
                            en: locTitle,
                          }),
                        }}
                        aria-label={locTitle}
                        style={{ fontSize: "16px" }}
                        onClick={() => choiseLabel("loc", locTitle)}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="positions_list">
                {sortedPositionsList.length > 0 ? (
                  <>
                    {sortedPositionsList.map((position, index) => (
                      <button
                        key={index}
                        className="position"
                        onClick={() => togglePopup(index)}
                        aria-label={position.title}
                      >
                        <div
                          className="title"
                          dangerouslySetInnerHTML={{
                            __html: position.title,
                          }}
                        />

                        <div className="option_list">
                          <div
                            className="department"
                            dangerouslySetInnerHTML={{
                              __html: translator(langCurrent, {
                                es: translateDepartaments(
                                  position.positions.department,
                                  translatePositions,
                                  langCurrent
                                ),
                                en: position.positions.department,
                              }),
                            }}
                          />

                          <div className="locations_list">
                            {position.positions.location.map(
                              (locTitle, subIndex) => (
                                <div
                                  key={`${index}-${subIndex}`}
                                  className="loc"
                                  dangerouslySetInnerHTML={{
                                    __html: getFullLocationHTML(
                                      locTitle,
                                      translatePositions,
                                      langCurrent
                                    ),
                                  }}
                                />
                              )
                            )}
                          </div>
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <div
                    className="empty"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "No <b>tenemos posiciones</b> con el criterio buscado actualmente",
                        en: "There are no <b>open positions</b> with this criteria right now",
                      }),
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {showPositionPopup && (
            <PositionPopup
              data={choisedPosition}
              translatePositions={translatePositions}
              langCurrent={langCurrent}
              toCopyLink={urlPosition}
              showPositionPopup={showPositionPopup}
              setShowPositionPopup={setShowPositionPopup}
            />
          )}
        </StyledOpenPositionsBlock>
      )}
    </>
  );
};

const StyledOpenPositionsBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 100px 0;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    padding: 60px 0;
  }
  .bg_layout {
    background-color: #fff;
    right: 1500px;
    &:after {
      height: 3000px;
      right: -340px;
      background-color: #f9fafc;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 446px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        right: -446px;
      }
    }
  }
  .main_wrapper {
    z-index: 6;
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 2px;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        font-size: 40px;
        line-height: 49px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        font-size: 32px;
        line-height: 36px;
      }
    }

    span {
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      text-align: center;
      letter-spacing: -1px;
      color: #c1c1c1;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        font-size: 40px;
        line-height: 49px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }

  .positions_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .navigation_wrapper {
      width: 25%;
      height: auto;
      border-top: 1px solid #e6e6e6;
      padding-top: 24px;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        margin-bottom: 60px;
      }
      .option_list {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
        &.opened {
          z-index: 2;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .visible {
          width: 100%;
          height: 100%;
          padding-right: 30px;
          position: relative;
          cursor: pointer;
          padding-bottom: 15px;
          font-family: "PT Sans", arial, sans-serif;
          .text {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: -0.3px;
            color: #000000;
            opacity: 0.6;
            @media (max-width: ${BREAKPOINTS.lg + "px"}) {
              font-size: 15px;
            }
          }
          .svg {
            position: absolute;
            top: 11px;
            right: 0;
            width: fit-content;
            height: fit-content;
            svg {
              width: 18px;
              height: 18px;
              fill: #ccc;
            }
          }
        }
        .hidden {
          width: 100%;
          height: auto;
          background-color: #fff;
          padding: 0;
          position: absolute;
          top: 50px;
          left: 0;
          box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
          display: none;
          &.show {
            display: block;
          }
          button {
            width: 100%;
            height: 45px;
            text-align: left;
            padding: 0 20px;
            &:hover {
              background-color: rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }

    .positions_list {
      width: 65%;
      margin-left: auto;
      border-top: 1px solid #e6e6e6;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        border-top: none;
      }
      .position {
        width: 100%;
        height: auto;
        border-bottom: 1px solid #e6e6e6;
        padding: 24px 0;
        cursor: pointer;
        font-family: "PT Sans", arial, sans-serif;
        .title {
          width: 100%;
          height: auto;
          font-weight: 700;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #000000;
          margin-bottom: 12px;
          cursor: pointer;
          text-align: left;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 20px;
          }
          &:hover {
            opacity: 0.8;
          }
        }
        .option_list {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: rgba(0, 0, 0, 0.6);
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 15px;
          }
          .department {
            &:after {
              content: "-";
              margin: 0 12px;
            }
          }
        }
      }

      .empty {
        width: 100%;
        height: auto;
        padding: 70px 0 20px;
        display: inline-block;
        font-size: 20px;
        text-align: center;
      }
    }
  }
`;

export const query = graphql`
  fragment OpenPositionsBlock on WpPage_Components_Components_OpenPositionsBlock {
    opsSubtitle
    opsTitle
  }
`;
