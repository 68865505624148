// libraries
import { useStaticQuery, graphql } from "gatsby";

export const useInvestAgreement = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        acfOptionsGatsby {
          gatsbyFields {
            vtdTitle {
              es
              en
            }
            vtdNotAgreeButtonLabel {
              es
              en
            }
            vtdContent {
              es
              en
            }
            vtdAgreeButtonLabel {
              es
              en
            }
          }
        }
      }
    }
  `);

  return data?.wp.acfOptionsGatsby?.gatsbyFields;
};
