// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const SustainableAndInclusiveSocietyBanner = ({ data }) => {
  return (
    <>
      {data && (
        <StyledSustainableAndInclusiveSocietyBanner className="banner_block">
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div
              className="svg"
              dangerouslySetInnerHTML={{
                __html: data.sisLogo,
              }}
            />
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.sisContent,
              }}
            />
          </div>
        </StyledSustainableAndInclusiveSocietyBanner>
      )}
    </>
  );
};

const StyledSustainableAndInclusiveSocietyBanner = styled.div`
  width: 100%;
  height: 209px;
  position: relative;
  overflow: hidden;

  .bg_layout{
    background-color: #47B673;
    @media(max-width: ${BREAKPOINTS.sm + "px"}){
      background-color: #4EB16C;
    }
    &:before{
      background-color: #3DB26C;
      right: -589px;
      @media(max-width: ${BREAKPOINTS.sm + "px"}){
        background-color: #38A667;
        right: -50px;
      }
    }
  }

  .content{
    color: #fff;
    a{
      color: #fff;
    }
  }
`;

export const query = graphql`
  fragment SustainableAndInclusiveSocietyBanner on WpPage_Components_Components_SustainableAndInclusiveSocietyBanner {
    sisContent
    sisLogo
  }
`;
