// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const SubPromoBlock = ({ data }) => {
  return (
    <>
      {data && (
        <StyledSubPromoBlock>
          <div
            className="bg_layout"
            style={{
              backgroundImage: `url(${data.supImage?.localFile?.publicURL})`,
            }}
          />

          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.supTitle,
                }}
              />

              <span
                dangerouslySetInnerHTML={{
                  __html: data.supSubtitle,
                }}
              />
            </div>
          </div>
        </StyledSubPromoBlock>
      )}
    </>
  );
};

const StyledSubPromoBlock = styled.div`
  position: relative;
  height: 504px;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    background-color: #fff;
    padding: 340px 0 80px;
    height: auto;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: skewX(-30deg);
    right: 300px;
  }

  .bg_layout {
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      height: 280px;
      top: 0;
      left: 0;
      right: auto;
      background-size: cover;
    }
    &:before {
      background-color: #e8edf2;
      right: 713px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 509px;
      }
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        background-color: rgba(248, 250, 251, 0.4);
        right: 403px;
      }
    }
    &:after {
      display: none;
    }
  }

  .main_wrapper {
    height: 100%;
  }

  .title_block {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      width: 60%;
    }
    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 55%;
    }

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 80%;
      text-align: center;
      margin: 0 auto;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
    }

    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      display: flex;
      letter-spacing: -1px;
      margin-bottom: 24px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.5px;
      }
    }
    span {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      width: 80%;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 100%;
      }
    }
  }
`;

export const query = graphql`
  fragment SubPromoBlock on WpPage_Components_Components_SubPromoBlock {
    supSubtitle
    supTitle
    supImage {
      localFile {
        publicURL
      }
    }
  }
`;
