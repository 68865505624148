// libraries
import React, {
  useState,
  useContext,
  createContext,
  useEffect,
  useMemo,
} from "react";
// actions
import { updateBondsCalcCurrencyVal } from "../store/actions/calc_bonds";
// hooks
import { useCalcBondsStore } from "../store";

const BondsContext = createContext(null);

export const BondsContextProvider = ({ children }) => {
  const { state, dispatch } = useCalcBondsStore();
  const [bondsCalcCurrencyVal, setBondsCalcCurrencyVal] = useState(state.currentVal);

  useEffect(() => {
    setBondsCalcCurrencyVal(state.currentVal);
  }, [state.currentVal]);

  const value = useMemo(() => {
    const handleChange = (newVal) => {
      if (window.ym) {
        window.ym(57564463, 'reachGoal', 'bond_calc_interact');
      }
      if (window.dataLayer) {
        window.dataLayer.push({ event: 'bond_calc_interact' });
      }
  
      setBondsCalcCurrencyVal(newVal);
      updateBondsCalcCurrencyVal(dispatch, newVal);
    };
  
    return {
      bondsCalcCurrencyVal,
      handleChange,
    };
  }, [bondsCalcCurrencyVal, dispatch]);

  return (
    <BondsContext.Provider value={value}>{children}</BondsContext.Provider>
  );
};

export const useBonds = () => useContext(BondsContext);
