// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { MegaSlider } from '../../../Slider';
// constants
import { BREAKPOINTS } from '../../../../settings';
// context
import { useBonds } from '../../../../context/bondsContext';
// helpers
import { prettify, prettifyLegend } from '../../../../helpers/base';
// hooks
import { useCalcBondsStore } from '../../../../store';

export const BondsCalculatorSlider = (props) => {
    const { label, min, max, step } = props;
    const { state: bondsState } = useCalcBondsStore();
    const { handleChange, bondsCalcCurrencyVal } = useBonds();

    const currencyName = bondsState.products[bondsState.currentProduct][bondsState.currentActiveBond].sign;

    return (
        <StyledBondsCalculatorSlider>
            <div className="content">
                <div className="input">
                    <span className="input_label">{label}</span>
                    <span
                        className="input_content"
                        dangerouslySetInnerHTML={{
                            __html: `${prettify(bondsCalcCurrencyVal)} ${currencyName}`,
                        }}
                    />
                </div>
                <MegaSlider min={min} max={max} step={step} value={bondsCalcCurrencyVal} handleChange={handleChange} />
            </div>
            <div className="legends">
                <span
                    dangerouslySetInnerHTML={{
                        __html: `${prettifyLegend(min, 'en')} ${currencyName}`,
                    }}
                />
                <span
                    dangerouslySetInnerHTML={{
                        __html: `${prettifyLegend(max, 'en')} ${currencyName}`,
                    }}
                />
            </div>
        </StyledBondsCalculatorSlider>
    );
};

const StyledBondsCalculatorSlider = styled.div`
    .content {
        .input {
            margin: 0 0 18px;
            &_label {
                display: inherit;
                font-size: 18px;
                font-weight: 700;
                @media (min-width: ${BREAKPOINTS.sm}px) {
                    font-size: 20px;
                }
            }
            &_content {
                display: inherit;
                margin: 4px 0 0;
                color: #005EB8;
                font-size: 40px;
                font-weight: 700;
            }
        }
    }
    .legends {
        margin: 16px 0 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        span {
            color: #898989;
            font-size: 14px;
        }
    }
`;
