// libraries
import React from 'react';
import styled from 'styled-components';
// actions
import { initBondsCalc } from '../../store/actions/calc_bonds';
// components
import { BondsCalculatorSliders } from './BondsCalculatorSliders';
import { BondsCalculatorInfo } from './BondsCalculatorInfo';
import { OpenForm } from '../OpenForm';
// constants
import { BREAKPOINTS } from '../../settings';
// context
import { BondsContextProvider } from '../../context/bondsContext';
// hooks
import { useCalcBondsStore } from '../../store';

const bondsCalsIsInit = (bondsCalcState) => {
  return !(
    bondsCalcState.currentTerm === null ||
    bondsCalcState.percents === null ||
    bondsCalcState.currentPercent === null
  );
};

export const BoundsCalculatorBlock = ({ title, subtitle }) => {
  const { state, dispatch } = useCalcBondsStore();
  
  if (!bondsCalsIsInit(state)) {
    initBondsCalc(dispatch);
  }
  
  return bondsCalsIsInit(state) ? (
    <BondsContextProvider>
      <StyledBondsCalculatorBlock id="calc">
      <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />
      <StyledSliders>
        <BondsCalculatorSliders />
      </StyledSliders>
      <StyledInfo>
        <BondsCalculatorInfo />
      </StyledInfo>
      <StyledRequest>
        <StyledSubmit type="bonds" />
        <p className="info">
          Advance payment.
          <br />
          It is not a public offer.
        </p>
      </StyledRequest>
      <StyledSubtext dangerouslySetInnerHTML={{ __html: subtitle }}/>
    </StyledBondsCalculatorBlock>
    </BondsContextProvider>
  ) : null;
};

const StyledBondsCalculatorBlock = styled.div`
    width: 100%;
    background: #fff;
    border-radius: 16px;
    color: hsl(201, 36%, 9%);
    display: grid;
    grid-gap: 24px;
    grid-template-columns: 100%; // HOTFIX: на телефонах грид вываливается за границы обертки, пока что не понял почему, нужно разобраться
    line-height: 1.5;
    padding: 24px 16px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.18);

    @media (min-width: ${BREAKPOINTS.sm}px) {
        border-radius: 7px;
        grid-template-columns: repeat(2, 1fr);
        padding: 32px;
        gap: 32px;
    }
  
    @media (min-width: ${BREAKPOINTS.xl}px) {
        width: 620px;
    }
`;

// TODO: вынести в общий для двух калькуляторов компонент
const StyledTitle = styled.h3`
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;

    @media (min-width: ${BREAKPOINTS.sm}px) {
        border-bottom: 0;
        grid-column: 1/3;
        margin-bottom: 0;
        font-size: 24px;
    }
`;

const StyledSliders = styled.div`
    @media (min-width: ${BREAKPOINTS.sm}px) {
        max-width: 270px;
    }
`;

const StyledInfo = styled.div`
    @media (min-width: ${BREAKPOINTS.sm}px) {
        justify-self: end;
        max-width: 270px;
    }

    @media (min-width: ${BREAKPOINTS.md}px) {
        max-width: 262px;
    }
`;

const StyledRequest = styled.div`
    align-items: flex-start;
    border-top: 1px solid #E8EDF2;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
  
    @media (min-width: ${BREAKPOINTS.sm}px) {
        align-items: center;
    }

    @media (min-width: ${BREAKPOINTS.sm}px) {
        grid-column: 1/3;
    }

    .info {
        color: hsl(0, 0%, 72%);
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 0;
        
        @media (min-width: ${BREAKPOINTS.sm}px) {
            margin-top: 0;
            margin-left: 30px;
            padding-right: 10px;
        }

        @media (min-width: ${BREAKPOINTS.md}px) {
            margin-left: auto;
            margin-right: auto;
            font-size: 14px;
        }

        @media (min-width: ${BREAKPOINTS.lg}px) {
            margin-right: 15px;
        }
    }
`;

const StyledSubmit = styled(OpenForm)`
    flex-shrink: 0;
`;

const StyledSubtext = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.3px;
    color:#898989;

    @media (min-width: ${BREAKPOINTS.sm}px) {
      grid-column: 1 / 3;
    }
`
