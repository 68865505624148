// libraries
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// components
import { InvestAgreement } from "../InvestAgreement";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { usePopups } from "../../context/popupsContext";
// helpers
import { getAllUrlParameters } from "../../helpers/base";
// static
import { SVG_DOWNLOAD_ICON } from "../../icons/icons";

export const InvestorsInnerBlock = ({ data, id: pageID }) => {
  const { showAgreementPopup, setShowAgreementPopup } = usePopups();
  const { iibTitle: title, iibSubtitle: subtitle } = data;
  const financialStatementsPage = pageID === 7518 || pageID === 7522;

  const [isMobile, setMobile] = useState(false);

  const investorPopupHandler = (isShow, url) => {
    let data = [];
    data.showPopup = isShow;
    data.url = url;

    setShowAgreementPopup(data);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      setMobile(window.matchMedia("(max-width: 992px)").matches);
      let param = getAllUrlParameters(window.location.href);
      if (!financialStatementsPage) {
        investorPopupHandler(!param.ref, null);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledInvestorsInnerBlock>
      <div className="bg_layout" />

      {data && (
        <div className="main_wrapper_lg">
          <div className="info">
            {title ? (
              <h1
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            ) : null}

            {subtitle ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: subtitle,
                }}
              />
            ) : null}
          </div>
          <div className="cards_list">
            {data.iibCardsList.map((card, index) => (
              <div key={index} className="card">
                <div className="card_title_block">
                  <div
                    className="card_pretitle"
                    dangerouslySetInnerHTML={{
                      __html: card.iibClPretitle,
                    }}
                  />
                  <h2
                    className="card_title"
                    dangerouslySetInnerHTML={{
                      __html: card.iibClTitle,
                    }}
                  />

                  {!isMobile && card.iibClFile?.localFile?.publicURL && (
                    <a
                      className="card_file_button"
                      href={card.iibClFile?.localFile?.publicURL}
                      aria-label="Download file"
                      download={true}
                    >
                      <span
                        className="label"
                        dangerouslySetInnerHTML={{
                          __html: card.iibClButtonLable,
                        }}
                      />
                      <span
                        className="svg"
                        dangerouslySetInnerHTML={{
                          __html: SVG_DOWNLOAD_ICON,
                        }}
                      />
                    </a>
                  )}
                </div>

                <div className="card_property_list">
                  {card.iibClPropertyList?.map((prop, indexProp) => (
                    <div key={index + "-" + indexProp} className="prop">
                      <div
                        className="title"
                        dangerouslySetInnerHTML={{
                          __html: prop.iibClPlTitle,
                        }}
                      />
                      <div
                        className="content"
                        dangerouslySetInnerHTML={{
                          __html: prop.iibClPlContent,
                        }}
                      />
                    </div>
                  ))}
                </div>

                {isMobile && card.iibClFile?.localFile?.publicURL && (
                  <a
                    className="card_file_button"
                    href={card.iibClFile?.localFile?.publicURL}
                    aria-label="Download file"
                    download={true}
                  >
                    <span
                      className="label"
                      dangerouslySetInnerHTML={{
                        __html: card.iibClButtonLable,
                      }}
                    />
                    <span
                      className="svg"
                      dangerouslySetInnerHTML={{
                        __html: SVG_DOWNLOAD_ICON,
                      }}
                    />
                  </a>
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      {showAgreementPopup.showPopup ? <InvestAgreement isInner={true} /> : null}
    </StyledInvestorsInnerBlock>
  );
};

const StyledInvestorsInnerBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 3;
  padding: 85px 0 80px;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    padding: 12px 0 52px;
  }
  .bg_layout {
    background-color: #f1f4f7;
    &:after {
      transform: skewX(0);
      background-color: #fff;
      top: 55%;
    }
  }

  .info {
    margin-top: 86px;
    margin-bottom: 68px;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      margin-top: 90px;
      margin-bottom: 48px;
    }
  }

  h1 {
    font-weight: 700;
    font-size: 76px;
    line-height: 1;
    letter-spacing: -1.5px;
    color: #000000;
    width: 60%;
    margin-bottom: 24px;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      width: 70%;
    }
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 83%;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      font-size: 42px;
      line-height: 1.1;
      letter-spacing: -1px;
      width: 70%;
    }
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin-bottom: 8px;
    }

    span {
      color: rgb(0, 94, 184);
    }
  }

  p {
    font-size: 24px;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      max-width: 312px;
      font-size: 20px;
    }
  }

  .cards_list {
    width: 100%;
    height: auto;
  }

  .card {
    width: 100%;
    height: auto;
    background-color: #005eb8;
    padding: 48px 40px 80px;
    color: #fff;
    margin-bottom: 80px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      padding: 40px;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      margin-bottom: 24px;
      padding: 40px 20px 60px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .card_title_block {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: 40px;
      .card_pretitle {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.5px;
        color: #ffffff;
        margin-bottom: 24px;
      }
      .card_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 36px;
        letter-spacing: -0.5px;
        color: #ffffff;
        width: 50%;
        @media (max-width: ${BREAKPOINTS.xl + "px"}) {
          width: 70%;
        }
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          width: 80%;
        }
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          width: 100%;
          font-size: 32px;
        }
      }
      .card_file_button {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .card_file_button {
      width: fit-content;
      height: 40px;
      background-color: #fff;
      border-radius: 100px;
      padding-right: 40px;
      position: relative;
      display: block;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        margin-top: 40px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        margin: 40px auto 0;
      }
      .label {
        width: fit-content;
        height: 100%;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: -0.385714px;
        color: #005eb8;
        border-right: 1px solid #005eb8;
      }
      .svg {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
          fill: #005eb8;
        }
      }
    }

    .card_property_list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .prop {
        width: 18%;
        height: auto;
        margin-right: 2.5%;
        border-top: 1px solid rgba(230, 230, 230, 0.2);
        padding-top: 27px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          width: 30%;
          margin-right: 5%;
          margin-bottom: 40px;
        }
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          width: 100%;
          margin-right: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(5n) {
          margin-right: 0;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            margin-right: 5%;
          }
          @media (max-width: ${BREAKPOINTS.sm + "px"}) {
            margin-right: 0;
          }
        }
        &:nth-child(3n) {
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            margin-right: 0;
          }
        }
        .title {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #ffffff;
          opacity: 0.6;
          margin-bottom: 16px;
        }
        .content {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.3px;
          color: #ffffff;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment InvestorsInnerBlock on WpPage_Components_Components_InvestorsInnerBlock {
    iibTitle
    iibSubtitle
    iibCardsList {
      iibClPretitle
      iibClTitle
      iibClButtonLable
      iibClFile {
        localFile {
          publicURL
        }
      }
      iibClPropertyList {
        iibClPlContent
        iibClPlTitle
      }
    }
  }
`;
