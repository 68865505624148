// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const OurPrinciplesBlock = ({ data }) => {
  const elemId = "principles";
  const blockRef = React.useRef(null);

  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledOurPrinciplesBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.opbTitle,
                }}
              />
            </div>

            <div className="principles_list">
              {data.opbPrinciplesList.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="icon"
                    dangerouslySetInnerHTML={{
                      __html: item.opbPlIcon,
                    }}
                  />

                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.opbPlTitle,
                    }}
                  />

                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: item.opbPlSubtitle,
                    }}
                  />

                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: item.opbPlContent,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </StyledOurPrinciplesBlock>
      )}
    </>
  );
};

const StyledOurPrinciplesBlock = styled.div`
  width: 100%;
  padding: 80px 0 90px;
  position: relative;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    padding: 60px 0 100px;
    height: auto;
  }

  .bg_layout {
    background-color: #edf1f5;
    &:before {
      background-color: #f1f4f7;
      right: 923px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 722px;
      }
    }
    &:after {
      display: none;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        display: block;
        right: -750px;
        background-color: #e8edf2;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -535px;
      }
    }
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 15px;
    }

    span {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      br {
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          display: none;
        }
      }
    }
  }

  .principles_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      width: 28%;
      height: auto;
      margin-right: 8%;
      text-align: center;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        width: 31%;
        margin-right: 3.5%;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 55%;
        margin: 0 auto 80px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
      }
      &:nth-child(3n) {
        margin-right: 0;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          margin-right: auto;
        }
      }
      &:last-child {
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          margin-bottom: 0;
        }
      }
      .icon {
        width: fit-content;
        height: fit-content;
        margin: 0 auto 30px;
        svg {
          width: 50px;
          height: 50px;
        }
      }
      .title {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.5px;
        color: #005eb8;
        margin-bottom: 17px;
      }
      .subtitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.3px;
        margin-bottom: 15px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }
      .content {
        font-size: 18px;
        line-height: 24px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment OurPrinciplesBlock on WpPage_Components_Components_OurPrinciplesBlock {
    opbTitle
    opbPrinciplesList {
      opbPlContent
      opbPlIcon
      opbPlSubtitle
      opbPlTitle
    }
  }
`;
