// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";
// static
import { SVG_ROUND_NAV_RIGHT_TITLE } from "../../icons/icons";

export const WantToJoinUsBlock = ({ data }) => {
  const elemId = "explore_careers";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledWantToJoinUsBlock
          svgRight={SVG_ROUND_NAV_RIGHT_TITLE}
          id={elemId}
          ref={blockRef}
        >
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div
              className="image_wrapper"
              style={{
                backgroundImage: `url(${data.wtjImage?.localFile?.publicURL})`,
              }}
            />

            <div className="content_wrapper">
              <div className="title_block">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: data.wtjTitle,
                  }}
                />
                <a
                  href={data.wtjLink}
                  aria-label={data.wtjLinkLabel}
                  dangerouslySetInnerHTML={{
                    __html: data.wtjLinkLabel,
                  }}
                />
              </div>

              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: data.wtjSubtitle,
                }}
              />
            </div>
          </div>
        </StyledWantToJoinUsBlock>
      )}
    </>
  );
};

const StyledWantToJoinUsBlock = styled.div`
  width: 100%;
  height: 530px;
  position: relative;
  padding-top: 100px;

  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    height: auto;
    padding-top: 60px;
  }

  .bg_layout {
    background-color: #273e56;
    &:after {
      right: -628px;
      background-color: #19324b;
    }
  }

  .main_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      flex-direction: column-reverse;
      padding: 0;
    }

    .image_wrapper {
      width: 48%;
      height: 320px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 8px;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        position: relative;
        border-radius: 0;
        height: 260px;
      }
    }

    .content_wrapper {
      width: 48%;
      height: auto;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        padding: 0 20px 50px;
      }
      .title_block {
        width: 100%;
        height: auto;
        margin-bottom: 36px;
        h2 {
          color: rgba(255, 255, 255, 0.6);
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 32px;
            line-height: 32px;
          }
        }
        a {
          color: #fff;
          display: block;
          font-weight: 700;
          font-size: 48px;
          line-height: 48px;
          letter-spacing: -0.5px;
          @media (max-width: ${BREAKPOINTS.xl + "px"}) {
            font-size: 40px;
            line-height: 49px;
            margin-top: 10px;
            width: 350px;
          }
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 32px;
            line-height: 32px;
            width: 300px;
          }
          @media (max-width: ${BREAKPOINTS.md + "px"}) {
            width: 100%;
          }
          @media (max-width: ${BREAKPOINTS.sm + "px"}) {
            max-width: 320px;
            display: block;
            margin-top: 12px;
          }
          &:after {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg width='37' height='36' viewBox='0 0 37 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' width='36' height='36' rx='18' fill='white'%3E%3C/rect%3E%3Cpath d='M16.5125 25L23.5 18L16.5125 25Z' fill='%23005EB8'%3E%3C/path%3E%3Cpath d='M16.5125 25L23.5 18' stroke='%23005EB8' stroke-linecap='round'%3E%3C/path%3E%3Cpath d='M23.5 18L16.5124 11L23.5 18Z' fill='%23005EB8'%3E%3C/path%3E%3Cpath d='M23.5 18L16.5124 11' stroke='%23005EB8' stroke-linecap='round'%3E%3C/path%3E%3C/svg%3E");
            width: 37px;
            height: 36px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: inline-block;
            position: relative;
            bottom: -4px;
            margin-left: 15px;
            @media (max-width: ${BREAKPOINTS.lg + "px"}) {
              width: 32px;
              height: 31px;
              bottom: -5px;
              margin-left: 12px;
            }
          }
        }
      }
      .content {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;
        color: #ffffff;
        width: 80%;
        margin-right: auto;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          width: 100%;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment WantToJoinUsBlock on WpPage_Components_Components_WantToJoinUsBlock {
    wtjSubtitle
    wtjTitle
    wtjLink
    wtjLinkLabel
    wtjImage {
      localFile {
        publicURL
      }
    }
  }
`;
