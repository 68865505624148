// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const CreditInteligeBlock = ({ data }) => {
  const elemId = data.cibBlockId;
  const blockRef = React.useRef(null);

  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledCreditInteligeBlock id={elemId} ref={blockRef}>
          <div className={`bg_layout ${!data.cibType ? "right" : ""}`} />
          <div className={`main_wrapper ${!data.cibType ? "right" : ""}`}>
            <div className="content_wrapper">
              <h3
                className="title"
                dangerouslySetInnerHTML={{
                  __html: data.cibTitle,
                }}
              />
              <div
                className="content"
                dangerouslySetInnerHTML={{
                  __html: data.cibContent,
                }}
              />
            </div>

            <div
              className="image_wrapper"
              dangerouslySetInnerHTML={{
                __html: data.cibSvgImage,
              }}
            />
          </div>
        </StyledCreditInteligeBlock>
      )}
    </>
  );
};

const StyledCreditInteligeBlock = styled.div`
  width: 100%;
  height: 550px;
  position: relative;
  padding: 0;
  display: flex;
  align-items: center;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    height: 700px;
  }

  .bg_layout {
    background-color: #273e56;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      background-color: #071b32;
    }
    &:before {
      background-color: #19314b;
      right: -916px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -418px;
      }
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: 185px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        right: 406px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 230px;
        background-color: #0a243f;
      }
    }
    &.right {
      background-color: #19324b;
      &:after {
        background-color: #0a243f;
        right: -1233px;
        @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
          right: -735px;
        }
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          right: -133px;
        }
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          right: 2px;
        }
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          right: 231px;
        }
      }

      &:before {
        display: none;
      }
    }
  }

  .main_wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    color: #fff;
    .content_wrapper {
      margin-right: auto;
    }
    &.right {
      flex-direction: row-reverse;
      .content_wrapper {
        margin-left: auto;
        margin-right: 0;
      }
    }
  }

  .content_wrapper {
    width: 45%;
    height: auto;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 60%;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 100%;
      margin-bottom: 40px;
    }
    .title {
      font-weight: 700;
      font-size: 33px;
      line-height: 32px;
      margin-bottom: 24px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 48px;
        width: 100%;
        text-align: center;
      }
    }
    .content {
      font-size: 17px;
      line-height: 24px;
      p {
        margin-bottom: 15px;
      }
      ul {
        width: 100%;
        height: auto;
        li {
          position: relative;
          margin-bottom: 24px;
          padding-left: 20px;
          &:last-child {
            margin-bottom: 0;
          }
          &:before {
            content: "";
            width: 4px;
            height: 4px;
            background-color: #078be4;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: 0px;
          }
        }
      }
    }
  }

  .image_wrapper {
    width: 35%;
    height: auto;
    display: flex;
    justify-content: center;

    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      width: 80%;
      margin: 0 auto;
    }

    svg {
      max-width: 380px;
      max-height: 330px;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        max-height: 220px;
      }
    }
  }
`;

export const query = graphql`
  fragment CreditInteligeBlock on WpPage_Components_Components_CreditInteligeBlock {
    cibContent
    cibSvgImage
    cibType
    cibTitle
    cibBlockId
  }
`;
