// libraries
import React from 'react';
// components
import { BondsCalculatorSlider } from './BondsCalculatorSlider';
// hooks
import { useCalcBondsStore } from '../../../store';

export const BondsCalculatorSliders = () => {
    const { state: bondsState } = useCalcBondsStore();

    return (
        <BondsCalculatorSlider
            name="amount"
            label="Amount of investment"
            value={bondsState.currentVal}
            min={bondsState.min}
            max={bondsState.max}
            step={bondsState.step}
        />
    );
};
