// libraries
import { useStaticQuery, graphql } from "gatsby";

export const ComparedGraphList = () => {
  const data = useStaticQuery(graphql`
    {
      wp {
        acfOptionsGatsby {
          gatsbyFields {
            cghGraphsList {
              cghGlTitle {
                en
                es
              }
              cghGlYValue
              cghGlFstColor
              cghGlFstTitle {
                en
                es
              }
              cghGlScdColor
              cghGlScdTitle {
                en
                es
              }
              cghGlYearsList {
                cghGlYlYear
                cghGlYlValue {
                  firstGraphValue
                  secondGraphValue
                }
              }
            }
          }
        }
      }
    }
  `);

  return data?.wp.acfOptionsGatsby?.gatsbyFields?.cghGraphsList;
};
