export const initBondsCalc = dispatch => {
  dispatch({
    type: "INIT_BONDS_CALC",
  });
};

export const updateBondsCalcProduct = (dispatch, product) => {
  dispatch({
    type: "UPDATE_BONDS_CALC_PRODUCT",
    data: product,
  });
};

export const updateBondsCalcTerm = (dispatch, termID) => {
  dispatch({
    type: "UPDATE_BONDS_CALC_TERM",
    data: termID,
  });
};

export const updateBondsCalcCurrencyVal = (dispatch, currencyVal) => {
  dispatch({
    type: "UPDATE_BONDS_CALC_CURRENCY_VAL",
    data: currencyVal,
  });
};

export const updateBondsCalcActiveBonds = (dispatch, key) => {
  dispatch({
    type: "UPDATE_BONDS_CALC_ACTIVE_BONDS",
    data: key,
  });
};

export const updateBondsCalcCurrentActiveBond = (dispatch, key) => {
  dispatch({
    type: "UPDATE_BONDS_CALC_CURRENT_ACTIVE_BOND",
    data: key,
  });
};
