// libraries
import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../../settings";
// context
import { useLang } from "../../../context/languageContext";
// helpers
import { translator } from "../../../helpers/lang";

export const CompareGraph = ({ graph }) => {
  const { lang:langCurrent } = useLang();

  const getGraphData = graphValuesList => {
    let returnArray = [];

    for (let val of graphValuesList) {
      let data = {};
      data.year = val.cghGlYlYear;
      data.first = val.cghGlYlValue.firstGraphValue
        ? parseInt(val.cghGlYlValue.firstGraphValue)
        : 0;
      data.second = val.cghGlYlValue.secondGraphValue
        ? parseInt(val.cghGlYlValue.secondGraphValue)
        : 0;
      returnArray.push(data);
    }

    return returnArray;
  };

  const graphData = getGraphData(graph.cghGlYearsList);

  return (
    <>
      {graphData && (
        <StyledCompareGraph>
          <div className="header_block">
            <div
              className="title"
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  es: graph.cghGlTitle.es,
                  en: graph.cghGlTitle.en,
                }),
              }}
            />
            <div
              className="value"
              dangerouslySetInnerHTML={{
                __html: graph.cghGlYValue,
              }}
            />
          </div>

          <ResponsiveContainer height={200}>
            <AreaChart
              data={graphData}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="colorFirst" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={graph.cghGlFstColor}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={graph.cghGlFstColor}
                    stopOpacity={0}
                  />
                </linearGradient>
                <linearGradient id="colorSecond" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor={graph.cghGlScdColor}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={graph.cghGlScdColor}
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#E6E6E6" vertical={false} />
              <XAxis dataKey="year" padding={{ left: 20 }} />
              <YAxis axisLine={false} tickLine={false} tickCount={7} />
              <Area
                type="monotone"
                dataKey="first"
                stackId="1"
                stroke={graph.cghGlFstColor}
                fillOpacity={1}
                fill="url(#colorFirst)"
              />
              <Area
                type="monotone"
                dataKey="second"
                stackId="2"
                stroke={graph.cghGlScdColor}
                fillOpacity={1}
                fill="url(#colorSecond)"
              />
            </AreaChart>
          </ResponsiveContainer>

          <div className="footer_block">
            <div className="item">
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: translator(langCurrent, {
                    es: graph.cghGlFstTitle.es,
                    en: graph.cghGlFstTitle.en,
                  }),
                }}
              />
              <div
                className="stroke"
                style={{ backgroundColor: graph.cghGlFstColor }}
              />
            </div>

            <div className="item">
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: translator(langCurrent, {
                    es: graph.cghGlScdTitle.es,
                    en: graph.cghGlScdTitle.en,
                  }),
                }}
              />
              <div
                className="stroke"
                style={{ backgroundColor: graph.cghGlScdColor }}
              />
            </div>
          </div>
        </StyledCompareGraph>
      )}
    </>
  );
};

const StyledCompareGraph = styled.div`
  width: 48%;
  height: auto;
  position: relative;
  padding: 30px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 16px;
  margin-right: 4%;
  margin-bottom: 30px;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    width: 100%;
    margin-right: 0;
  }
  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    padding: 20px;
  }

  &:nth-child(2n) {
    margin-right: 0;
  }
  .header_block {
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
    }
    .value {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
    }
  }

  .recharts-responsive-container {
    margin-bottom: 30px;
    width: calc(100% + 20px) !important;
    position: relative;
    left: -20px;
    text {
      fill: #000;
    }
    .recharts-cartesian-axis-tick-line,
    .recharts-cartesian-axis-line {
      stroke: #000;
    }
  }

  .footer_block {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    padding-left: 40px;
    flex-wrap: wrap;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding-left: 0;
    }
    .item {
      width: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 40px;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      .title {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: -0.3px;
      }
      .stroke {
        width: 20px;
        height: 2px;
        margin-left: 12px;
      }
    }
  }
`;
