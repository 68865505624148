// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const SubPromoCareerBlock = ({ data }) => {
  return (
    <>
      {data && (
        <StyledSubPromoCareerBlock bg_img={data.spcImage?.localFile?.publicURL}>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.spcTitle,
                }}
              />

              <a
                href={data.spcTitleLink}
                dangerouslySetInnerHTML={{
                  __html: data.spcTitleLinkLabel,
                }}
                aria-label={data.spcTitleLinkLabel}
              />
            </div>
          </div>
        </StyledSubPromoCareerBlock>
      )}
    </>
  );
};

const StyledSubPromoCareerBlock = styled.div`
  position: relative;
  height: 370px;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    background-color: #fff;
    padding: 340px 0 80px;
    height: auto;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transform: skewX(-30deg);
    right: 300px;
  }

  .bg_layout {
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      height: 280px;
      top: 0;
      left: 0;
      right: auto;
      background-size: cover;
    }
    &:before {
      background-color: #e8edf2;
      right: 674px;
      z-index: 2;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 471px;
      }
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        background-color: rgba(248, 250, 251, 0.4);
        right: 403px;
      }
    }
    &:after {
      background-image: url(${props => (props.bg_img ? props.bg_img : "")});
      background-repeat: no-repeat;
      background-position: 85px;
      background-size: cover;
      width: 55%;
      top: 0;
      right: 0;
      transform: skewX(0);
      z-index: 1;
      @media (max-width: 1600px) {
        background-position: center;
      }
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        /* background-position: 85px; */
      }
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        width: 60%;
      }

      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 100%;
      }
    }
  }

  .main_wrapper {
    height: 100%;
  }

  .title_block {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      width: 60%;
    }
    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 55%;
    }

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 80%;
      text-align: center;
      margin: 0 auto;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
    }

    h2 {
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      display: flex;
      letter-spacing: -1px;
      margin-bottom: 8px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.5px;
      }
    }
    a {
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      letter-spacing: -0.5px;
      color: #005eb8;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.5px;
      }
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='36' height='36' rx='18' fill='%23005EB8'/%3E%3Cpath d='M16.0117 25L22.9993 18L16.0117 25Z' fill='white'/%3E%3Cpath d='M16.0117 25L22.9993 18' stroke='white' stroke-width='1.875' stroke-linecap='round'/%3E%3Cpath d='M23 18L16.0124 11L23 18Z' fill='white'/%3E%3Cpath d='M23 18L16.0124 11' stroke='white' stroke-width='1.875' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        display: inline;
        width: 36px;
        height: 36px;
        margin-left: 20px;
        position: relative;
        bottom: -3px;
        @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
          bottom: -7px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment SubPromoCareerBlock on WpPage_Components_Components_SubPromoCareerBlock {
    spcTitle
    spcTitleLinkLabel
    spcTitleLink
    spcImage {
      localFile {
        publicURL
      }
    }
  }
`;
