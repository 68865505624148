// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// components
import { InvestAgreement } from "../InvestAgreement";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { usePopups } from "../../context/popupsContext";
import { useLang } from "../../context/languageContext";
// hooks
import { useInvestorsFilesPreview } from "../../hooks/use-investors-files-preview";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const InformationForBondInvestorsBlock = () => {
  const { lang:langCurrent } = useLang();
  const { showAgreementPopup, setShowAgreementPopup } = usePopups();

  const previewList = useInvestorsFilesPreview(langCurrent);

  const investorPopupHandler = (isShow, url) => {
    let data = [];
    data.showPopup = isShow;
    data.url = url;

    setShowAgreementPopup(data);
  };

  const elemId = "bond_investors";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {previewList && (
        <StyledInformationForBondInvestorsBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />
          <div className="main_wrapper">
            {previewList.map((item, index) => (
              <div key={index} className="preview_card">
                <div
                  className="title"
                  dangerouslySetInnerHTML={{
                    __html:
                      item.components?.components[0]?.iibPreviewInfoBlock
                        ?.iibPiTitle,
                  }}
                />

                <div
                  className="content"
                  dangerouslySetInnerHTML={{
                    __html:
                      item.components?.components[0]?.iibPreviewInfoBlock
                        ?.iibPiDesc,
                  }}
                />

                <button
                  dangerouslySetInnerHTML={{
                    __html:
                      item.components?.components[0]?.iibPreviewInfoBlock
                        ?.iibPiLinkTitle,
                  }}
                  onClick={() => investorPopupHandler(true, item.uri)}
                  aria-label={
                    item.components?.components[0]?.iibPreviewInfoBlock
                      ?.iibPiLinkTitle
                  }
                />
              </div>
            ))}
          </div>
        </StyledInformationForBondInvestorsBlock>
      )}
      {showAgreementPopup ? <InvestAgreement isInner={false} /> : null}
    </>
  );
};

const StyledInformationForBondInvestorsBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 60px 0 80px;

  .bg_layout {
    background-color: #16304a;
    &:before {
      background-color: #0a2540;
      right: 1191px;
    }
    &:after {
      background-color: #0a2540;
      right: -1900px;
    }
  }

  .preview_card {
    width: 626px;
    height: auto;
    margin: 0 auto 40px;
    background-color: #005eb8;
    padding: 80px 40px 70px;
    color: #fff;
    position: relative;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 100%;
    }
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding: 85px 20px 70px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    &:after {
      content: "";
      display: block;
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0;
      right: 0;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        45deg,
        rgba(0, 77, 151, 1) 0%,
        rgba(0, 77, 151, 1) 50%,
        #16304a 50%
      );
    }

    .title {
      font-weight: 700;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: -0.5px;
      margin-bottom: 32px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.3px;
        margin-bottom: 8px;
      }
    }

    .content {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      margin-bottom: 48px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.3px;
        width: 90%;
      }
    }

    button {
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      color: #fff;
      cursor: pointer;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.3px;
      }
      &:hover {
        opacity: 0.8;
      }
      &:after {
        content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.11817 8.11209L7.22375 5.00098L4.11817 8.11209Z' fill='white'/%3E%3Cpath d='M4.11817 8.11209L7.22375 5.00098' stroke='%23005EB8' stroke-width='1.875' stroke-linecap='round'/%3E%3Cpath d='M7.22363 4.99978L4.11805 1.88867L7.22363 4.99978Z' fill='white'/%3E%3Cpath d='M7.22363 4.99978L4.11805 1.88867' stroke='%23005EB8' stroke-width='1.875' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #fff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
      }
    }
  }
`;

export const query = graphql`
  fragment InformationForBondInvestorsBlock on WpPage_Components_Components_InformationForBondInvestorsBlock {
    ifbTitle
  }
`;
