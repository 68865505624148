// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const SimplePolicyBlock = ({ data, title }) => {
  return (
    <>
      {data && (
        <StyledSimplePolicyBlock>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: title,
                }}
              />
            </div>

            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.spbContent,
              }}
            />
          </div>
        </StyledSimplePolicyBlock>
      )}
    </>
  );
};

const StyledSimplePolicyBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 4;
  padding-top: 150px;
  padding-bottom: 50px;
  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    padding-top: 120px;
  }

  .bg_layout {
    background-color: #f1f4f7;
    &:after {
      background-color: #fff;
      right: -900px;
    }
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 40px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 30px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 15px;
    }
  }

  .content {
    width: 100%;
    height: auto;
    font-size: 18px;
    line-height: 27px;
    p {
      margin-bottom: 15px;
    }

    h1,
    h2,
    h3 {
      width: 100%;
      height: auto;
      text-align: center;
      font-size: 28px;
      line-height: 37px;
      margin: 50px 0 30px;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        font-size: 25px;
        line-height: 34px;
        margin: 35px 0 20px;
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 40px auto;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        margin: 24px auto;
      }
    }

    .gatsby-image-wrapper {
      display: table !important;
      height: auto;
      margin: 40px auto;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        margin: 24px auto;
      }
    }

    a.styled {
      color: #005eb8;
      text-decoration: underline;
    }

    ul {
      & > li {
        padding-left: 15px;
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: 0;
          top: 8px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          display: block;
          background-color: #4c4c4c;
        }
      }

      &.hyphen-point {
        > li::before {
          top: 12px;
          width: 4px;
          height: 1px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment SimplePolicyBlock on WpPage_Components_Components_SimplePolicyBlock {
    spbContent
  }
`;
