// libraries
import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
// helpers
import { newsDate, categoryColor } from "../../../helpers/base";
import { translator } from "../../../helpers/lang";
// constants
import { BREAKPOINTS } from "../../../settings";

export const NewsPewviewSlider = ({ data }) => {
  const newsList = data.newsList;
  const lang = data.langCurrent;

  const sliderParams = {
    slidesPerView: 1.2,
    spaceBetween: 16,
    loop: true,
    breakpoints: {
      576: {
        slidesPerView: 1.5,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2.5,
      },
      1200: {
        slidesPerView: 2.5,
        spaceBetween: 23,
      },
      1400: {
        slidesPerView: 3,
      },
    },
  };

  return (
    <>
      {newsList && (
        <StyledNewsPewviewSlider>
          <Swiper {...sliderParams}>
            {newsList.slice(0, 10).map((item, index) => (
              <SwiperSlide key={index}>
                <div className="item_content">
                  <ul className="category_list">
                    {item.categories?.nodes?.map((category, catIndex) => (
                      <li
                        key={item + "-" + catIndex}
                        style={{
                          color: categoryColor(category.databaseId),
                        }}
                        dangerouslySetInnerHTML={{
                          __html: category.name,
                        }}
                      />
                    ))}
                  </ul>
                  <a
                    aria-label={item.title}
                    href={translator(lang, {
                      es: `/es/noticias/${item.slug}`,
                      en: `/news/${item.slug}`,
                    })}
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.title,
                    }}
                  />
                  <div
                    className="date"
                    dangerouslySetInnerHTML={{
                      __html: newsDate(item.date, lang),
                    }}
                  />
                </div>

                {/* TODO: проверить макет, возможно есть смысл обернуть всю карточку в ссылку */}
                {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                <a
                  href={translator(lang, {
                    es: `/es/noticias/${item.slug}`,
                    en: `/news/${item.slug}`,
                  })}
                  title={item.title}
                  className="item_image"
                  >
                    <img
                        src={item.news?.picturemob?.localFile?.publicURL}
                        alt={item.news?.picturemob?.altText ? item.news.picturemob.altText : item.title}
                      />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </StyledNewsPewviewSlider>
      )}
    </>
  );
};

const StyledNewsPewviewSlider = styled.div`
  width: 100%;
  height: auto;

  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    width: calc(100% + 20px);
  }

  .swiper-slide {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    border-radius: 16px;
    height: auto;
    .item_content {
      width: 100%;
      height: 230px;
      padding: 30px 30px 40px;
      position: relative;
      .category {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: #36b66b;
        margin-bottom: 16px;
      }
      .title {
        display: block;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: -0.3px;
        position: relative;
        max-height: 120px;
        overflow: hidden;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 20px;
        }
      }

      .date {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        position: absolute;
        left: 30px;
        bottom: 14px;
        color: #6D7288;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 13px;
        }
      }
    }
    .item_image img {
      width: 100%;
      height: auto;
      display: block;
    }
    .category__wrap {
      display: flex;
      justify-content: space-between;
    }
  }
  .category_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 16px;
    li {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.3px;
      margin-right: 10px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 13px;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
`;
