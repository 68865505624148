// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

export const CarbonNeutralBanner = ({ data }) => {
  return (
    <>
      {data && (
        <StyledCarbonNeutralBanner className="banner_block">
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div
              className="svg"
              dangerouslySetInnerHTML={{
                __html: data.cnbLogo,
              }}
            />
            <div
              className="content"
              dangerouslySetInnerHTML={{
                __html: data.cnbContent,
              }}
            />
          </div>
        </StyledCarbonNeutralBanner>
      )}
    </>
  );
};

const StyledCarbonNeutralBanner = styled.div`
  width: 100%;
  height: 209px;
  position: relative;
  overflow: hidden;

  .bg_layout {
    background-color: #f1f4f7;
    &:after {
      background-color: #e9edf2;
      right: -1089px;
    }
  }
`;

export const query = graphql`
  fragment CarbonNeutralBanner on WpPage_Components_Components_CarbonNeutralBanner {
    cnbContent
    cnbLogo
  }
`;
