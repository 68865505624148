export function addGTMHTML(counterID) {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return true;
  }
  const script = document.createElement("script");
  script.id = "GTM";
  script.async = true;
  script.innerHTML = `
      (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
              'gtm.start':
                new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
              'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${counterID}');
      `;
  document.head.appendChild(script);
}
export function addBodyNoScriptsHTML(counterID) {
  if (typeof window === "undefined" || typeof document === "undefined") {
    return true;
  }
  const noScript = document.createElement("noscript");
  noScript.id = "body_no_scripts";
  noScript.async = true;
  noScript.innerHTML = `
      <iframe src='https://www.googletagmanager.com/ns.html?id=${counterID}' height='0' width='0' style='display:none;visibility:hidden'></iframe>
  `;
  document.body.prepend(noScript);
}
