// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// components
import { MainMenu } from "../MainMenu";
import { PromoMainPageContent } from "./PromoMainPageContent";
// constants
import { BREAKPOINTS } from "../../settings";

export const PromoMainPageBlock = ({ data, fixedNav, id }) => {
  return (
    <>
      {data && (
        <StyledPromoMainPageBlock>
          <div
            className="bg_layout"
            style={{
              backgroundImage: `url(${data.pmpBackgroundImage?.localFile?.publicURL})`,
            }}
          >
            <video
              preload="auto"
              autoPlay={true}
              muted
              poster={data.pmpVideoPoster.localFile.publicURL}
            >
              <source
                type="video/mp4"
                src={data.pmpVideo.localFile.publicURL}
              />
            </video>
          </div>

          <MainMenu type={`${fixedNav ? "dark" : ""} mp_menu`} pageId={id} />

          <PromoMainPageContent title={data.pmpTitle} />
        </StyledPromoMainPageBlock>
      )}
    </>
  );
};

const StyledPromoMainPageBlock = styled.div`
  position: relative;
  height: 600px;

  @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
    height: 500px;
  }

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    height: 442px;
  }

  .dark {
    .main_menu {
      a {
        font-size: 16px;
      }
    }
  }

  .bg_layout {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      right: 550px;
      height: 100%;
      top: 0;
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(4px);
      display: block;
      transform: skewX(-30deg);
      z-index: 2;

      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 350px;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 150px;
      }
    }

    video {
      bottom: 0;
      position: absolute;
      z-index: 1;
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;

      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        display: none;
      }
    }
  }
`;

export const query = graphql`
  fragment PromoMainPageBlock on WpPage_Components_Components_PromoMainPageBlock {
    pmpTitle
    pmpBackgroundImage {
      localFile {
        publicURL
      }
    }
    pmpVideo {
      localFile {
        publicURL
      }
    }
    pmpVideoMobileImage {
      localFile {
        publicURL
      }
    }
    pmpVideoPoster {
      localFile {
        publicURL
      }
    }
  }
`;
