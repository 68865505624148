// libraries
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// components
import { MirrorListBlock } from "../MirrorListBlock";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";
// static
import { SVG_DOWNLOAD_ICON } from "../../icons/icons";

export const FinancialResponsibilityBlock = ({ data }) => {
  const elemId = "responsibility";
  const blockRef = React.useRef(null);

  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setMobile(window.matchMedia("(max-width: 992px)").matches);
    }
  }, []);

  return (
    <>
      {data && (
        <StyledFinancialResponsibilityBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="pretitle_block">
              <span
                className="svg"
                dangerouslySetInnerHTML={{
                  __html: data.frbPretitleIcon,
                }}
              />
            </div>

            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.frbTitle,
                }}
              />
            </div>

            <div
              className="CSRPolicy"
              dangerouslySetInnerHTML={{
                __html: data.csrpolicy,
              }}
            />

            {data.frbDownloadCard.map((item, index) => (
              <div className="card" key={index}>
                <div className="card_title_block">
                  <h2
                    className="card_title"
                    dangerouslySetInnerHTML={{
                      __html: item.frbDownloadCardTitle,
                    }}
                  />
                  {!isMobile ? (
                    <a
                      className="card_file_button"
                      href={item.frbDownloadCardFile?.localFile?.publicURL}
                      download={true}
                    >
                      <span
                        className="label"
                        dangerouslySetInnerHTML={{
                          __html: item.frbDownloadCardLabel,
                        }}
                      />
                      <span
                        className="svg-wrapper"
                        dangerouslySetInnerHTML={{
                          __html: SVG_DOWNLOAD_ICON,
                        }}
                      />
                    </a>
                  ) : null}
                </div>
                {isMobile ? (
                  <a
                    className="card_file_button"
                    href={item.frbDownloadCardFile?.localFile?.publicURL}
                    download={true}
                  >
                    <span
                        className="label"
                        dangerouslySetInnerHTML={{
                          __html: item.frbDownloadCardLabel,
                        }}
                      />
                    <span
                      className="svg-wrapper"
                      dangerouslySetInnerHTML={{
                        __html: SVG_DOWNLOAD_ICON,
                      }}
                    />
                  </a>
                ) : null}
              </div>
            ))}

            {data.frbMirrorList.map((item, index) => (
              <MirrorListBlock
                key={index}
                index={index}
                title={item.frbMirrorListTitle}
                content={item.frbMirrorListContent}
                image={item.frbMirrorListImage?.localFile?.publicURL}
                color={"#000"}
              />
            ))}
          </div>
        </StyledFinancialResponsibilityBlock>
      )}
    </>
  );
};

const StyledFinancialResponsibilityBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 80px 0;
  @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
    padding: 60px 0 0;
  }
  .bg_layout {
    background-color: rgba(241, 244, 247, 0.5);
  }

  .pretitle_block {
    width: 100%;
    height: auto;
    margin-bottom: 5px;

    .svg {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      svg {
        width: 55px;
        height: 55px;
      }
    }
  }

  .title_block {
    width: 65%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;
    color: #3db26c;
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -0.5px;
    }
  }

  .CSRPolicy {
    margin: 0 0 80px;
    font-size: 18px;
    line-height: 1.55;
    & > p :not(:first-of-type), ul {
      padding-top: 15px;
    }
    h3 {
      width: 400px;
      font-size: 36px;
      font-weight: 700;
      line-height: 1.12;
      letter-spacing: -0.5px;
      margin-bottom: 32px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 100%;
        font-size: 32px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        line-height: 1.25;
        text-align: center;
        letter-spacing: -0.3px;
      }
    }
    p {
      letter-spacing: -0.5px;
    }
    ul {
      li {
        margin-bottom: 15px;
        text-indent: 20px;
        position: relative;
        &:before {
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #000;
          position: absolute;
          top: 10px;
          left: 5px;
        }
      }
      & > :last-child {
        margin-bottom: 0;
      }
    }
  }

  .card {
    width: 100%;
    height: auto;
    background-color: rgb(0, 94, 184);
    padding: 48px 40px 80px;
    color: rgb(255, 255, 255);
    margin-bottom: 80px;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      padding: 40px;
    }
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      padding: 40px 20px 60px;
    }

    .card_title_block {
      width: 100%;
      height: auto;
      position: relative;
      margin-bottom: 40px;
      .card_title {
        font-weight: 700;
        font-size: 36px;
        line-height: 1.12;
        letter-spacing: -0.5px;
        color: rgb(255, 255, 255);
        width: 50%;
        @media (max-width: ${BREAKPOINTS.xl + "px"}) {
          width: 70%;
        }
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          width: 80%;
        }
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          width: 100%;
        }
      }
      .card_file_button {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    .card_file_button {
      width: fit-content;
      height: 40px;
      background-color: #fff;
      border-radius: 100px;
      padding-right: 40px;
      position: relative;
      display: block;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        margin-top: 40px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        margin: 40px auto 0;
      }
      .label {
        width: fit-content;
        height: 100%;
        padding: 0 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 18px;
        line-height: 1.12;
        letter-spacing: -0.385714px;
        color: #005eb8;
        border-right: 1px solid #005eb8;
      }
      .svg-wrapper {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 16px;
          height: 16px;
          fill: #005eb8;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment FinancialResponsibilityBlock on WpPage_Components_Components_FinancialResponsibilityBlock {
    frbPretitleIcon
    frbTitle
    frbMirrorList {
      frbMirrorListContent
      frbMirrorListTitle
      frbMirrorListImage {
        localFile {
          publicURL
        }
      }
    }
    csrpolicy
    frbDownloadCard {
      frbDownloadCardTitle
      frbDownloadCardLabel
      frbDownloadCardFile {
        localFile {
          publicURL
        }
      }
    }
  }
`;
