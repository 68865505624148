// libraries
import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
// static
import { SVG_ROUND_NAV_RIGHT_TITLE } from "../../../icons/icons";

export const OurProductsBlockLinkItem = ({ data }) => {
  const setLinkTarget = link => {
    if (link.indexOf("http") !== -1) {
      return "_blank";
    }

    return null;
  };

  const props = {
    to: data.product.oprmpPlProductLink,
    state: {
      project: data.activeIndex,
    },
    className: "title",
    target: setLinkTarget(data.product.oprmpPlProductLink),
  };

  return (
    <StyledOurProductsBlockLinkItem {...props}>
      <h3
        dangerouslySetInnerHTML={{
          __html: data.product.oprmpPlProductTitle,
        }}
      />
      <span
        className="svg"
        dangerouslySetInnerHTML={{
          __html: SVG_ROUND_NAV_RIGHT_TITLE,
        }}
      />
    </StyledOurProductsBlockLinkItem>
  );
};

const StyledOurProductsBlockLinkItem = styled(Link)`
  width: 100%;
  height: auto;
  margin-bottom: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 36px;
    color: #fff;
  }

  .svg {
    margin-left: 10px;
    width: fit-content;
    height: fit-content;
    position: relative;
    top: 4px;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;
