// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Link } from "gatsby";
// constants
import { BREAKPOINTS } from "../../settings";
// static
import { SVG_ROUND_NAV_RIGHT_TITLE_GREEN } from "../../icons/icons";

export const WorkingResponsiblyBlock = ({ data }) => {
  return (
    <>
      {data && (
        <StyledWorkingResponsiblyBlock>
          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.wrbTitle,
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: data.wrbSubtitle,
                }}
              />
            </div>

            <div className="list">
              {data.wrbList.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="icon"
                    dangerouslySetInnerHTML={{
                      __html: item.wrbListIcon,
                    }}
                  />

                  <Link to={item.wrbListLink} className="title_wrap">
                    <span
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: item.wrbListTitle,
                      }}
                    />

                    <span
                      className="svg"
                      dangerouslySetInnerHTML={{
                        __html: SVG_ROUND_NAV_RIGHT_TITLE_GREEN,
                      }}
                    />
                  </Link>

                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: item.wrbListContent,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </StyledWorkingResponsiblyBlock>
      )}
    </>
  );
};

const StyledWorkingResponsiblyBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  padding: 80px 0 90px;

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 86px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 24px;
    }

    span {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      br {
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          display: none;
        }
      }
    }
  }

  .list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      width: 30%;
      height: auto;
      margin-right: 5%;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        width: 32%;
        margin-right: 2%;
      }

      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 50px;
      }

      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }

      .icon {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        margin-bottom: 36px;
        svg {
          width: 54px;
          height: 54px;
        }
      }

      .title_wrap {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 25px;
        .title {
          font-weight: 700;
          font-size: 36px;
          line-height: 32px;
          text-align: center;
          letter-spacing: -0.5px;
          color: #4eb16c;
          margin-right: 10px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            font-size: 32px;
          }
        }
        .svg {
          width: fit-content;
          height: 100%;
          position: relative;
          top: 8px;
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      .content {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment WorkingResponsiblyBlock on WpPage_Components_Components_WorkingResponsiblyBlock {
    wrbSubtitle
    wrbTitle
    wrbList {
      wrbListContent
      wrbListIcon
      wrbListTitle
      wrbListLink
    }
  }
`;
