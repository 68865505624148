// libraries
import React from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../../../../settings";
// helpers
import { translator } from "../../../../../helpers/lang";

export const AcceptCheckboxWithMessage = ({ labelES, labelEN, langCurrent }) => {

  return (
    <StyledAcceptCheckboxWithMessage>
      <StyledItem data-checked={null}>
        <div>
          <input type="checkbox" id="checkPrivacy" name="checkPrivacy" />
          <label
            htmlFor="checkPrivacy"
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                es: labelES,
                en: labelEN,
              }),
            }}
          />
          <div
            className={translator(langCurrent, {
              es: "error error_es",
              en: "error",
            })}
            dangerouslySetInnerHTML={{
              __html: translator(langCurrent, {
                es: "Por favor, acepte los términos para continuar",
                en: "Please, acсept the terms to proceed",
              }),
            }}
          />
        </div>
      </StyledItem>
    </StyledAcceptCheckboxWithMessage>
  );
};

const StyledAcceptCheckboxWithMessage = styled.div`
  display: flex;
  margin-bottom: 8px;
  width: 100%;
  [data-checked="false"] {
    input[type="checkbox"] {
      outline: 3.5px solid #ba1a1a !important;
    }
    .error {
      display: block;
    }
  }
  .error {
    width: 100%;
    padding-top: 4px;
    color: #ba1a1a;
    font-size: 16px;
    display: none;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      font-size: 15px;
      bottom: 8px;
    }
  }
`;

export const StyledItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    margin-right: 12px;
  }
  input[type="checkbox"] {
    width: 12px;
    height: 12px;
    margin: 0px 24px 0px 6px;
    outline: 3.5px solid #005eb8;
    appearance: none;
    position: relative;
    border-radius: 0;
    padding: 0;
    :checked {
      background: #005eb8;
      color: #fff;
    }
    :after {
      width: 13px;
      height: 13px;
      content: "";
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbFJ1bGU9ImV2ZW5vZGQiIGNsaXBSdWxlPSJldmVub2RkIiBkPSJNMTMuNDUgMS4wMDU0N0MxMy4zNDQyIDEuMDIyMyAxMy4yNDYgMS4wNzcyMyAxMy4xNzIgMS4xNjE2MUMxMC42OTUzIDMuODc0MzcgOC4wMTE0NiA3LjAyNTA2IDUuNTM1OTQgOS43NTQxMkwxLjc5Mjk3IDYuMzg2MDRDMS41ODgzIDYuMTk4ODggMS4yODM4MiA2LjIyODU4IDEuMTEyNTggNi40NTIyOEMwLjk0MTM0MSA2LjY3NTk4IDAuOTY4NTE4IDcuMDA4NzYgMS4xNzMxOSA3LjE5NTkyQzEuMTc0MTUgNy4xOTY5NyAxLjE3NTM1IDcuMTk4MjkgMS4xNzY3OSA3LjE5OTA4TDUuMjY1MzggMTAuODc5NEM1LjQ1NjM0IDExLjA1MTkgNS43MzYwNCAxMS4wMzc5IDUuOTExNjEgMTAuODQ2NkM4LjQ4NjcgOC4wMjU3OSAxMS4yOTI3IDQuNzA4MTggMTMuODQ4MyAxLjkwOTJDMTQuMDQxOSAxLjcxMDIxIDE0LjA1MTUgMS4zNzc2OSAxMy44Njk3IDEuMTY1ODJDMTMuNzYyMiAxLjA0MDcgMTMuNjA1NCAwLjk4MDc2MyAxMy40NSAxLjAwNTQ3WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTEzLjQ1IDEuMDA1NDdDMTMuMzQ0MiAxLjAyMjMgMTMuMjQ2IDEuMDc3MjMgMTMuMTcyIDEuMTYxNjFDMTAuNjk1MyAzLjg3NDM3IDguMDExNDYgNy4wMjUwNiA1LjUzNTk0IDkuNzU0MTJMMS43OTI5NyA2LjM4NjA0QzEuNTg4MyA2LjE5ODg4IDEuMjgzODIgNi4yMjg1OCAxLjExMjU4IDYuNDUyMjhDMC45NDEzNDEgNi42NzU5OCAwLjk2ODUxOCA3LjAwODc2IDEuMTczMTkgNy4xOTU5MkMxLjE3NDE1IDcuMTk2OTcgMS4xNzUzNSA3LjE5ODI5IDEuMTc2NzkgNy4xOTkwOEw1LjI2NTM4IDEwLjg3OTRDNS40NTYzNCAxMS4wNTE5IDUuNzM2MDQgMTEuMDM3OSA1LjkxMTYxIDEwLjg0NjZDOC40ODY3IDguMDI1NzkgMTEuMjkyNyA0LjcwODE4IDEzLjg0ODMgMS45MDkyQzE0LjA0MTkgMS43MTAyMSAxNC4wNTE1IDEuMzc3NjkgMTMuODY5NyAxLjE2NTgyQzEzLjc2MjIgMS4wNDA3IDEzLjYwNTQgMC45ODA3NjMgMTMuNDUgMS4wMDU0NyIgc3Ryb2tlPSJ3aGl0ZSIvPgo8L3N2Zz4K");
      display: none;
    }
    :checked:after {
      display: block;
    }
  }
  label {
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      font-size: 12px;
    }
  }
  a {
    text-decoration: underline;
    color: #005eb8;
  }
  label {
    font-size: 18px;
    color: #6d7288;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      display: inline-grid;
      font-size: 17px;
    }
  }
`;
