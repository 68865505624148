/* eslint-disable no-magic-numbers */
// libraries
import React from "react";
import styled from "styled-components";
// components
import { IconArrow } from "../Icons/IconArrow";
// context
import { usePopups } from "../../context/popupsContext";

const sendEvents = (type) => {
    switch (type) {
        case 'loans':
            if (window.dataLayer) {
                window.dataLayer.push({ event: 'loan_calc_leadform_сlick' });
            }
            if (window.ym) {
                window.ym(67027090, 'reachGoal', 'start_invest');
                window.ym(67027090, 'reachGoal', 'all_open_form');

                window.ym(57564463, 'reachGoal', 'loan_calc_leadform_сlick');
            }
            break;
        case 'bonds':
            if (window.dataLayer) {
                window.dataLayer.push({ event: 'bond_calc_leadform_сlick' });
            }
            if (window.ym) {
                window?.ym(91379641, 'reachGoal', 'zayavka_click');
                return true;
            }
            break;
        default:
            break;
    }
};

export const OpenForm = ({ type }) => {
  const { setShowContactsFormPopup } = usePopups();
  const buttonClick = () => {
    sendEvents(type);
    setShowContactsFormPopup(true);
  };

  return (
      <StyledOpenForm href="#bonds" onClick={buttonClick}>
          Book a call
          <IconArrow size={14} />
      </StyledOpenForm>
  );
};

export const StyledOpenForm = styled.a`
    background: #005EB8;
    border-radius: 50px;
    border: 1px solid #005EB8;
    color: #FFFFFF;
    display: inline-block;
    font-size: 16px;
    padding: 14px 12px;
    text-align: center;
    transition: background 1.5s ease;

    &:hover,
    &:focus {
        background: #FFFFFF;
        color: #005EB8;
    }

    .icon__arrow {
        margin: 0 0 0 8px;

        path {
            stroke: currentColor;
        }
    }
`;
