// libraries
import { useStaticQuery, graphql } from "gatsby";

export const PositionsList = () => {
  const data = useStaticQuery(graphql`
    {
      allWpPosition {
        nodes {
          title
          content
          uri
          positions {
            department
            location
            email
          }
        }
      }
    }
  `);

  return data?.allWpPosition?.nodes;
};
