// libraries
import React, { useState } from "react";
import styled from "styled-components";
// actions
import { updatePositionFormStatus } from "../../../store/actions/base";
// components
import { PositionForm } from "./PositionForm";
// constants
import { BREAKPOINTS } from "../../../settings";
// helpers
import { translator } from "../../../helpers/lang";
// hooks
import { useBaseStore } from "../../../store";
// static
import { SVG_MENU_CLOSE } from "../../../icons/icons";
import { SVG_COPY_ICON } from "../../../icons/icons";

export const PositionPopup = ({
  data,
  translatePositions,
  langCurrent,
  toCopyLink,
  showPositionPopup,
  setShowPositionPopup
}) => {
  const { dispatch } = useBaseStore();

  const [tabIndex, setTabIndex] = useState(0);

  function closePopup() { 
    let defaultData = {
      isOk: null,
      message: null,
    };

    updatePositionFormStatus(dispatch, defaultData);
    setShowPositionPopup(false);
  }

  function changeTab(index) {
    let defaultData = {
      isOk: null,
      message: null,
    };

    updatePositionFormStatus(dispatch, defaultData);
    setTabIndex(index);
  }

  function getFullLocationHTML(country, translatePositions, langCurrent) {
    let currentLocation = translatePositions.locations?.filter(
      item => item.country === country
    )[0];

    // if es lang
    if (langCurrent === "es") {
      return currentLocation.cityEs + ", " + currentLocation.countryEs;
    } else {
      return currentLocation.city + ", " + currentLocation.country;
    }
  }

  const [copied, setCopied] = useState(false);

  function copyLink() {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  }

  return (
    <>
      {data && (
        <StyledPositionPopup
          className="popup_overlay"
          onMouseDown={e =>
            e.target.classList.contains("popup_overlay") && closePopup()
          }
        >
          <div className="popup_wrapper">
            <div className="tab_line">
              <button
                className={`tab ${tabIndex === 0 ? "active" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: translator(langCurrent, {
                    es: "Info",
                    en: "Info",
                  }),
                }}
                onClick={() => changeTab(0)}
                aria-label="Info"
              />
              <button
                className={`tab ${tabIndex === 1 ? "active" : ""}`}
                dangerouslySetInnerHTML={{
                  __html: translator(langCurrent, {
                    es: "Enviar una solicitud",
                    en: "Send a request",
                  }),
                }}
                onClick={() => changeTab(1)}
                aria-label={translator(langCurrent, {
                  es: "Enviar una solicitud",
                  en: "Send a request",
                })}
              />

              <button
                className="close_popup"
                dangerouslySetInnerHTML={{
                  __html: SVG_MENU_CLOSE,
                }}
                onClick={() => closePopup()}
                aria-label="close_popup"
              />
            </div>

            {tabIndex === 0 ? (
              <>
                <div className="info_wrapper">
                  <div className="title_block">
                    <div
                      className="title"
                      dangerouslySetInnerHTML={{
                        __html: data.title,
                      }}
                    />
                    <div className="locations">
                      {data.positions.location.map((location, index) => (
                        <div
                          key={index}
                          className="loc"
                          dangerouslySetInnerHTML={{
                            __html: getFullLocationHTML(
                              location,
                              translatePositions,
                              langCurrent
                            ),
                          }}
                        />
                      ))}
                    </div>
                  </div>

                  <div
                    className="content_wrapper"
                    dangerouslySetInnerHTML={{
                      __html: data.content,
                    }}
                  />
                </div>

                <div className="button_line">
                  <div className="copy__link">
                    <button
                      className="btn"
                      aria-label={translator(langCurrent, {
                        es: "Enviar una solicitud",
                        en: "Send a request",
                      })}
                      dangerouslySetInnerHTML={{
                        __html: translator(langCurrent, {
                          es: "Enviar una solicitud",
                          en: "Send a request",
                        }),
                      }}
                      onClick={() => changeTab(1)}
                    />
                    <div className="copy">
                      {/* TODO: span (текст) должен быть внутри кнопки */}
                      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                      <button
                        dangerouslySetInnerHTML={{
                          __html: SVG_COPY_ICON,
                        }}
                        className="copy__btn"
                        onClick={copyLink}
                      />
                      <span className="copy__label">
                        {!copied
                          ? translator(langCurrent, {
                              es: "¡Haga clic para copiar!",
                              en: "Click to copy!",
                            })
                          : translator(langCurrent, {
                              es: "¡Copiada!",
                              en: "Copied!",
                            })}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="request_wrapper">
                <div className="title_block">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "Nos encantaría saber de ti",
                        en: "We’d love to hear from you",
                      }),
                    }}
                  />
                  <div
                    className="subtitle"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "Comuníquese con nosotros y nos comunicaremos con usted en breve",
                        en: "Reach out to us and we’ll get back to you shortly",
                      }),
                    }}
                  />
                </div>

                <PositionForm data={data} />
              </div>
            )}
          </div>
        </StyledPositionPopup>
      )}
    </>
  );
};

const StyledPositionPopup = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);

  .copy .copy__label {
    visibility: visible;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px 10px;
    position: absolute;
    z-index: 1;
    bottom: 21.4%;
    left: 50.4%;
    opacity: 0;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;
  }

  .copy .copy__label::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .copy:hover .copy__label {
    visibility: visible;
    opacity: 1;
  }

  .copy {
    &__btn {
      cursor: pointer;
      width: 45px;
      height: 45px;
      display: block;
      margin-left: 10px;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 35px;
        height: 35px;
        display: none;
      }
    }
    &__link {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .popup_wrapper {
    width: 95vw;
    height: 100%;
    max-width: 682px;
    max-height: 720px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 10px;
  }

  .tab_line {
    flex: 0 0 auto;
    width: 100%;
    height: 45px;
    padding-right: 45px;
    position: relative;
    display: flex;
    flex-direction: row;
    @media (max-height: 700px) {
      height: 40px;
      padding-right: 40px;
    }
    button {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      white-space: nowrap;
      cursor: pointer;
      font-size: 18px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 17px;
      }

      &.close_popup {
        position: absolute;
        right: 0;
        top: 0;
        padding: 0;
        background-color: #005eb8;
        width: 45px;
        height: 100%;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          width: 40px;
        }
        &:hover {
          opacity: 0.8;
        }
        svg {
          width: 20px;
          height: 20px;
          @media (max-width: ${BREAKPOINTS.lg + "px"}) {
            width: 16px;
            height: 16px;
          }
          path {
            fill: #fff;
          }
        }
      }
      &.tab {
        width: 100%;
        background-color: #efefef;
        &.active {
          background-color: #fff;
        }
      }
    }
  }

  .info_wrapper {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    height: calc(100% - 100px);
    .title_block {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      padding: 0 20px;
      .title {
        width: 100%;
        font-size: 20px;
        margin-bottom: 15px;
        font-weight: 700;
      }
      .locations {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .content_wrapper {
      width: 100%;
      padding: 10px 0;
      overflow-y: scroll;
      flex-grow: 1;
      p {
        margin-bottom: 15px;
      }
      ul {
        width: 100%;
        height: auto;
        li {
          width: 100%;
          height: auto;
          margin-bottom: 15px;
          text-indent: 20px;
          position: relative;
          &:before {
            content: "";
            display: block;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background-color: #000;
            position: absolute;
            top: 5px;
            left: 5px;
          }
        }
      }
      strong {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
  .button_line {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 55px;
    flex: 0 0 auto;
  }

  .request_wrapper {
    width: 100%;
    height: auto;
    padding: 40px 0 0;
    overflow-y: auto;
    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      padding: 30px 0 0;
    }
    .title_block {
      width: 100%;
      height: auto;
      margin-bottom: 20px;
      padding: 0 20px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        margin-bottom: 10px;
      }
      .title {
        width: 100%;
        font-size: 20px;
        margin-bottom: 15px;
        font-weight: 700;
        color: #005eb8;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          margin-bottom: 16px;
        }
      }
      .subtitle {
        font-size: 18px;
        color: #6D7288;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 17px;
        }
      }
    }
  }

  .btn {
    width: fit-content;
    height: 45px;
    background-color: #005eb8;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    border-radius: 4px;
    cursor: pointer;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      height: 35px;
      font-size: 15px;
    }
    &:hover {
      opacity: 0.8;
    }
  }
`;
