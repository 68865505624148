// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// components
import { HistoryYearsSlider } from "./HistoryYearsSlider";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const HistoryPreviewBlock = ({ data }) => {
  const elemId = "history";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledHistoryPreviewBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.hpbTitle,
                }}
              />
            </div>
          </div>

          <HistoryYearsSlider historyList={data.hpbHistoryList} />
        </StyledHistoryPreviewBlock>
      )}
    </>
  );
};

const StyledHistoryPreviewBlock = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100px;
  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    height: auto;
    padding: 60px 0 100px;
  }
  .bg_layout {
    background-color: #e8edf2;
    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      background-color: #f1f4f7;
    }
    &:after {
      background-color: #f1f4f7;
      right: 1060px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 857px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        right: -238px;
        background-color: #e8edf2;
      }
    }
  }

  .title_block {
    text-align: center;
    width: 55%;
    margin: 0 auto 100px;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
      margin: 0 auto 90px;
    }

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      margin: 0 auto 40px;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
    }
  }
`;

export const query = graphql`
  fragment HistoryPreviewBlock on WpPage_Components_Components_HistoryPreviewBlock {
    hpbTitle
    hpbHistoryList {
      hpbHlYear
      hpbHlEventList {
        hpbEvlEvent
      }
    }
  }
`;
