// libraries
import React from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../../settings";
// context
import { useBonds } from "../../../context/bondsContext";
// helpers
import { prettify } from "../../../helpers/base";
// hooks
import { useCalcBondsStore } from "../../../store";

const getRateDisplay = (dataCalcBonds) => {
    const { rateDisplay } = dataCalcBonds.products[dataCalcBonds.currentProduct][dataCalcBonds.currentActiveBond];

    return rateDisplay;
};

const calculateCount = (dataCalcBonds, bondsCalcCurrencyVal) => {
    const currentCost = dataCalcBonds.products[dataCalcBonds.currentProduct][dataCalcBonds.currentActiveBond].cost;
    const currentVal = bondsCalcCurrencyVal;

    return `${Math.floor(currentVal / currentCost)}`;
};
const calculateQuarterlyPayment = (dataCalcBonds, bondsCalcCurrencyVal) => {
    const currentVal = bondsCalcCurrencyVal;
    const currentRate = dataCalcBonds.products[dataCalcBonds.currentProduct][dataCalcBonds.currentActiveBond].rate;
    const currentName = dataCalcBonds.products[dataCalcBonds.currentProduct][dataCalcBonds.currentActiveBond].sign;
    let quarterlyPayment = Math.round((currentVal * currentRate / 100) / 4);

    return `${prettify(quarterlyPayment)} ${currentName}`;
};

const calculateFullIncome = (dataCalcBonds, bondsCalcCurrencyVal) => {
    const currentVal = bondsCalcCurrencyVal;
    const currentRate = dataCalcBonds.products[dataCalcBonds.currentProduct][dataCalcBonds.currentActiveBond].rate;
    const currentName = dataCalcBonds.products[dataCalcBonds.currentProduct][dataCalcBonds.currentActiveBond].sign;
    const termM = dataCalcBonds.products[dataCalcBonds.currentProduct][dataCalcBonds.currentActiveBond].termM;
    let fullIncome = Math.round(((currentVal * currentRate / 100) / 12)  * termM);
    
    return `${prettify(fullIncome)} ${currentName}`;
}

export const BondsCalculatorInfo = () => {
  const { state: bondsState } = useCalcBondsStore();
  const { bondsCalcCurrencyVal } = useBonds();

    return (
        <StyledBondsCalculatorInfo className="calc-info">
            <div className="calc-info-items">
                <div className="calc-info-item">
                    <div className="calc-info-item_value">{calculateFullIncome(bondsState, bondsCalcCurrencyVal)}</div>
                    <div className="calc-info-item_description">Lifetime income</div>
                </div>
                <div className="calc-info-item">
                    <div className="calc-info-item_value">{getRateDisplay(bondsState)}</div>
                    <div className="calc-info-item_description">Annual yield</div>
                </div>
                <div className="calc-info-item">
                    <div className="calc-info-item_value">{calculateQuarterlyPayment(bondsState, bondsCalcCurrencyVal)}</div>
                    <div className="calc-info-item_description">Quarterly payment</div>
                </div>
                <div className="calc-info-item">
                    <div className="calc-info-item_value">{calculateCount(bondsState, bondsCalcCurrencyVal)}</div>
                    <div className="calc-info-item_description">Number of bonds</div>
                </div>
            </div>
        </StyledBondsCalculatorInfo>
    );
};

const StyledBondsCalculatorInfo = styled.div`
    .calc-info-items {
        display: flex;
        flex-wrap: wrap;
        margin: -15px -10px;

        @media (min-width: ${BREAKPOINTS.sm}px) {
            margin: -15px;
        }
    }

    .calc-info-item {
        width: 50%;
        padding: 15px 10px;

        @media (min-width: ${BREAKPOINTS.sm}px) {
            padding: 15px;
        }

        &.hidden {
            visibility: hidden;
        }
    }

    .calc-info-item_value {
        font-size: 20px;
        font-weight: 700;

        @media (min-width: ${BREAKPOINTS.sm}px) {
            font-size: 18px;
        }
    }

    .calc-info-item_description {
        margin: 4px 0 0;
        color: #898989;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.4;
        letter-spacing: -0.3px;
    }
`;
