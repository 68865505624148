// libraries
import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

import {
  SVG_PLUS_CIRCLE_ICON,
  SVG_LINKEDIN_ICON,
  SVG_MENU_CLOSE,
} from "../../icons/icons";
import { scrollToElemTargetId } from "../../helpers/base";

export const LeadershipBlock = ({ data }) => {
  const [showedIndex, setShowedIndex] = useState(-1);

  const elemId = "team";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data.lspOurTeamList && (
        <StyledLeadershipBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />

          <div className="main_wrapper_lg">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.lspTitle,
                }}
              />
            </div>
            <div className="leader_list">
              {data.lspOurTeamList.map((item, index) => (
                <div
                  key={index}
                  className={`item ${showedIndex === index ? "show" : ""}`}
                >
                  <button
                    className="image_wrapper"
                    aria-label="Show content"
                    style={{
                      backgroundImage: `url(${item.lspOtlImage?.localFile?.publicURL})`,
                    }}
                    onClick={() => setShowedIndex(index)}
                  />

                  <div className="highlights_wrapper">
                    <div
                      className="name"
                      dangerouslySetInnerHTML={{
                        __html: item.lspOtlName,
                      }}
                    />
                    <div
                      className="position"
                      dangerouslySetInnerHTML={{
                        __html: item.lspOtlPosition,
                      }}
                    />

                    <button
                      className="svg"
                      aria-label="Show content"
                      dangerouslySetInnerHTML={{
                        __html: SVG_PLUS_CIRCLE_ICON,
                      }}
                      onClick={() => setShowedIndex(index)}
                    />
                  </div>

                  <div
                    className={`hidden_content ${
                      showedIndex === index ? "show" : ""
                    }`}
                  >
                    <div
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: item.lspOtlContent,
                      }}
                    />

                    <a
                      href={item.lspOtlLinkedin}
                      target="_blank"
                      aria-label="LinkedIn"
                      rel="nofollow noopener noreferrer"
                      dangerouslySetInnerHTML={{
                        __html: SVG_LINKEDIN_ICON,
                      }}
                    />
                  </div>

                  <button
                    className={`hide ${showedIndex === index ? "show" : ""}`}
                    aria-label="Hide content"
                    dangerouslySetInnerHTML={{
                      __html: SVG_MENU_CLOSE,
                    }}
                    onClick={() => setShowedIndex(-1)}
                  />
                </div>
              ))}
            </div>
          </div>
        </StyledLeadershipBlock>
      )}
    </>
  );
};

const StyledLeadershipBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 100px 0 0;
  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    padding: 80px 0 0;
  }
  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 60px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 15px;
    }
  }

  .leader_list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item {
      width: 30%;
      height: auto;
      margin-right: 5%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      margin-bottom: 100px;
      overflow: hidden;
      padding: 15px;
      &.show {
        box-shadow: rgba(0, 0, 0, 0.1) 0 10px 50px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        width: 48%;
        margin-right: 4%;
        &:nth-child(3n) {
          margin-right: 4%;
        }
        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 66px;
        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      .image_wrapper {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        z-index: 1;
        cursor: pointer;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          width: 93px;
          height: 93px;
        }
      }

      .highlights_wrapper {
        width: calc(100% - 160px);
        height: auto;
        position: relative;
        z-index: 1;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          width: calc(100% - 110px);
        }
        .name {
          font-weight: 700;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.3px;
          margin-bottom: 10px;
        }
        .position {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.3px;
          margin-bottom: 10px;
        }
        .svg {
          width: auto;
          height: auto;
          cursor: pointer;
          &:hover {
            svg {
              transform: rotate(360deg);
            }
          }
          svg {
            width: 16px;
            height: 16px;
            fill: #005eb8;
            transition: transform 0.5s ease-in-out;
          }
        }
      }

      .hidden_content {
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        right: -120%;
        background-color: #fff;
        overflow: auto;
        transition: right 0.3s ease-in-out;
        padding: 10px 30px 10px 10px;
        &.show {
          right: 0;
        }
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.1);
        }

        .content {
          font-size: 14px;
          padding-top: 10px;
          margin-bottom: 15px;
          font-weight: 400;
          p {
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        a {
          width: auto;
          height: auto;
          svg {
            width: 30px;
            height: 30px;
          }
        }
      }

      .hide {
        position: absolute;
        top: 0;
        right: -120%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        cursor: pointer;
        z-index: 6;
        transition: right 0.4s ease-in-out;
        &.show {
          right: 10px;
        }
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment LeadershipBlock on WpPage_Components_Components_LeadershipBlock {
    lspTitle
    lspOurTeamList {
      lspOtlName
      lspOtlPosition
      lspOtlContent
      lspOtlLinkedin
      lspOtlImage {
        localFile {
          publicURL
        }
      }
    }
  }
`;
