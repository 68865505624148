// libraries
import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
// components
import { CalcPanel } from './CalcPanel';
// constants
import { BREAKPOINTS } from "../../settings";

export const CalcBlock = ({ data }) => {
  const { title, pluses } = data;
  
    return (
        <StyledMainHeading>
          <StyledTextBlock>
            <div className="main_wrapper_lg">
              <StyledTitle variant="h1" as="h1" dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          </StyledTextBlock>
            <StyledCalcBlock>
                <div className="main_wrapper_lg">
                    <div className="inner">
                        <ul className="pluses-list">
                            {pluses.map(({ text }, index) => (
                                <li key={index}>
                                    <span dangerouslySetInnerHTML={{ __html: text }} />
                                </li>
                            ))}
                        </ul>
                        <div className="calc-wrap">
                            <div className="calc-inner">
                                <CalcPanel />
                            </div>
                        </div>
                    </div>
                </div>
            </StyledCalcBlock>
        </StyledMainHeading>
    );
};

const StyledMainHeading = styled.section`
    position: relative;
    padding-top: 80px;
    background-color: #F1F4F7;
    z-index: 4;
  
    @media (min-width: ${BREAKPOINTS.md}px) {
      padding-top: 120px;
    }
  
    h1 {
        width: 100%;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 38px;
        line-height: 47px;
        letter-spacing: -1.5px;
        @media (min-width: ${BREAKPOINTS.md + "px"}) {
          font-size: 48px;
          line-height: 58px;
        }
        @media (min-width: ${BREAKPOINTS.xl + "px"}) {
          width: 55%;
          margin-bottom: 24px;
          font-size: 76px;
          line-height: 76px;
          letter-spacing: -1px;
        }
        span {
          color: #005eb8;
        }
    }
`;

const StyledTextBlock = styled.div`
    padding: 32px 0 40px;
  
    ${BREAKPOINTS.lg} {
        padding: 200px 0 162px;
        background-image: none;
    }
`;

const StyledTitle = styled.h1`
    margin-bottom: 24px;
    font-weight: 700;
    font-size: 42px;
    line-height: 1.2;
    letter-spacing: -1px;
  
    span {
        color: rgb(0, 94, 184);
    }

    @media (min-width: ${BREAKPOINTS.xxl}px) {
        width: 55%;
        font-size: 76px;
        letter-spacing: -1.5px;
    }
`;


const StyledCalcBlock = styled.div`
    position: relative;
    padding-bottom: 20px;
    border-radius: 0 0 16px 16px;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 40%;
        background-color: #005EB8;

        @media (min-width: ${BREAKPOINTS.lg}px) {
            height: 100%;
        }
    }

    @media (min-width: ${BREAKPOINTS.lg}px) {
        padding: 54px 0;
        border-radius: 0;
    }

    .inner {
        @media (min-width: ${BREAKPOINTS.lg}px) {
            display: flex;
            gap: 30px;
        }

        @media (min-width: ${BREAKPOINTS.xl}px) {
            gap: 105px;
        }
    }

    .pluses-list {
        padding: 32px 24px 40px;
        list-style-type: disc;

        @media (min-width: ${BREAKPOINTS.lg}px) {
            padding: 0 24px;
            max-width: 480px;
            margin-bottom: 0;
        }

        @media (min-width: ${BREAKPOINTS.xxl}px) {
            max-width: 496px;
        }

        > li {
            margin-bottom: 24px;
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: -0.3px;
            color: #fff;

            @media (min-width: ${BREAKPOINTS.lg}px) {
                font-size: 16px;
            }

            &:last-child {
                margin-bottom: 0;
            }
          
            a {
              color: #fff;
              text-decoration: underline;
            }
        }
    }

    .calc-wrap {
        position: relative;
        @media (min-width: ${BREAKPOINTS.md}px) {
            margin: 0;
        }

        @media (min-width: ${BREAKPOINTS.lg}px) {
            flex-grow: 1;
        }
    }

    .calc-inner {
        z-index: 1;

        @media (min-width: ${BREAKPOINTS.lg}px) {
            position: absolute;
            top: calc(50% + 32px);
            right: 0;
            transform: translateY(-50%);
        }

        @media (min-width: ${BREAKPOINTS.xl}px) {
            top: calc(50%);
        }

        @media (min-width: ${BREAKPOINTS.xxl}px) {
            transform: translate(32px, -50%);
            right: 30px;
        }
    }
`;

export const query = graphql`
    fragment CalcBlock on WpPage_Components_Components_CalcBlock {
        title
        pluses {
            text
        }
    }
`;
