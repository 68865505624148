// libraries
import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
// components
import { NewsPewviewSlider } from "./NewsPewviewSlider";
import { MediaPresenceSlider } from "../MediaPresenceSlider";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { useLang } from "../../context/languageContext";
// hooks
import { useNewsPreview } from "../../hooks/use-news-preview";
import { useBaseStore } from "../../store";
// static
import { SVG_ROUND_NAV_RIGHT_TITLE } from "../../icons/icons";

export const LatesNewsPreviewBlock = ({ data, id }) => {
  const { state } = useBaseStore();
  const isFrontPage = state.isFrontPage;
  const { lang:langCurrent } = useLang();

  let newsList = useNewsPreview(langCurrent);
  const sustArr = [];
  newsList.forEach(item => {
    item.categories.nodes.forEach(id => {
      if (id.databaseId === 336 || id.databaseId === 345) {
        sustArr.push(item);
      }
    });
  });

  if (id === 37 || id === 1126) {
    newsList = sustArr;
  }
  return (
    <>
      {newsList && (
        <StyledLatesNewsPreviewBlock
          className={`bg_layout ${isFrontPage ? "" : "not_front"}`}
        >
          <div className="bg_layout" />

          <div className="main_wrapper_lg">
            <div className="title_block">
              <Link to={data.lnpLink} className="title" state={{ pageId: id }}>
                <h2>{data.lnpTitle}</h2>
                <span
                  className="svg"
                  dangerouslySetInnerHTML={{
                    __html: SVG_ROUND_NAV_RIGHT_TITLE,
                  }}
                />
              </Link>
            </div>

            <div className="body_block">
              <NewsPewviewSlider data={{ newsList, langCurrent, id }} />
              {isFrontPage && <MediaPresenceSlider isHome={isFrontPage} />}
            </div>
          </div>
        </StyledLatesNewsPreviewBlock>
      )}
    </>
  );
};

const StyledLatesNewsPreviewBlock = styled.div`
  padding-top: 100px;
  position: relative;
  height: 1000px;

  &.not_front {
    height: 840px;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      height: 750px;
    }
    .bg_layout {
      background-color: #3aaa69;
      &:before {
        background-color: #3db26c;
        right: 1870px;
      }
      &:after {
        background-color: #3db26c;
        right: -1970px;
        display: block;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding-top: 60px;
    height: 850px;
  }

  .bg_layout {
    background-color: #0a2540;
    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: -567px;
      background-color: rgba(0, 0, 0, 0.2);
      transform: skewX(-30deg);

      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -170px;
      }

      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: -255px;
      }
    }

    &:after {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: -1450px;
      background-color: rgba(255, 255, 255, 0.06);
      transform: skewX(-30deg);
      display: none;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -1050px;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 605px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -400px;
      }
    }
  }

  .title_block {
    margin: 0 auto 60px;

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      margin: 0 auto 90px;
    }

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      margin: 0 auto 40px;
    }

    .title {
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #fff;

      h2 {
        display: inline-block;
        font-weight: 700;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: -0.5px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 32px;
          line-height: 32px;
        }
      }
      a {
        color: #fff;
      }
      .svg {
        width: auto;
        height: auto;
        margin-left: 20px;
        position: relative;
        top: 4px;

        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          top: 2px;
        }

        svg {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  .body_block {
    width: 100%;
    height: auto;
  }
`;

export const query = graphql`
  fragment LatesNewsPreviewBlock on WpPage_Components_Components_LatesNewsPreviewBlock {
    lnpTitle
    lnpLink
  }
`;
