// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// components
import { MirrorListBlock } from "../MirrorListBlock";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const ActivelyCollaborateBlock = ({ data }) => {
  const elemId = "people";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledActivelyCollaborateBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div className="pretitle_block">
              <span
                className="svg"
                dangerouslySetInnerHTML={{
                  __html: data.acbPretitleIcon,
                }}
              />
            </div>

            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.acbTitle,
                }}
              />
            </div>

            {data.acbMirrorList.map((item, index) => (
              <MirrorListBlock
                key={index}
                index={index}
                title={item.acbMirrorListTitle}
                content={item.acbMirrorListContent}
                image={item.acbMirrorListImage?.localFile?.publicURL}
                color={"#fff"}
              />
            ))}
          </div>
        </StyledActivelyCollaborateBlock>
      )}
    </>
  );
};

const StyledActivelyCollaborateBlock = styled.div`
  width: 100%;
  height: 658px;
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    height: auto;
  }
  .bg_layout {
    background-color: #42aa6f;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      background-color: #4eb16c;
    }
    &:before {
      background-color: #38a667;
      right: -840px;

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -300px;
      }
    }
  }

  .pretitle_block {
    width: 100%;
    height: auto;
    margin-bottom: 5px;

    .svg {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      svg {
        width: 55px;
        height: 55px;
      }
    }
  }

  .title_block {
    width: 65%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }
  }
`;

export const query = graphql`
  fragment ActivelyCollaborateBlock on WpPage_Components_Components_ActivelyCollaborateBlock {
    acbPretitleIcon
    acbTitle
    acbMirrorList {
      acbMirrorListTitle
      acbMirrorListContent
      acbMirrorListImage {
        localFile {
          publicURL
        }
      }
    }
  }
`;
