// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// components
import { MirrorListBlock } from "../MirrorListBlock";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const OurCarbonFootprintBlock = ({ data }) => {
  const elemId = "environment";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledOurCarbonFootprintBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />
          <div className="main_wrapper">
            <div className="pretitle_block">
              <span
                className="svg"
                dangerouslySetInnerHTML={{
                  __html: data.ocfPretitleIcon,
                }}
              />
              <span
                className="pretitle"
                dangerouslySetInnerHTML={{
                  __html: data.ocfPretitle,
                }}
              />
            </div>

            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.ocfTitle,
                }}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: data.ocfSubtitle,
                }}
              />
            </div>

            <div className="list">
              {data.ocfList.map((item, index) => (
                <div key={index} className="item">
                  <div
                    className="list_title"
                    dangerouslySetInnerHTML={{
                      __html: item.ocfListPretitle,
                    }}
                  />

                  <div className="value_wrapper">
                    <span
                      className="count"
                      dangerouslySetInnerHTML={{
                        __html: item.ocfListCounter,
                      }}
                    />
                    <span
                      className="value"
                      dangerouslySetInnerHTML={{
                        __html: item.ocfListValue,
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>

            {data.mirrorList.map((item, index) => (
              <MirrorListBlock
                key={index}
                index={index}
                title={item.mirrorListTitle}
                content={item.mirrorListContent}
                image={item.mirrorListImage?.localFile?.publicURL}
                color={"#000"}
              />
            ))}
          </div>
        </StyledOurCarbonFootprintBlock>
      )}
    </>
  );
};

const StyledOurCarbonFootprintBlock = styled.div`
  width: 100%;
  height: 1000px;
  position: relative;
  overflow: hidden;
  padding-top: 80px;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    height: 1540px;
  }
  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    height: auto;
    padding-top: 60px;
  }

  .bg_layout {
    background-color: #fff;
    &:before {
      background-color: rgba(241, 244, 247, 0.6);
      right: -1438px;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: -480px;
      }
    }
  }

  .pretitle_block {
    width: 100%;
    height: auto;
    margin-bottom: 5px;

    .svg {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      svg {
        width: 55px;
        height: 55px;
      }
    }

    .pretitle {
      display: block;
      width: 100%;
      height: auto;
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      text-align: center;
      letter-spacing: -1px;
      color: #4eb16c;
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.5px;
      }
    }
  }

  .title_block {
    width: 67%;
    height: auto;
    margin: 0 auto 64px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      width: 80%;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 100%;
      margin: 0 auto 45px;
    }

    h2 {
      margin-bottom: 15px;
    }

    span {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.3px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 17px;
      }
      br {
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          display: none;
        }
      }
    }
  }

  .list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 98px;
    .item {
      width: 30%;
      height: auto;
      margin-right: 5%;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        margin-right: 0;
        text-align: center;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column-reverse;
      }
      &:last-child {
        margin-right: 0;
        margin-bottom: 0;
      }
      .list_title {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.3px;
        margin-bottom: 20px;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          font-size: 24px;
          line-height: 28px;
          letter-spacing: -0.5px;
          margin-bottom: 0;
        }
      }
      .value_wrapper {
        width: 100%;
        height: auto;
        color: #4eb16c;
        @media (max-width: ${BREAKPOINTS.md + "px"}) {
          margin-bottom: 40px;
        }
        .count {
          font-weight: 700;
          font-size: 60px;
          line-height: 66px;
          letter-spacing: -0.3px;
          margin-right: 10px;
        }
      }
    }
  }
`;

export const query = graphql`
  fragment OurCarbonFootprintBlock on WpPage_Components_Components_OurCarbonFootprintBlock {
    ocfPretitleIcon
    ocfPretitle
    ocfTitle
    ocfSubtitle
    ocfList {
      ocfListValue
      ocfListPretitle
      ocfListCounter
    }
    mirrorList {
      mirrorListTitle
      mirrorListContent
      mirrorListImage {
        localFile {
          publicURL
        }
      }
    }
  }
`;
