// libraries
import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";

export const AdvantagesMainPageBlock = ({ data }) => {
  return (
    <>
      {data && (
        <StyledAdvantagesMainPageBlock>
          <div className="bg_layout" />
          <div className="main_wrapper_lg">
            <ul>
              {data.admList.map((item, index) => (
                <li key={index}>
                  <span
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.admListTitle,
                    }}
                  />
                  <span
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: item.admListDesc,
                    }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </StyledAdvantagesMainPageBlock>
      )}
    </>
  );
};

const StyledAdvantagesMainPageBlock = styled.div`
  width: 100%;
  height: 60px;
  position: relative;
  z-index: 2;

  .bg_layout {
    background-color: #f6f8fa;
    &:before {
      content: "";
      display: block;
      background-color: #fff;
      width: 100%;
      height: 100%;
      top: 0;
      right: 740px;
      transform: skewX(-30deg);
      position: absolute;

      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 513px;
      }
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: 495px;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 295px;
      }
    }
  }

  .main_wrapper_lg {
    height: 100%;
    width: 100%;
    padding: 0 20px;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding: 0 12px;
    }
  }

  ul {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    align-items: center;
    text-align: center;
    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
    }

    li {
      width: fit-content;
      padding-right: 32px;
      white-space: nowrap;
      display: inline;
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        margin-right: auto;
      }
      .title {
        width: fit-content;
        white-space: nowrap;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.3px;
        margin-right: 4px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 13px;
        }
      }
      .desc {
        width: fit-content;
        white-space: nowrap;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.3px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 13px;
        }
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
`;

export const query = graphql`
  fragment AdvantagesMainPageBlock on WpPage_Components_Components_AdvantagesMainPageBlock {
    admList {
      admListTitle
      admListDesc
    }
  }
`;
