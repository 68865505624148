// libraries
import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
// actions
import {
  updateLang,
  updateIsFrontPage,
  updateListCarrer,
  updateCookieInformationEventCount,
} from "../../store/actions/base";
// context
import { LanguageContextProvider } from "../../context/languageContext";
import { PopupsContextProvider } from "../../context/popupsContext";
// helpers
import {
  setCookieInformationHTML,
  changeCookieInformationValues,
} from "../../helpers/base";
import { addGTMHTML, addBodyNoScriptsHTML } from "../../helpers/analytics";
// hooks
import { useBaseStore } from "../../store";

export const Layout = ({ data, isPost, children }) => {
  const { state, dispatch } = useBaseStore();
  const lang = data.language.code.toLowerCase();
  const isFrontPage = data.databaseId === 19 || data.databaseId === 1098;
  const [isScriptsSet, setIsScriptsSet] = useState(false);

  //cookie information
  const {
    cookieInformation,
    cookieInformationEventCount,
    isCookieInformationHTMLSeted,
  } = state;
  ///////end cookie information

  useEffect(() => {
    updateLang(dispatch, lang);
    updateIsFrontPage(dispatch, isFrontPage);

    //cookie information
    if (!isCookieInformationHTMLSeted) {
      setCookieInformationHTML(dispatch, lang);
    }

    if (
      cookieInformation.isUserFirstAccept &&
      cookieInformation.cookie_cat_statistic
    ) {
      if (!isScriptsSet) {
        addGTMHTML("GTM-5TL2ZMZC");
        addBodyNoScriptsHTML("GTM-5TL2ZMZC");
        setIsScriptsSet(true);
      }
    }
    ///////end cookie information
  }, [
    dispatch,
    lang,
    isFrontPage,
    isCookieInformationHTMLSeted,
    cookieInformation,
    isScriptsSet,
    setIsScriptsSet,
  ]);

  const handleChangeCookieInformationValues = useCallback(
    (event, eventCount) => {
      changeCookieInformationValues(
        eventCount,
        event.currentTarget.cicc,
        dispatch
      );
    },

    [dispatch]
  );

  /*
   *  After installing script of CI-tool, listener CookieInformationConsentGiven
   * is triggered. In this case, all parameters are set to true or false by default and
   * all available cookies are loaded on the page.
   * The hook prevents instant loading and waits for user action.
   * Notice: This hook is important when in tool inited turnOnAllCategoriesByDefault()
   * */
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("CookieInformationConsentGiven", event => {
        const updatedEventCount = cookieInformationEventCount + 1;
        updateCookieInformationEventCount(dispatch, updatedEventCount);
        handleChangeCookieInformationValues(event, updatedEventCount);
      });
    }
  }, [
    cookieInformationEventCount,
    handleChangeCookieInformationValues,
    dispatch,
  ]);

  const isOpenList = e => {
    const target = e.target;
    if (!target.closest(".option_list")) {
      updateListCarrer(dispatch, false);
    }
  };

  return (
    <LanguageContextProvider data={data} isPost={isPost} lang={lang}>
      <PopupsContextProvider>
        <StyledLayout onClick={isOpenList}>{children}</StyledLayout>
      </PopupsContextProvider>
    </LanguageContextProvider>
  );
};

const StyledLayout = styled.div`
  position: relative;
  overflow: hidden;
`;
