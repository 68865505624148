// libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../../../settings";
// context
import { usePopups } from "../../../../context/popupsContext";
// helpers
import { sendContactsForm, checkAllInputs } from "../../../../helpers/forms";
import { translator } from "../../../../helpers/lang";
import {
  parseOnlyLetterAndSpace,
  parseOnlyNumbers,
  parseOnlyNumbersAndSomeSymbols,
} from "../../../../helpers/inputParser";
//  hooks
import { useBaseStore, useCalcBondsStore } from "../../../../store";
// static
import { IconArrow } from "../../../Icons/IconArrow";

export const ContactsForm = ({ langCurrent }) => {
  const { state: baseState, dispatch } = useBaseStore();
  const { state: bondsState } = useCalcBondsStore();
  const showContactsFormOverlay = baseState.showContactsFormOverlay;
  const { contactsFormStatus, setContactsFormStatus } = usePopups();
  const labelEN =
    "By submitting the application I consent to the processing of personal data and accept the terms of the <a href='/data-protection-policy-careers/' target='_blank'>Privacy Policy</a>. ID Finance Investments, S.L., as Data Controller, shall collect and process the personal information, in order to execute the investment request and transfer the information to the Placing or Placement Agent (located whithin the EU). You have the right of access, rectification, erasure, restriction of processing, data portability and to object by sending an email to <a href='mailto:dpd@idfinance.com' rel='noreferrer noopener'>dpd@idfinance.com</a>. You can access more information in <a href='/data-protection-policy-careers/' target='_blank' rel='noreferrer noopener'>Privacy Policy</a>.";
  const labelES = "Al enviar esta solicitud, doy mi consentimiento para el tratamiento de datos personales y acepto los términos de la <a href='/es/politica-de-proteccion-de-datos-carreras/' target='_blank' rel='noreferrer noopener'>Política de Privacidad</a>. ID Finance Investments, S.L., como Responsable del Tratamiento de Datos Personales, recopilará y procesará la información personal con el fin de ejecutar la solicitud de inversión y transferir la información al Agente de Colocación o Colocador (ubicado dentro de la UE). Usted tiene derecho de acceso, rectificación, supresión, limitación del tratamiento, portabilidad de datos y a oponerse enviando un correo electrónico a <a href='mailto:dpd@idfinance.com' rel='noreferrer noopener'>dpd@idfinance.com</a>. Puede acceder a más información en el apartado web de <a href='/es/politica-de-proteccion-de-datos-carreras/' target='_blank'>Política de Privacidad</a>.";

  const contactsForm = React.createRef();
  const currentVal = bondsState.currentVal
  const [isOpenForm, setIsOpenForm] = useState(false);
  const amountInputRef = React.createRef();
  
  function collectFormData(form) {
    const formData = {};

    const inputElements = form.querySelectorAll("input");

    inputElements.forEach(input => {
      if (input.name === 'phone') {
        formData[input.name] = `+${input.value}`;
        return;
      }
      formData[input.name] = input.value;
    });

    return formData;
  }
  
  const buttonClick = () => {
    let defaultData = {
      isOk: null,
      message: null,
    };
    
    setIsOpenForm(true);
    setContactsFormStatus(defaultData);
  };
  
  useEffect(() => {
    if (contactsFormStatus.isOk === false) {
      setIsOpenForm(false)
    }
  }, [contactsFormStatus]);
  
  return (
    <>
      <StyledContactsForm>
        {contactsFormStatus.isOk === false && !isOpenForm ? (
          <>
            <div
              className="form_failed"
              dangerouslySetInnerHTML={{
                __html: contactsFormStatus.message,
              }}
            />
            <button
              className="btn btn_try"
              onClick={buttonClick}
              dangerouslySetInnerHTML={{
                __html: translator(langCurrent, {
                  es: "Intentar",
                  en: "Try again",
                }),
              }}
            />
          </>
        ) : (
          <>
            {contactsFormStatus.isOk &&
              contactsFormStatus.message && (
                <div
                  className="form_send"
                  dangerouslySetInnerHTML={{
                    __html: contactsFormStatus.message,
                  }}
                />
              )}

            <form
              ref={contactsForm}
              onSubmit={event => {
                event.preventDefault();
                if (checkAllInputs(contactsForm, langCurrent)) {
                  sendContactsForm(
                    dispatch,
                    contactsForm.current,
                    collectFormData(contactsForm.current),
                    langCurrent,
                    amountInputRef.current,
                    setContactsFormStatus
                  );
                }
              }}
            >
              <div data-checked={null} className="input_wrapper">
                <label
                  htmlFor="first_name"
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: "Nombre*",
                      en: "Name*",
                    }),
                  }}
                />
                <input
                  data-req={true}
                  autoComplete="name"
                  type="text"
                  name="first_name"
                  placeholder={translator(langCurrent, {
                    es: "Nombre",
                    en: "Name",
                  })}
                  onChange={(e) => {
                    e.target.value = parseOnlyLetterAndSpace(e.target.value);
                  }}
                />
                <div
                  className={translator(langCurrent, {
                    es: "error error_es",
                    en: "error",
                  })}
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: "El campo es obligatorio",
                      en: "The field is required",
                    }),
                  }}
                />
              </div>

              <div className="user_metrick">
                <div data-checked={null} className="input_wrapper">
                  <label
                    htmlFor="email"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "Email*",
                        en: "Email*",
                      }),
                    }}
                  />
                  <input
                    data-req={true}
                    autoComplete="email"
                    type="text"
                    name="email"
                    placeholder={translator(langCurrent, {
                      es: "example@email.com",
                      en: "example@email.com",
                    })}
                  />
                  <div
                    className={translator(langCurrent, {
                      es: "error error_es",
                      en: "error",
                    })}
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "El campo es obligatorio",
                        en: "The field is required",
                      }),
                    }}
                  />
                </div>
                <div data-checked={null} className="input_wrapper">
                  <label
                    htmlFor="phone"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "Teléfono*",
                        en: "Phone*",
                      }),
                    }}
                  />
                  <input
                    data-req={true}
                    autoComplete="Telephone*"
                    type="text"
                    name="phone"
                    placeholder={translator(langCurrent, {
                      es: "+",
                      en: "+",
                    })}
                    onChange={(e) => {
                      e.target.value = `+${parseOnlyNumbersAndSomeSymbols(e.target.value)}`;
                    }}
                  />
                  <div
                    className={translator(langCurrent, {
                      es: "error error_es",
                      en: "error",
                    })}
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "Teléfono móvil incorrecto o falta",
                        en: "Invalid phone number",
                      }),
                    }}
                  />
                </div>
              </div>

              <div className="user_metrick">
                <div data-checked={null} className="input_wrapper">
                  <label
                    htmlFor="amount"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "Cantidad*",
                        en: "Amount*",
                      }),
                    }}
                  />
                  <input
                    ref={amountInputRef}
                    className="amount"
                    data-req={true}
                    autoComplete="Amount*"
                    type="text"
                    name="amount"
                    defaultValue={currentVal}
                    placeholder="10000"
                    onChange={(e) => {
                      e.target.value = parseOnlyNumbers(e.target.value);
                    }}
                  />
                  <div
                    className={translator(langCurrent, {
                      es: "error error_es",
                      en: "error",
                    })}
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "Monto de inversión no válido",
                        en: "Invalid investment amount",
                      }),
                    }}
                  />
                </div>
                <div data-checked={null} className="input_wrapper">
                  <label
                    htmlFor="broker"
                    dangerouslySetInnerHTML={{
                      __html: translator(langCurrent, {
                        es: "Corredora",
                        en: "Broker",
                      }),
                    }}
                  />
                  <input
                    data-req={true}
                    autoComplete="Broker"
                    type="text"
                    name="broker"
                    placeholder={translator(langCurrent, {
                      es: "Broker",
                      en: "Broker",
                    })}
                  />
                </div>
              </div>
  
              <StyledAcceptCheckbox data-checked={null} className="input_wrapper">
                <input type="checkbox" id="checkPrivacy" name="checkPrivacy" />
                <label
                  className="checkPrivacy"
                  htmlFor="checkPrivacy"
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: labelES,
                      en: labelEN,
                    }),
                  }}
                />
                <div
                  className={translator(langCurrent, {
                    es: "error error_es",
                    en: "error",
                  })}
                  dangerouslySetInnerHTML={{
                    __html: translator(langCurrent, {
                      es: "Por favor, acepte los términos para continuar.",
                      en: "Please, accept the terms to proceed",
                    }),
                  }}
                />
              </StyledAcceptCheckbox>

              <button
                type="submit"
                className="btn"
                aria-label="Send message"
          
              >
                <span>
                  {translator(langCurrent, {
                    es: "Enviar",
                    en: "Send",
                  })}
                  <IconArrow size={16} />
                </span>
              </button>
            </form>

            {showContactsFormOverlay && <div className="form_overlay" />}
          </>
        )}
      </StyledContactsForm>
    </>
  );
};

const StyledContactsForm = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px;

  @media (min-width: ${BREAKPOINTS.lg + "px"}) {
    padding: 40px;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @media (min-width: ${BREAKPOINTS.lg + "px"}) {
      gap: 40px;
    }
  }
  
  
  .form_send {
    display: flex;
    padding: 24px 32px;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
    border-radius: 4px;
    border: 1px solid #3E985A;
    background: #FFF;
    color: #3E985A;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -0.5px;

    @media (min-width: ${BREAKPOINTS.lg + "px"}) {
      margin-bottom: 40px;
    }
  }

  .form_failed {
    max-width: 674px;
    margin: 0 auto;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.1;
    letter-spacing: -0.5px;
    text-align: center;
  }

  .form_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 50;
  }

  .input_wrapper {
    width: 100%;
    height: auto;
    position: relative;

    &[data-checked="false"] {
      input,
      textarea {
        border-bottom: 1px solid #BA1A1A;
      }
      .error {
        display: block;
        &_es {
          @media (max-width: 400px) {
            bottom: 9px;
          }
          @media (max-width: 380px) {
            bottom: 1px;
          }
        }
      }
    }
    &[data-checked="true"] {
      input,
      textarea {
          border-bottom: 1px solid #C1C1C1;
      }
      .error {
        display: none;
      }
    }
    .error {
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 100%;
      color: #BA1A1A;
      font-size: 13px;
      display: none;
    }
  }

  label {
    display: inline-block;
    margin-bottom: 24px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    color: #898989;

    @media (min-width: ${BREAKPOINTS.lg + "px"}) {
      font-size: 18px;
      line-height: 1;
      letter-spacing: -0.5px;
    }
  }

  input,
  textarea {
    border-bottom: 1px solid #c1c1c1;
    width: 100%;
    height: 36px;
    -webkit-appearance: none;
    @media (max-height: 700px) {
      height: 35px;
      font-size: 14px;
    }
    &::placeholder {
      color: #C1C1C1;
    }
  }

  input.amount {
    font-size: 40px;
    font-weight: 700;
    line-height: 1.15;
    letter-spacing: -0.3px;
    
    &::placeholder {
      font-size: 14px;
    }
  }

  .user_metrick {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    @media (min-width: ${BREAKPOINTS.lg + "px"}) {
      flex-direction: row;
    }
    
    .input_wrapper {
      @media (min-width: ${BREAKPOINTS.lg + "px"}) {
        flex-direction: row;
        gap: 40px;
        width: 48%;
      }
    }
  }

  .btn {
    width: 100%;
    max-width: 180px;
    background-color: #005eb8;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 14px 16px;
    border-radius: 40px;
    cursor: pointer;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      max-width: 100%;
      font-size: 15px;
    }
    &:hover {
      &:not(:disabled) {
        opacity: 0.8;
      }
    }
    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .icon__arrow {
      margin: 0 0 0 8px;

      path {
        stroke: currentColor;
      }
    }
  }

  .btn_try {
    justify-content: center;
    margin: 40px auto 0;
  }

  .item {
    padding-top: 20px;
  }
`;

const StyledAcceptCheckbox = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.6);
  
  input[type="checkbox"] {
    width: 100%;
    max-width: 18px;
    height: 18px;
    outline: 3.5px solid #005eb8;
    appearance: none;
    position: relative;
    
    :checked {
      background: #005eb8;
      color: #fff;
    }
    
    :after {
      width: 18px;
      height: 18px;
      content: "";
      background: no-repeat center url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxNSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbFJ1bGU9ImV2ZW5vZGQiIGNsaXBSdWxlPSJldmVub2RkIiBkPSJNMTMuNDUgMS4wMDU0N0MxMy4zNDQyIDEuMDIyMyAxMy4yNDYgMS4wNzcyMyAxMy4xNzIgMS4xNjE2MUMxMC42OTUzIDMuODc0MzcgOC4wMTE0NiA3LjAyNTA2IDUuNTM1OTQgOS43NTQxMkwxLjc5Mjk3IDYuMzg2MDRDMS41ODgzIDYuMTk4ODggMS4yODM4MiA2LjIyODU4IDEuMTEyNTggNi40NTIyOEMwLjk0MTM0MSA2LjY3NTk4IDAuOTY4NTE4IDcuMDA4NzYgMS4xNzMxOSA3LjE5NTkyQzEuMTc0MTUgNy4xOTY5NyAxLjE3NTM1IDcuMTk4MjkgMS4xNzY3OSA3LjE5OTA4TDUuMjY1MzggMTAuODc5NEM1LjQ1NjM0IDExLjA1MTkgNS43MzYwNCAxMS4wMzc5IDUuOTExNjEgMTAuODQ2NkM4LjQ4NjcgOC4wMjU3OSAxMS4yOTI3IDQuNzA4MTggMTMuODQ4MyAxLjkwOTJDMTQuMDQxOSAxLjcxMDIxIDE0LjA1MTUgMS4zNzc2OSAxMy44Njk3IDEuMTY1ODJDMTMuNzYyMiAxLjA0MDcgMTMuNjA1NCAwLjk4MDc2MyAxMy40NSAxLjAwNTQ3WiIgZmlsbD0id2hpdGUiLz4KPHBhdGggZD0iTTEzLjQ1IDEuMDA1NDdDMTMuMzQ0MiAxLjAyMjMgMTMuMjQ2IDEuMDc3MjMgMTMuMTcyIDEuMTYxNjFDMTAuNjk1MyAzLjg3NDM3IDguMDExNDYgNy4wMjUwNiA1LjUzNTk0IDkuNzU0MTJMMS43OTI5NyA2LjM4NjA0QzEuNTg4MyA2LjE5ODg4IDEuMjgzODIgNi4yMjg1OCAxLjExMjU4IDYuNDUyMjhDMC45NDEzNDEgNi42NzU5OCAwLjk2ODUxOCA3LjAwODc2IDEuMTczMTkgNy4xOTU5MkMxLjE3NDE1IDcuMTk2OTcgMS4xNzUzNSA3LjE5ODI5IDEuMTc2NzkgNy4xOTkwOEw1LjI2NTM4IDEwLjg3OTRDNS40NTYzNCAxMS4wNTE5IDUuNzM2MDQgMTEuMDM3OSA1LjkxMTYxIDEwLjg0NjZDOC40ODY3IDguMDI1NzkgMTEuMjkyNyA0LjcwODE4IDEzLjg0ODMgMS45MDkyQzE0LjA0MTkgMS43MTAyMSAxNC4wNTE1IDEuMzc3NjkgMTMuODY5NyAxLjE2NTgyQzEzLjc2MjIgMS4wNDA3IDEzLjYwNTQgMC45ODA3NjMgMTMuNDUgMS4wMDU0NyIgc3Ryb2tlPSJ3aGl0ZSIvPgo8L3N2Zz4K");
      display: none;
    }
    
    :checked:after {
      display: block;
    }
  }

  label {
    margin-bottom: 0;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.3px;
    
    @media (min-width: ${BREAKPOINTS.lg + "px"}) {
      font-size: 16px;
    }
  }
  
  a {
    text-decoration: underline;
    color: #005EB8;
  }
  
  &[data-checked="false"] {
    input[type="checkbox"] {
      outline: 3.5px solid #BA1A1A;
    }
  }
`;
