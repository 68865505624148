// libraries
import React from "react";
import styled from "styled-components";
// components
import { ContactsFormPopup } from "./ContactsFormPopup";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { useLang } from "../../context/languageContext";
import { usePopups } from "../../context/popupsContext";

export const ContactsFormBlock = () => {
  const { lang:langCurrent } = useLang();
  const { showContactsFormPopup, setShowContactsFormPopup } = usePopups();
  const blockRef = React.useRef(null);

  return (
    <>
      {showContactsFormPopup && (
        <StyledContactsFromBlock ref={blockRef}>
          <div className="bg_layout" />
          <ContactsFormPopup
            langCurrent={langCurrent}
            setShowContactsFormPopup={setShowContactsFormPopup}
          />
        </StyledContactsFromBlock>
      )}
    </>
  );
};

const StyledContactsFromBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 100px 0;
  overflow-y: auto;
  @media (max-width: ${BREAKPOINTS.md + "px"}) {
    padding: 60px 0;
  }
  .bg_layout {
    background-color: #fff;
    right: 1500px;
    &:after {
      height: 3000px;
      right: -340px;
      background-color: #f9fafc;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 446px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        right: -446px;
      }
    }
  }
  .main_wrapper {
    z-index: 6;
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 16px;

    @media (max-width: ${BREAKPOINTS.md + "px"}) {
      margin: 0 auto 40px;
    }

    h2 {
      margin-bottom: 2px;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        font-size: 40px;
        line-height: 49px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        font-size: 32px;
        line-height: 36px;
      }
    }

    span {
      font-weight: 700;
      font-size: 48px;
      line-height: 58px;
      text-align: center;
      letter-spacing: -1px;
      color: #c1c1c1;
      @media (max-width: ${BREAKPOINTS.xl + "px"}) {
        font-size: 40px;
        line-height: 49px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        font-size: 32px;
        line-height: 36px;
      }
    }
  }

  .positions_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .navigation_wrapper {
      width: 25%;
      height: auto;
      border-top: 1px solid #e6e6e6;
      padding-top: 24px;
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        width: 100%;
        margin-bottom: 60px;
      }
    }
  }
`;
