//libraries
import React, { useEffect, useState } from "react";
import styled from "styled-components";
// components
import { SortedPreviewList } from "./SortedPreviewList";
import { MediaPresenceSlider } from "../MediaPresenceSlider";
// constants
import { BREAKPOINTS } from "../../settings";
// context
import { useLang } from "../../context/languageContext";
// helpers
import { translator } from "../../helpers/lang";
import { scrollToElemTargetId } from "../../helpers/base";
// hooks
import { useNewsPreview } from "../../hooks/use-news-preview";

export const NewsPagePreviewBlock = ({ location }) => {
  const { lang:langCurrent } = useLang();
  const [choisedCategoryID, setChoisedCategoryID] = useState(() => {
    switch (location?.state?.pageId) {
      case 37:
        return 336;
      case 1126:
        return 345;
      default:
        return -1;
    }
  });
  let newsList = useNewsPreview(langCurrent);

  const sortCategoryTitles = newsList => {
    let categoryList = new Map();
    const allNewsStr = translator(langCurrent, {
      es: "Todas las noticias",
      en: "All news",
    });
    categoryList.set(-1, allNewsStr);
    newsList.forEach(news => {
      news.categories?.nodes?.forEach(cat => {
        categoryList.set(cat.databaseId, cat.name);
      });
    });

    return Array.from(categoryList);
  };
  const sortedCategoryTitles = sortCategoryTitles(newsList);

  const sortNews = (choisedCategoryID, newsList) => {
    if (choisedCategoryID === -1) {
      return Array.from(newsList);
    }

    let sortedNews = [];

    newsList.forEach(news => {
      news.categories?.nodes?.forEach(cat => {
        if (cat.databaseId === choisedCategoryID) {
          sortedNews.push(news);
        }
      });
    });

    return sortedNews;
  };
  const sortedNews = sortNews(choisedCategoryID, newsList);

  const tabClickHandler = categortID => {
    setChoisedCategoryID(parseInt(categortID));
  };

  const elemId = "news";
  const blockRef = React.useRef(null);
  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {newsList && (
        <StyledNewsPagePreviewBlock id={elemId} ref={blockRef}>
          <div className="tab_list_wrap">
            <div className="bg_layout" />
            <div className="main_wrapper">
              <div className="tab_list">
                {sortedCategoryTitles.map((item, index) => (
                  <button
                    key={index}
                    aria-label="Sort news"
                    dangerouslySetInnerHTML={{
                      __html: item[1],
                    }}
                    className={choisedCategoryID === item[0] ? "choised" : ""}
                    onClick={event => {
                      tabClickHandler(item[0]);
                      event.target.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest",
                        inline: "start",
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          <SortedPreviewList
            data={{ sortedNews, langCurrent, choisedCategoryID }}
          />
          <MediaPresenceSlider isHome={false} />
        </StyledNewsPagePreviewBlock>
      )}
    </>
  );
};

const StyledNewsPagePreviewBlock = styled.div`
  width: 100%;
  height: auto;
  z-index: 4;
  position: relative;

  .tab_list_wrap {
    width: 100%;
    height: 62px;
    position: relative;
    overflow: hidden;
    .bg_layout {
      background-color: #f1f4f7;
      &:after {
        background-color: #e8edf2;
        right: -1317px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          right: -550px;
        }
        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          right: -161px;
        }
      }
    }

    .main_wrapper {
      height: 100%;

      .tab_list {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        overflow: auto;
        button {
          width: fit-content;
          white-space: nowrap;
          height: 100%;
          border-bottom: 2px solid transparent;
          font-size: 18px;
          font-weight: 700;
          line-height: 16px;
          letter-spacing: -0.444444px;
          color: #898989;
          margin-right: 40px;
          cursor: pointer;
          transition: border-color 0.3s ease-in-out;
          &:last-child {
            margin-right: 0;
          }
          &.choised {
            border-color: #005eb8;
            color: #005eb8;
          }
          &:hover {
            opacity: 0.9;
            border-color: rgba(0, 94, 184, 0.7);
          }
        }
      }
    }
  }
`;
