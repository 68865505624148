// libraries
import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
// constants
import { BREAKPOINTS } from "../../settings";
// static
import { SVG_ROUND_NAV_RIGHT_TITLE } from "../../icons/icons";

export const OurKeyfiguresMainPageBlock = ({ data }) => {
  const blockTitle = data.okfTitle;

  return (
    <>
      {data && (
        <StyledOurKeyfiguresMainPageBlock>
          <div className="bg_layout" />

          <div className="main_wrapper">
            <div className="title_block">
              <Link to={data.okfLink} className="title">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: blockTitle,
                  }}
                />
                <span
                  className="svg"
                  dangerouslySetInnerHTML={{
                    __html: SVG_ROUND_NAV_RIGHT_TITLE,
                  }}
                />
              </Link>
            </div>

            <div className="okf_list_wrapper">
              {data.okfList.map((item, index) => (
                <div key={index} className="okf_list_item">
                  <div
                    className="title"
                    dangerouslySetInnerHTML={{
                      __html: item.okfListTitle,
                    }}
                  />

                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: item.okfListDesscription,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
        </StyledOurKeyfiguresMainPageBlock>
      )}
    </>
  );
};

const StyledOurKeyfiguresMainPageBlock = styled.div`
  width: 100%;
  height: 530px;
  position: relative;
  padding-top: 100px;
  text-align: center;

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding-top: 90px;
  }

  @media (max-width: ${BREAKPOINTS.sm + "px"}) {
    height: 1050px;
  }

  .bg_layout {
    background-color: #e8edf2;

    &:after {
      right: -1010px;
      background-color: rgba(241, 244, 247, 0.7);

      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -611px;
      }

      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: -654px;
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        right: 202px;
      }
    }
  }

  .title_block {
    display: inline-block;
    margin: 0 auto 120px;

    @media (max-width: ${BREAKPOINTS.xl + "px"}) {
      margin: 0 auto 90px;
    }

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      margin: 0 auto 65px;
    }

    .title {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: #005eb8;
      h2 {
        font-weight: 700;
        font-size: 48px;
        line-height: 48px;
        letter-spacing: -0.5px;
        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          font-size: 32px;
          line-height: 32px;
        }
      }

      .svg {
        width: auto;
        height: auto;
        margin-left: 20px;
        position: relative;
        top: 4px;

        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          top: 2px;
        }

        svg {
          width: 36px;
          height: 36px;
          path {
            stroke: #fff;
          }
          rect {
            fill: #005eb8;
          }
        }
      }
    }
  }

  .okf_list_wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .okf_list_item {
      width: 24%;
      height: auto;
      margin-right: 1.33%;

      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        width: 48%;
        margin-right: 4%;
        margin-bottom: 40px;
        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 80px;
      }

      &:last-child {
        margin-right: 0;
      }

      .title {
        width: 100%;
        height: auto;
        text-align: center;
        font-weight: 700;
        font-size: 60px;
        line-height: 60px;
        letter-spacing: -0.25px;
        color: #005eb8;
        margin-bottom: 40px;

        @media (max-width: ${BREAKPOINTS.lg + "px"}) {
          margin-bottom: 20px;
          font-size: 48px;
        }

        @media (max-width: ${BREAKPOINTS.sm + "px"}) {
          margin-bottom: 40px;
        }
      }
    }

    .desc {
      width: 100%;
      height: auto;
      text-align: center;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.5px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        font-size: 20px;
      }
    }
  }
`;

export const query = graphql`
  fragment OurKeyfiguresMainPageBlock on WpPage_Components_Components_OurKeyFiguresMainPageBlock {
    okfTitle
    okfLink
    okfList {
      okfListDesscription
      okfListTitle
    }
  }
`;
