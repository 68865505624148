// libraries
import { useStaticQuery, graphql } from "gatsby";

export const useInvestorsFilesPreview = langCurrent => {
  const { en, es } = useStaticQuery(
    graphql`
      query InvestorsFilesPreview {
        en: allWpPage(filter: { parentDatabaseId: { eq: 35 } }) {
          nodes {
            databaseId
            uri
            components {
              components {
                ... on WpPage_Components_Components_InvestorsInnerBlock {
                  iibPreviewInfoBlock {
                    iibPiDesc
                    iibPiLinkTitle
                    iibPiTitle
                  }
                }
              }
            }
          }
        }

        es: allWpPage(filter: { parentDatabaseId: { eq: 1119 } }) {
          nodes {
            databaseId
            uri
            components {
              components {
                ... on WpPage_Components_Components_InvestorsInnerBlock {
                  iibPreviewInfoBlock {
                    iibPiDesc
                    iibPiLinkTitle
                    iibPiTitle
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  if (langCurrent === "es") {
    return es.nodes;
  } else {
    return en.nodes;
  }
};
