// libraries
import React from 'react';
import styled from 'styled-components';
// components
// TODO: перенести оба компонента в директорию MainPageHeadingBlock/CalcPanel/
import { BoundsCalculatorBlock } from '../../../components/BoundsCalculatorBlock';
// constants
import { BREAKPOINTS } from '../../../settings';
export const CalcPanel = (props) => {

    return (
        <StyledCalPanel {...props}>
          <BoundsCalculatorBlock
            title="Investment calculation<span class='mobile-hidden'>&ensp;in bond*</span>"
            subtitle="* Form and denomination: The Bonds are serially numbered and in bearer form in the denomination of €1,000 (the “Initial Nominal Amount”) each. The Bonds are not publicly offered and therefore the minimum subscription amount is € 100,000. Trading of the Bonds in the secondary market is permitted for the Initial Nominal Amount. The Bonds are not intended to be offered, sold or otherwise made available to and should not be offered, sold or otherwise made available to any retail investor in the European Economic Area or the United Kingdom."
          />
        </StyledCalPanel>
    );
};

const StyledCalPanel = styled.div`
    align-items: stretch;
    display: flex;
    flex-direction: column;

    @media (min-width: ${BREAKPOINTS.sm}px) {
        align-items: flex-end;
    }

    @media (min-width: ${BREAKPOINTS.lg}px) {
        filter: drop-shadow(1px 1px 12px rgba(164, 164, 164, 0.25));
    }
`;

