// libraries
import React from "react";
// components
import { PromoMainPageBlock } from "../components/PromoMainPageBlock";
import { AdvantagesMainPageBlock } from "../components/AdvantagesMainPageBlock";
import { CompanyWaysMainPageBlock } from "../components/CompanyWaysMainPageBlock";
import { OurProductsBlock } from "../components/OurProductsBlock";
import { OurKeyfiguresMainPageBlock } from "../components/OurKeyfiguresMainPageBlock";
import { LatesNewsPreviewBlock } from "../components/LatesNewsPreviewBlock";
import { EmptyPromoBlock } from "../components/EmptyPromoBlock";
import { NewsPagePreviewBlock } from "../components/NewsPagePreviewBlock";
import { MediaKitBlock } from "../components/MediaKitBlock";
import { FullPromoBlock } from "../components/FullPromoBlock";
import { WorkingResponsiblyBlock } from "../components/WorkingResponsiblyBlock";
import { OurCarbonFootprintBlock } from "../components/OurCarbonFootprintBlock";
import { CarbonNeutralBanner } from "../components/CarbonNeutralBanner";
import { ActivelyCollaborateBlock } from "../components/ActivelyCollaborateBlock";
import { SustainableAndInclusiveSocietyBanner } from "../components/SustainableAndInclusiveSocietyBanner";
import { FinancialResponsibilityBlock } from "../components/FinancialResponsibilityBlock";
import { UnitedNationsSustainableDevelopmentGoalsBanner } from "../components/UnitedNationsSustainableDevelopmentGoalsBanner";
import { TechnologyDrivenAdvantagesBlock } from "../components/TechnologyDrivenAdvantagesBlock";
import { QualityManagementSystemBanner } from "../components/QualityManagementSystemBanner";
import { HowWeWorkBlock } from "../components/HowWeWorkBlock";
import { WantToJoinUsBlock } from "../components/WantToJoinUsBlock";
import { SubPromoBlock } from "../components/SubPromoBlock";
import { HistoryPreviewBlock } from "../components/HistoryPreviewBlock";
import { LeadershipBlock } from "../components/LeadershipBlock";
import { HighlightsBlock } from "../components/HighlightsBlock";
import { InvestorsInnerBlock } from "../components/InvestorsInnerBlock";
import { InformationForBondInvestorsBlock } from "../components/InformationForBondInvestorsBlock";
import { KeyFiguresBlock } from "../components/KeyFiguresBlock";
import { SubPromoCareerBlock } from "../components/SubPromoCareerBlock";
import { OurPrinciplesBlock } from "../components/OurPrinciplesBlock";
import { WhyIdFinanceBlock } from "../components/WhyIdFinanceBlock";
import { LifeAtIdFinanceBlock } from "../components/LifeAtIdFinanceBlock";
import { EmployeesExperienceBlock } from "../components/EmployeesExperienceBlock";
import { OpenPositionsBlock } from "../components/OpenPositionsBlock";
import { DataScienceSolutionsBlock } from "../components/DataScienceSolutionsBlock";
import { HowDoWeDoItBlock } from "../components/HowDoWeDoItBlock";
import { CreditInteligeBlock } from "../components/CreditInteligeBlock";
import { SimplePolicyBlock } from "../components/SimplePolicyBlock";
import { CalcBlock } from "../components/CalcBlock";
import { InvestorTable } from "../components/InvestorTable";

export const renderBlock = (block, pageId, title, location, fixedNav) => {
  switch (block.__typename) {
    case "WpPage_Components_Components_PromoMainPageBlock":
      return (
        <PromoMainPageBlock data={block} id={pageId} fixedNav={fixedNav} />
      );
    case "WpPage_Components_Components_AdvantagesMainPageBlock":
      return <AdvantagesMainPageBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_CompanyWaysMainPageBlock":
      return <CompanyWaysMainPageBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_OurProductsBlock":
      return <OurProductsBlock data={block} id={pageId} location={location} />;
    case "WpPage_Components_Components_OurKeyFiguresMainPageBlock":
      return <OurKeyfiguresMainPageBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_LatesNewsPreviewBlock":
      return <LatesNewsPreviewBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_EmptyPromoBlock":
      return <EmptyPromoBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_NewsPagePreviewBlock":
      return (
        <NewsPagePreviewBlock data={block} id={pageId} location={location} />
      );
    case "WpPage_Components_Components_MediaKitBlock":
      return <MediaKitBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_FullPromoBlock":
      return <FullPromoBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_WorkingResponsiblyBlock":
      return <WorkingResponsiblyBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_OurCarbonFootprintBlock":
      return <OurCarbonFootprintBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_CarbonNeutralBanner":
      return <CarbonNeutralBanner data={block} id={pageId} />;
    case "WpPage_Components_Components_ActivelyCollaborateBlock":
      return <ActivelyCollaborateBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_SustainableAndInclusiveSocietyBanner":
      return <SustainableAndInclusiveSocietyBanner data={block} id={pageId} />;
    case "WpPage_Components_Components_FinancialResponsibilityBlock":
      return <FinancialResponsibilityBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_UnitedNationsSustainableDevelopmentGoalsBanner":
      return (
        <UnitedNationsSustainableDevelopmentGoalsBanner
          data={block}
          id={pageId}
        />
      );
    case "WpPage_Components_Components_TechnologyDrivenAdvantagesBlock":
      return <TechnologyDrivenAdvantagesBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_QualityManagementSystemBanner":
      return <QualityManagementSystemBanner data={block} id={pageId} />;
    case "WpPage_Components_Components_HowWeWorkBlock":
      return <HowWeWorkBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_WantToJoinUsBlock":
      return <WantToJoinUsBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_SubPromoBlock":
      return <SubPromoBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_HistoryPreviewBlock":
      return <HistoryPreviewBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_LeadershipBlock":
      return <LeadershipBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_HighlightsBlock":
      return <HighlightsBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_InvestorsInnerBlock":
      return <InvestorsInnerBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_InformationForBondInvestorsBlock":
      return <InformationForBondInvestorsBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_KeyFiguresBlock":
      return <KeyFiguresBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_SubPromoCareerBlock":
      return <SubPromoCareerBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_OurPrinciplesBlock":
      return <OurPrinciplesBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_WhyIdFinanceBlock":
      return <WhyIdFinanceBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_LifeAtIdFinanceBlock":
      return <LifeAtIdFinanceBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_EmployeesExperienceBlock":
      return <EmployeesExperienceBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_OpenPositionsBlock":
      return <OpenPositionsBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_DataScienceSolutionsBlock":
      return <DataScienceSolutionsBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_HowDoWeDoItBlock":
      return <HowDoWeDoItBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_CreditInteligeBlock":
      return <CreditInteligeBlock data={block} id={pageId} />;
    case "WpPage_Components_Components_SimplePolicyBlock":
      return <SimplePolicyBlock data={block} id={pageId} title={title} />;
    case "WpPage_Components_Components_CalcBlock":
      return <CalcBlock data={block} id={pageId} title={title} />;
    case "WpPage_Components_Components_InvestorTable":
      return <InvestorTable data={block} id={pageId} title={title} />;
    default:
      return null;
  }
};
