// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
// constants
import { BREAKPOINTS } from "../../settings";
// helpers
import { scrollToElemTargetId } from "../../helpers/base";

export const LifeAtIdFinanceBlock = ({ data }) => {
  const sliderParams = {
    slidesPerView: 1.2,
    spaceBetween: 20,
    loop: true,
    centeredSlides: true,
    breakpoints: {
      576: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
      1400: {
        slidesPerView: 6,
      },
    },
  };

  const elemId = "life_at_ID_Finance";
  const blockRef = React.useRef(null);

  useEffect(() => {
    scrollToElemTargetId(document.getElementById(elemId));
  }, [blockRef]);

  return (
    <>
      {data && (
        <StyledLifeAtIdFinanceBlock id={elemId} ref={blockRef}>
          <div className="bg_layout" />
          <div className="main_wrapper_lg">
            <div className="title_block">
              <h2
                dangerouslySetInnerHTML={{
                  __html: data.lfbTitle,
                }}
              />
            </div>

            <Swiper {...sliderParams}>
              {data.lfbLifeList.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={`slide ${
                      parseInt(index % 2) === 0 ? "push" : ""
                    } ${parseInt(index % 3) === 0 ? "padding" : ""} `}
                  >
                    <img
                      src={item.lfbLlImage?.localFile?.publicURL}
                      alt="IDF Life"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </StyledLifeAtIdFinanceBlock>
      )}
    </>
  );
};

const StyledLifeAtIdFinanceBlock = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  padding: 100px 0 55px;

  @media (max-width: ${BREAKPOINTS.lg + "px"}) {
    padding: 34px 0 75px;
  }

  .bg_layout {
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      background-color: #f3f6f8;
    }
    &:before {
      background-color: #f3f6f8;
      right: 1696px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: 1479px;
      }
      // @media(max-width: ${BREAKPOINTS.sm + "px"}){
      //   right: 453px;
      // }
    }

    &:after {
      background-color: #f3f6f8;
      right: -1762px;
      @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
        right: -1000px;
      }
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        right: -700px;
      }
      @media (max-width: ${BREAKPOINTS.md + "px"}) {
        right: 92px;
      }
      @media (max-width: ${BREAKPOINTS.sm + "px"}) {
        background-color: #fff;
      }
    }
  }

  .main_wrapper_lg {
    width: 100%;
    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      padding: 0;
    }
  }

  .title_block {
    width: 50%;
    height: auto;
    margin: 0 auto 80px;
    text-align: center;

    @media (max-width: ${BREAKPOINTS.xxl + "px"}) {
      margin: 0 auto 60px;
      width: 60%;
    }

    @media (max-width: ${BREAKPOINTS.lg + "px"}) {
      width: 80%;
      margin: 0 auto 45px;
    }

    @media (max-width: ${BREAKPOINTS.sm + "px"}) {
      width: 80%;
    }
  }

  .swiper-wrapper {
    display: flex;
    .swiper-slide {
      height: auto;
      max-height: 400px;
      @media (max-width: ${BREAKPOINTS.lg + "px"}) {
        max-height: 300px;
      }
    }
  }

  .slide {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding-top: 40px;
    &.push {
      justify-content: flex-end;
      padding-top: 0;
    }
    &.padding {
      padding-top: 100px;
    }
    img {
      display: block;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

export const query = graphql`
  fragment LifeAtIdFinanceBlock on WpPage_Components_Components_LifeAtIdFinanceBlock {
    lfbTitle
    lfbLifeList {
      lfbLlImage {
        localFile {
          publicURL
        }
      }
    }
  }
`;
